/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import { useEffect, useState } from 'react';
import { loanRequestedByBiz } from 'services/loans';
import moment from 'moment';
import { number } from 'prop-types';
import { Spinner } from 'components/spinner';
import { TableComponent } from 'components/table';
import { getPCRLabel } from 'utils/loans';
import { TableContainer, TableContent } from 'styles/table';
import { Status } from 'styles/utils';
import { numberWithCommas } from 'utils/others';
import { toast } from 'react-hot-toast';
// import { formatTag } from 'utils/tags';
import { TooltipComponent } from 'components/tooltip';
// import { AlertMessage } from 'components/message';
// import { Input } from 'components/input';
// import { OverviewWrapper } from 'styles/layout';

const RequestedLoans = ({ businessAccountId }) => {
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [loading, setLoading] = useState(false);
   const [tableData, setTableData] = useState([]);
   // const [error, setError] = useState('');
   // const [bizAccount, setBizAccount] = useState('');
   // const [detailsModal, setDetailsModal] = useState(false);
   // const [singleLoan, setSingleLoan] = useState('');

   useEffect(() => {
      (async function fetchLoansRequested() {
         setLoading(true);
         try {
            const result = await loanRequestedByBiz(businessAccountId, currentPage);
            setPaginationData(result);
            setTableData(result.data || []);
         } catch ({ message }) {
            toast.error(message);
         }
         setLoading(false);
      })();
   }, []);

   // function handleOpenModal(row) {
   //    // setActiveTab('loan-overview');
   //    // console.log(row.biz_account_id);
   //    // setBizAccount(row.biz_account_id);
   //    // setSingleLoan(row.biz_loan_id);
   //    // setDetailsModal(true);
   // }

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   const columns = [
      {
         field: 'biz_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_name}>
               <span
               // onClick={() => handleOpenModal(row)}
               >
                  {row.biz_name}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_amount',
         headerName: 'Requested Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_amount}>
               ₦{numberWithCommas(row?.loan_amount)}
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_purpose',
         headerName: 'Loan Purpose',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_purpose}>{row?.loan_purpose}</TooltipComponent>
         ),
      },
      {
         field: 'loan_status',
         headerName: 'Loan Status',
         // width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_status}>
               <Status status={row?.loan_status}>{row.loan_status}</Status>
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_crs_score',
         headerName: 'Loan CRS Score',
         renderCell: ({ row }) => (
            <TooltipComponent
               title={
                  <>
                     {getPCRLabel(row?.loan_crs_score)} | {row?.loan_crs_score}
                  </>
               }
            >
               {getPCRLabel(row?.loan_crs_score)} | {row?.loan_crs_score || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_tenure',
         headerName: 'Loan Tenure',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_tenure}>{row?.loan_tenure} days</TooltipComponent>
         ),
      },
      {
         field: 'loan_disbursement_status',
         headerName: 'Loan Disbursement',
         // width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_officer_details?.first_name}>
               <Status status={row?.loan_disbursement_status}>
                  {row.loan_disbursement_status || 'N/A'}
               </Status>
               <span>
                  {' '}
                  {row?.loan_approved_by_details &&
                     ` | ${row?.loan_approved_by_details.first_name} ${row?.loan_approved_by_details.last_name}`}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_repayment_balance',
         headerName: 'Loan Repayment Balance',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_repayment_balance}>
               ₦{numberWithCommas(row?.loan_repayment_balance)}
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_officer_details',
         headerName: 'Loan Officer',
         renderCell: ({ row }) =>
            row?.loan_officer_details ? (
               <TooltipComponent
                  title={`${row?.loan_officer_details?.first_name} ${row?.loan_officer_details?.last_name}`}
               >
                  {`${row?.loan_officer_details?.first_name} ${row?.loan_officer_details?.last_name}`}
               </TooltipComponent>
            ) : (
               'N/A'
            ),
      },
      {
         field: 'pub_date',
         headerName: 'Requested Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={moment(row.pub_date).format('DD MMM, YYYY')}>
               {moment(row.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row.pub_date).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   return (
      <div>
         {/* <AlertMessage message={error} variant="error" /> */}

         {loading ? (
            <Spinner />
         ) : (
            <TableContainer>
               <TableContent>
                  <div>
                     <h1> Requested Loans</h1>
                     <span>{paginationData?.count}</span>
                  </div>
               </TableContent>
               <TableComponent
                  columns={columns}
                  rows={tableData}
                  isLoading={loading}
                  onPageChange={onPageChange}
                  count={paginationData.count}
               />
            </TableContainer>
         )}
      </div>
   );
};

RequestedLoans.propTypes = {
   businessAccountId: number.isRequired,
};

export default RequestedLoans;
