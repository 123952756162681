/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'components/button';
import { Skeleton } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterList';
import { MoreHoriz } from '@material-ui/icons';
import { TableContainer, TableContent, FilterContainer } from 'styles/table';
import { MenuComponent } from 'components/menu';
import { confirm } from 'components/confirm-box';
import { IconButton } from '@material-ui/core';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import LoggedInDashboard from 'components/logged-in-dashboard';
import EditLimitData from 'containers/limit-board/add-new-board';
import LimitBoardFilterModal from 'containers/limit-board/filter-limit-board';
import { listLimitBoardDetails, deleteLimitBoardDetails } from 'services/limit-board';

export default function LimitBoardControls() {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [currentPage, setCurrentPage] = useState(page || 1);
   const [tableData, setTableData] = useState([]);
   const [, setError] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [limitId, setLimitId] = useState('');
   const [newLimitDataModal, setNewLimitDataModal] = useState(false);
   const [editLimitDataModal, setEditLimitDataModal] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [filterPayload, setFilterPayload] = useState({});
   const [filterData, setFilterData] = useState({});
   const [editDefault, setEditDefault] = useState({});

   const fetchLimitBoardData = async () => {
      try {
         const result = await listLimitBoardDetails({ page: currentPage, ...filterPayload });
         if (result.ok) {
            setTableData(result.results);
            setPaginationData(result);
            setError('');
         }
         setIsLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setIsLoading(false);
      }
   };

   const deleteHandler = async () => {
      confirm({
         confirmText: 'Are you sure want to delete it?',
         isLoading: false,
         onConfirm: async () => {
            try {
               const result = await deleteLimitBoardDetails(limitId);
               setError(result.message);
               setIsLoading(false);
            } catch (err) {
               setError(err.message || err.custom_message);
               setIsLoading(false);
            }
            fetchLimitBoardData();
         },
      });
   };

   useEffect(() => {
      fetchLimitBoardData();
   }, [currentPage, filterPayload]);

   const options = ['Edit', 'Delete'];

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleOpenModal = () => {
      setEditLimitDataModal(true);
   };

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[() => handleOpenModal(row.id), () => deleteHandler(row.id)]}
               />
            </div>
         ),
      },
      {
         field: 'action_type',
         headerName: 'Action Type',
         renderCell: ({ row }) => (
            <TooltipComponent
               title={
                  row?.action_type.charAt(0).toUpperCase() +
                  row?.action_type.slice(1).replaceAll('_', ' ')
               }
            >
               {row?.action_type.charAt(0).toUpperCase() +
                  row?.action_type.slice(1).replaceAll('_', ' ')}
            </TooltipComponent>
         ),
      },
      {
         field: 'account_plan_type',
         width: 180,
         headerName: 'Account Plan Type',
         renderCell: ({ row }) => (
            <TooltipComponent
               title={row?.account_plan_type.toUpperCase().slice().replaceAll('_', ' ')}
            >
               {row?.account_plan_type.toUpperCase().slice().replaceAll('_', ' ')}
            </TooltipComponent>
         ),
      },
      {
         field: 'limit_count',
         width: 180,
         headerName: 'Limit Count',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.limit_count}>{row?.limit_count}</TooltipComponent>
         ),
      },
      {
         field: 'direct_state',
         width: 180,
         headerName: 'Direct State',
         renderCell: ({ row }) => (
            <TooltipComponent
               title={
                  row?.direct_state.toString().charAt(0).toUpperCase() +
                  row?.direct_state.toString().slice(1).replaceAll('_', ' ')
               }
            >
               {row?.direct_state.toString().charAt(0).toUpperCase() +
                  row?.direct_state.toString().slice(1).replaceAll('_', ' ')}
            </TooltipComponent>
         ),
      },
      {
         field: 'inbound_amount',
         width: 180,
         headerName: 'Inbound Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.inbound_amount}>{row?.inbound_amount}</TooltipComponent>
         ),
      },
      {
         field: 'outbound_amount',
         width: 180,
         headerName: 'Outbound Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.outbound_amount}>{row?.outbound_amount}</TooltipComponent>
         ),
      },
      {
         field: 'from_amount',
         width: 180,
         headerName: 'From Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.from_amount}>{row?.from_amount}</TooltipComponent>
         ),
      },
      {
         field: 'to_amount',
         width: 180,
         headerName: 'To Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.to_amount}>{row?.to_amount}</TooltipComponent>
         ),
      },
      {
         field: 'extra_amount',
         width: 180,
         headerName: 'Extra Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.extra_amount}>{row?.extra_amount}</TooltipComponent>
         ),
      },
   ];

   function onRowClick({ row }) {
      setLimitId(row.id);
      setEditDefault(row);
   }

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <LoggedInDashboard pageTitle="Limit Board Controls">
         <TableContainer className="activity">
            <TableContent>
               <div className="d-flex justify-content-between w-100 align-items-center">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1> Limit Board Details</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}
               </div>
               <div className="d-flex justify-content-between">
                  <FilterContainer
                     style={{ margin: '0px 0px 0px 20px' }}
                     onClick={() => setModalVisibilityFilter(true)}
                  >
                     Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                  </FilterContainer>
               </div>

               <div style={{ marginLeft: '10px' }}>
                  <Button onClick={() => setNewLimitDataModal(true)}>Create New Board</Button>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               onRowClick={onRowClick}
            />
            {modalVisibilityFilter && (
               <LimitBoardFilterModal
                  open={modalVisibilityFilter}
                  onClose={() => setModalVisibilityFilter(false)}
                  setCurrentPage={setCurrentPage}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  setFilterPayload={setFilterPayload}
               />
            )}
            {newLimitDataModal && (
               <EditLimitData
                  open={newLimitDataModal}
                  editDefault={{}}
                  fetchLimitDetails={fetchLimitBoardData}
                  onClose={() => setNewLimitDataModal(false)}
               />
            )}
            {editLimitDataModal && (
               <EditLimitData
                  isEdit
                  open={editLimitDataModal}
                  editDefault={editDefault}
                  fetchLimitDetails={fetchLimitBoardData}
                  onClose={() => setEditLimitDataModal(false)}
               />
            )}
         </TableContainer>
      </LoggedInDashboard>
   );
}
