import styled from 'styled-components';

export const TableContainer = styled.div`
   margin: 30px 0 0px 0;
   background-color: #fcfcfc;
`;

export const TableContent = styled.div`
   background-color: #fff;
   padding: 15px 0;
   border-top: 1px solid #f1f1f1;
   border-bottom: 1px solid #f1f1f1;
   display: flex;
   justify-content: space-between;
   align-items: center;

   h1 {
      font-size: 2rem;
      display: inline-block;
      margin: 0;
   }

   .value {
      font-size: 1.2rem;
      margin-left: 10px;
      background: #efefef;
      padding: 5px;
      border-radius: 5px;
   }
`;

export const FilterContainer = styled.div`
   border: 1px solid #d7dce0;
   padding: 10px 16px;
   border-radius: 6px;
   /* margin: 0 20px; */
   height: 40px;
   cursor: pointer;
   width: 200px;
   display: flex;
   align-items: center;
   justify-content: space-between;
`;
