/* eslint-disable react/prop-types */
import { useLocation } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { Input } from 'components/input';
import LoggedInDashboard from 'components/logged-in-dashboard';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TableComponent } from 'components/table';
import moment from 'moment';
import { FilterContainer, TableContainer, TableContent } from 'styles/table';
import useBusinessMeta from 'hooks/businessMeta';
import { useEffect, useState } from 'react';
import { getUnApprovedAccounts, changeStatus, getBusinessStats } from 'services/business';
import { HeadingOne } from 'styles/typography';
import { Status } from 'styles/utils';
import { TooltipComponent } from 'components/tooltip';
import { confirm } from 'components/confirm-box/confirm-box';
import { useDebouncedCallback } from 'use-debounce';
import toast from 'react-hot-toast';
import FilterListIcon from '@material-ui/icons/FilterList';
import { IconButton } from '@material-ui/core';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import { MenuComponent } from 'components/menu';
import { StatsCards } from 'components/stats-cards';
import BusinessDetails from 'containers/business-details';
import { RegisteredAccountsFilter } from 'containers/business-account-filter-modal/';

const RegisteredAccounts = () => {
   const { setActiveTab } = useBizDetailsModal();
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const { businessCurrentStatus } = useBusinessMeta();
   // const filterUrl = new URLSearchParams(search).get('filter');
   const [searchTerm, setSearchTerm] = useState('');
   const [stats, setStats] = useState([]);
   const [approvedAccountData, setApprovedAccountData] = useState([]);
   const [bizCurrentStatus, setBizCurrentStatus] = useState([]);
   const [isLoadingApprovedData, setIsLoadingAprrovedData] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [modalVisibility, setModalVisibility] = useState(false);
   // const [activeFilter, setActiveFilter] = useState(filterUrl || '24-hours');
   const [bizAccount, setBizAccount] = useState('');
   const [isChangeStatusLoading] = useState(false);
   const [noCloseIcon, setNoCloseIcon] = useState(false);
   const [filterData, setfilterData] = useState({
      start_date: '2019-01-01',
      end_date: moment().format('YYYY-MM-DD'),
      biz_type: '',
   });

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const options = ['View Details', 'Call', 'Send Message'];

   function handleSearch({ target }) {
      setSearchTerm(target.value);
   }
   const debounce = useDebouncedCallback(handleSearch, 600);

   useEffect(() => {
      if (businessCurrentStatus) {
         setBizCurrentStatus(
            businessCurrentStatus.map(({ current_status_tag }) => ({
               value: current_status_tag,
               options:
                  current_status_tag.charAt(0).toUpperCase() +
                  current_status_tag.slice(1).replaceAll('_', ' '),
            })),
         );
      }
   }, [businessCurrentStatus]);

   async function clearFilter() {
      try {
         const result = await getUnApprovedAccounts(
            { page: currentPage },
            {
               start_date: '2019-01-01',
               end_date: moment().format('YYYY-MM-DD'),
            },
         );
         setApprovedAccountData(result.data);
         setPaginationData(result);
         setfilterData({
            start_date: '2019-01-01',
            end_date: moment().format('YYYY-MM-DD'),
         });
      } catch (err) {
         return err;
      }
   }

   const fetchUnApprovedAccounts = async () => {
      try {
         setIsLoadingAprrovedData(true);
         setApprovedAccountData([]);
         setPaginationData({});
         const result = await getUnApprovedAccounts(
            {
               page,
            },
            {
               biz_friendly_name: searchTerm,
               ...filterData,
            },
         );
         setApprovedAccountData(result.data);
         setPaginationData(result);
      } catch (err) {
         return err;
      } finally {
         setIsLoadingAprrovedData(false);
      }
   };

   useEffect(() => {
      fetchUnApprovedAccounts();
   }, [searchTerm, page]);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   function handleOpenModal() {
      setActiveTab('overview');
      setModalVisibility(true);
      setNoCloseIcon(false);
   }

   function sendMessageModalSection() {
      setActiveTab('message');
      setModalVisibility(true);
   }

   function callNumber(phone) {
      const link = document.createElement('a');
      const linkText = document.createTextNode('call us');
      link.appendChild(linkText);
      link.href = `tel:${phone}`;
      link.click();
   }
   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   async function handleChangeStatus({ target }, bizId) {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isChangeStatusLoading,
         onConfirm: async () =>
            new Promise((resolve, reject) => {
               // setIsChangeStatusLoading(true);
               changeStatus({
                  biz_current_status: target.value,
                  biz_account_id: bizId,
               })
                  .then(() => {
                     // setIsChangeStatusLoading(false);
                     toast.success('success');
                     resolve();
                  })
                  .catch((err) => {
                     // setIsChangeStatusLoading(false);
                     reject(err);
                     toast.error(err.message || err.custom_message);
                  });
            }),
      });
   }

   const columns = [
      {
         field: 'biz_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_name}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.biz_account_id)}
               >
                  {row.biz_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'bvn',
         headerName: 'BVN',
         width: 100,
         renderCell: ({ row }) => (
            <Status status={!row?.account_holder_details?.bvn_number ? '' : 'accepted'}>
               {!row?.account_holder_details?.bvn_number ? 'N/A' : 'verified'}
            </Status>
         ),
      },
      {
         field: 'id',
         headerName: 'ID',
         width: 100,
         renderCell: ({ row }) => (
            <Status status={row?.account_holder_details?.identity_file_status}>
               {row?.account_holder_details?.identity_file_status || 'N/A'}
            </Status>
         ),
      },
      {
         field: 'tin',
         headerName: 'TIN',
         width: 100,
         renderCell: ({ row }) => (
            <Status
               status={
                  row?.tin_number && row?.tin_number_status === ''
                     ? 'pending'
                     : row?.tin_number_status
               }
            >
               {(row?.tin_number && row?.tin_number_status === ''
                  ? 'pending'
                  : row?.tin_number_status) || 'N/A'}
            </Status>
         ),
      },
      {
         field: 'poa',
         headerName: 'POA',
         width: 100,
         renderCell: ({ row }) => (
            <Status status={row.proof_of_address_status}>
               {row.proof_of_address_status || 'N/A'}
            </Status>
         ),
      },
      {
         field: 'cac_cert_photo_status',
         headerName: 'CAC Cert',
         width: 150,
         renderCell: ({ row }) => (
            <Status status={row?.cac_cert_photo_status}>
               {row.cac_cert_photo_status || 'N/A'}
            </Status>
         ),
      },
      {
         field: 'owner',
         headerName: 'Owner',
         renderCell: ({ row }) => (
            <span style={{ textTransform: 'capitalize' }}>
               {row?.account_holder_details?.first_name.toLowerCase()}{' '}
               {row?.account_holder_details?.last_name.toLowerCase()}
            </span>
         ),
      },
      {
         field: 'account_holder_details.phone',
         headerName: 'Phone',
         renderCell: ({ row }) => <span>{row?.account_holder_details?.phone || 'N/A'}</span>,
      },
      {
         field: 'assigned_manager_details',
         headerName: 'Manager',
         renderCell: ({ row }) => (
            <>
               {!row.assigned_manager_details ? (
                  'N/A'
               ) : (
                  <span>
                     {row.assigned_manager_details.first_name}
                     {row.assigned_manager_details.first_name}
                  </span>
               )}
            </>
         ),
      },
      {
         field: 'biz_current_status',
         headerName: 'Status',
         renderCell: ({ row }) => (
            <select
               style={{
                  width: '100%',
                  border: 'none',
                  padding: '10px',
                  fontWeight: 400,
                  fontSize: '1.3rem',
               }}
               onChange={(e) => handleChangeStatus(e, row.biz_account_id)}
               name="status"
               defaultValue={row.biz_current_status}
            >
               {bizCurrentStatus.map((item) => (
                  <option value={item.value}>{item.options}</option>
               ))}
            </select>
         ),
      },
      {
         field: 'pub_date',
         headerName: 'Date Onboarded',
         renderCell: ({ row }) => (
            <span>
               {moment(row?.pub_date).format('MMM DD, YYYY')} |{' '}
               {moment(row?.pub_date).format('h:mm a')}
            </span>
         ),
      },
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreVertIcon />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[
                     // place the callbacks in position corresponding to the options index
                     () => handleOpenModal(row.biz_account_id),
                     () => callNumber(row?.account_holder_details?.phone),
                     () => sendMessageModalSection(row.biz_account_id),
                  ]}
               />
            </div>
         ),
      },
   ];
   function onRowClick({ row }) {
      setBizAccount(row.biz_account_id);
   }

   async function fetchBusinessStats() {
      try {
         const response = await getBusinessStats();
         const {
            total_approved_freelancer_account,
            total_approved_reg_biz_account,
            total_approved_unreg_account,
            total_biz_account,
            total_refer_acount,
            total_unapproved_freelancer_account,
            total_unapproved_reg_biz_account,
            total_unapproved_unreg_account,
         } = response;
         setStats({
            total_accounts_created: total_biz_account,
            total_approved_business_account: total_approved_reg_biz_account,
            total_approved_freelancer_account,
            total_approved_unreg_account,
            total_unapproved_business_account: total_unapproved_reg_biz_account,
            total_unapproved_freelancer_account,
            total_unapproved_unreg_account,
            total_accounts_from_referrals: total_refer_acount,
         });
      } catch (e) {
         toast.error(e);
      }
   }

   useEffect(() => {
      fetchBusinessStats();
   }, []);

   return (
      <LoggedInDashboard>
         <HeadingOne className="mb-5 mt-4 mt-md-0"> Business Accounts</HeadingOne>
         <StatsCards data={stats} />
         <RegisteredAccountsFilter
            open={modalVisibilityFilter}
            onClose={() => setModalVisibilityFilter(false)}
            setBusinessInfo={setApprovedAccountData}
            setPaginationData={setPaginationData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setfilterData={setfilterData}
            filterData={filterData}
            handleSubmit={fetchUnApprovedAccounts}
            handleClearFilter={clearFilter}
         />

         <TableContainer id="table" className="d-none">
            <TableContent className="flex-wrap">
               {isLoadingApprovedData ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div className="mb-4 mb-lg-0">
                     <h1> Business Accounts</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
               <div className="d-flex justify-content-between flex-wrap">
                  <div className="mb-4 mb-lg-0">
                     <Input
                        style={{ width: '300px', height: '40px' }}
                        placeholder="Search"
                        onChange={debounce}
                     />
                  </div>
                  <FilterContainer
                     style={{ margin: '0px 0px 0px 20px' }}
                     onClick={() => setModalVisibilityFilter(true)}
                     className="ms-0 ms-md-4"
                  >
                     Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                  </FilterContainer>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={approvedAccountData}
               isLoading={isLoadingApprovedData}
               onPageChange={onPageChange}
               count={Math.round(paginationData.count / 15)}
               page={currentPage}
               pageSize={20}
               onRowClick={onRowClick}
               // filter={activeFilter}
            />
         </TableContainer>
         {modalVisibility && (
            <BusinessDetails
               businessAccountId={bizAccount}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
               noCloseIcon={noCloseIcon}
            />
         )}
      </LoggedInDashboard>
   );
};

export default RegisteredAccounts;
