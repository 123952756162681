import { useEffect, useState } from 'react';
import { func, objectOf, bool, string } from 'prop-types';
import { confirm } from 'components/confirm-box';
import Modal from 'components/modal';
import { Input, NumberInput } from 'components/input';
import { toast } from 'react-hot-toast';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { createBillingRate, updateBillingRate } from 'services/billing';

function AddBillingRateModal({ isEdit = false, editDefault, fetchBillingRates, onClose, open }) {
   const [loading, setLoading] = useState(false);
   const [newData, setNewData] = useState({});

   const { max_amount, min_amount, bill_rate } = editDefault;

   const detailsUnchanged = () =>
      newData.bill_rate === bill_rate &&
      newData.max_amount === max_amount &&
      newData.min_amount === min_amount;

   const isEmpty = !newData?.bill_rate || !newData?.max_amount || !newData?.min_amount;

   function onChange(name, data) {
      setNewData({
         ...newData,
         [name]: data.value,
      });
   }

   const addNewTier = async (e) => {
      e.preventDefault();
      setLoading(true);
      confirm({
         confirmText: 'Are you sure want to perform this action?',
         isLoading: false,
         onConfirm: async () => {
            try {
               const result = await createBillingRate(newData);
               if (result.ok) {
                  fetchBillingRates();
                  toast.success(result?.message || 'Created Successfully');
                  onClose();
               }
            } catch (err) {
               toast.error(err.message || 'An error has occurred');
            }
            setLoading(false);
         },
      });
   };

   const editTier = async (e) => {
      e.preventDefault();
      setLoading(true);
      confirm({
         confirmText: 'Are you sure want to perform this action?',
         isLoading: false,
         onConfirm: async () => {
            try {
               const result = await updateBillingRate(newData, editDefault.id);
               if (result.ok) {
                  fetchBillingRates();
                  toast.success(result?.message || 'Details Updated Successfully');
                  onClose();
               }
            } catch (err) {
               toast.error(err.message || err.custom_message);
            }
            setLoading(false);
         },
      });
   };

   useEffect(() => {
      setNewData(editDefault);
   }, [editDefault]);

   return (
      <Modal open={open} onClose={onClose} title={isEdit ? 'Edit Tier' : 'Add New Tier'}>
         <form onSubmit={isEdit ? editTier : addNewTier}>
            <InputWrapper className="grid-2-2">
               <div>
                  <NumberInput
                     label="Tier Range(Min Amount)"
                     placeholder="From Amount"
                     name="min_amount"
                     value={newData.min_amount}
                     prefix=" "
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('min_amount', { value: valuesObject.floatValue });
                     }}
                  />
               </div>
               <div>
                  <NumberInput
                     label="Tier Range(Max Amount)"
                     name="max_amount"
                     value={newData.max_amount}
                     prefix=" "
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('max_amount', { value: valuesObject.floatValue });
                     }}
                  />
               </div>
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <div>
                  <NumberInput
                     label="Percentage"
                     name="bill_rate"
                     value={newData.bill_rate}
                     prefix=" "
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('bill_rate', { value: valuesObject.floatValue });
                     }}
                  />
               </div>
            </InputWrapper>

            <div className="d-flex justify-content-end pt-5">
               {isEdit ? (
                  <Button
                     type="submit"
                     isLoading={loading}
                     disabled={detailsUnchanged() || loading}
                  >
                     Update Tier
                  </Button>
               ) : (
                  <Button type="submit" isLoading={loading} disabled={isEmpty || loading}>
                     Add New Tier
                  </Button>
               )}
            </div>
         </form>
      </Modal>
   );
}

AddBillingRateModal.propTypes = {
   isEdit: bool.isRequired,
   onClose: func.isRequired,
   open: func.isRequired,
   editDefault: objectOf(string).isRequired,
   fetchBillingRates: func.isRequired,
};

export default AddBillingRateModal;
