import styled from 'styled-components';

export const BankAccountWrapper = styled.div`
   background: #e3f0fd;
   padding: 20px;
   border-radius: 8px;
   h3 {
      span {
         font-weight: 400;
         font-size: 15px;
         text-transform: capitalize;
      }
   }
   .wallet-icon {
      width: 50px;
   }
   .click-link {
      // cursor:pointer;
   }
`;

export const GenerateStatementWrapper = styled.div`
   .generate-statement-btn {
      align-self: flex-end;
   }
`;
