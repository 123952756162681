import { useState, useEffect } from 'react';
import { getBusinessDetails } from 'services/businessDetails';
import {
   userSearch,
   generateAccountStatementApi,
   reactivateTerminatedAccountApi,
} from 'services/business';
import { number } from 'prop-types';
import toast from 'react-hot-toast';
import { Status } from 'styles/utils';
import { InputWrapper } from 'styles/form';
import { formatTag } from 'utils/tags';
import { numberWithCommas } from 'utils/others';
import { Spinner } from 'components/spinner';
import { Button } from 'components/button';
import { Input } from 'components/input';
import WalletIcon from 'assets/images/wallet.png';
import moment from 'moment';
import { BankAccountWrapper, GenerateStatementWrapper } from './style';

function Index({ businessAccountId }) {
   const [bizDetails, setBizDetails] = useState({});
   const [otherAccounts, setOtherAccounts] = useState([]);
   const [loading, setLoading] = useState(true);
   const [reactivatingAccount, setReactivatingAccount] = useState(null);
   const [generatingStatement, setGeneratingStatment] = useState(false);
   const [dateRange, setDateRange] = useState({
      start_date: '2019-01-01',
      end_date: moment().format('YYYY-MM-DD'),
   });
   const fetchBusinessDetails = async () => {
      try {
         const result = await getBusinessDetails(businessAccountId);
         const { data } = result;
         const response = await userSearch(data.account_holder_details.email);
         setOtherAccounts(response.data);
         if (result.ok) {
            setBizDetails(data);
         }
         setLoading(false);
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setLoading(false);
      }
   };

   const generateAccountStatement = async () => {
      setGeneratingStatment(true);
      try {
         const payload = {
            biz_account_id: businessAccountId,
            ...dateRange,
         };
         const response = await generateAccountStatementApi(payload);
         toast.success(response.message);
         window.open(response.statement_url, '_blank');
         await fetchBusinessDetails();
      } catch (err) {
         toast.error(err.message || err.custom_message);
      }
      setGeneratingStatment(false);
   };

   const reactivateTerminatedAccount = async (walletId) => {
      setReactivatingAccount(walletId);
      try {
         const payload = {
            biz_wallet_id: walletId,
         };
         const response = await reactivateTerminatedAccountApi(payload);
         toast.success(response.message);
         await fetchBusinessDetails();
      } catch (err) {
         toast.error(err.message || err.custom_message);
      }
      setReactivatingAccount(null);
   };

   useEffect(() => {
      fetchBusinessDetails();
   }, []);

   if (loading) return <Spinner />;

   const { biz_account_wallet_details } = bizDetails;
   return (
      <div>
         <h2 className="mb-5">Accounts</h2>
         <div className="row">
            {biz_account_wallet_details.map((item) => {
               return (
                  <div className="col-md-6 mb-5" key={item.biz_wallet_id}>
                     <BankAccountWrapper>
                        <div className="d-flex justify-content-between">
                           <div className="d-flex align-items-center mb-4">
                              <img src={WalletIcon} alt="" className="wallet-icon" />
                              <div className="">
                                 <h3 className="ms-4 me-3 mb-0 account-type">
                                    {item.biz_wallet_type === 'current' ? 'Current' : 'Sub Account'}
                                    {item.partner_bank_name ? (
                                       <span className="preferred_name mb-0 d-flex align-items-center mb-0">
                                          {' '}
                                          {item.partner_bank_name}
                                       </span>
                                    ) : (
                                       <span className="preferred_name mb-0 d-flex align-items-center mb-0 text-danger">
                                          (No partner bank)
                                       </span>
                                    )}
                                    <p
                                       className="font-weight-normal"
                                       style={{ fontSize: '12px', fontWeight: '400' }}
                                    >
                                       Wallet ID: {item.biz_wallet_id}
                                    </p>
                                 </h3>
                              </div>
                           </div>
                           <div>
                              {formatTag(item.wallet_final_status, item.wallet_final_status)}
                           </div>
                        </div>

                        <div>
                           <div className="account_number d-flex mb-2">
                              <h3 className="mb-0">Account Number: </h3> {'    '}
                              {item.biz_account_number ? (
                                 <span className="ms-2"> {item.biz_account_number}</span>
                              ) : (
                                 <span className="ms-2 text-danger"> N/A</span>
                              )}
                           </div>
                           <div className="account_number d-flex mb-2">
                              <h3 className="mb-0">
                                 Account Name:
                                 {item.preferred_name ? (
                                    <span className="ms-2">{item.preferred_name}</span>
                                 ) : (
                                    <span className="ms-2 text-danger">{' N/A'}</span>
                                 )}
                              </h3>
                           </div>
                           <h3 className="mb-2">
                              Avail Bal: <span>₦{numberWithCommas(item.available_balance)} </span>
                           </h3>
                           <h3 className="mb-2">
                              Total Bal: <span> ₦{numberWithCommas(item.total_balance)} </span>
                           </h3>
                        </div>
                        <Button
                           className="mt-3"
                           onClick={() => reactivateTerminatedAccount(item.biz_wallet_id)}
                           disabled={reactivatingAccount === item.biz_wallet_id}
                           isLoading={reactivatingAccount === item.biz_wallet_id}
                           style={{
                              visibility: item.wallet_final_status === 'terminated' ? '' : 'hidden',
                           }}
                        >
                           Reactivate Account
                        </Button>
                     </BankAccountWrapper>
                  </div>
               );
            })}
         </div>

         <h2 className="mb-4 pt-4">Generate Account Statement</h2>
         <GenerateStatementWrapper>
            <InputWrapper className="grid-3-3">
               <Input
                  placeholder="Start Date"
                  label="Start Date"
                  name="start_date"
                  type="date"
                  value={dateRange.start_date}
                  onChange={(e) =>
                     setDateRange({
                        ...dateRange,
                        start_date: e.target.value,
                     })
                  }
               />
               <Input
                  placeholder="End Date"
                  label="End Date"
                  name="end_date"
                  type="date"
                  value={dateRange.end_date}
                  onChange={(e) =>
                     setDateRange({
                        ...dateRange,
                        end_date: e.target.value,
                     })
                  }
               />
               <Button
                  className="generate-statement-btn"
                  onClick={generateAccountStatement}
                  disabled={generatingStatement}
                  isLoading={generatingStatement}
               >
                  Generate
               </Button>
            </InputWrapper>
         </GenerateStatementWrapper>

         <h2 className="mb-4 pt-4">Other Accounts</h2>
         <div className="row">
            {otherAccounts.map(({ biz_related_meta }) => {
               return (
                  <div className="col-lg-6 mb-4">
                     <BankAccountWrapper style={{ background: '#eee3fd' }}>
                        <h3 className="me-3 mb-4 account-type d-flex align-items-center justify-content-between">
                           <strong> Business Name</strong>
                           <span
                              className="click-link"
                              onClick={() =>
                                 fetchBusinessDetails(biz_related_meta[0].biz_account_id)
                              }
                           >
                              {biz_related_meta[0].biz_name}
                           </span>
                        </h3>
                        <div className="d-flex align-items-center justify-content-between">
                           <strong>Status</strong>
                           <Status className="m-0" status={biz_related_meta[0].biz_status}>
                              {biz_related_meta[0].biz_status}
                           </Status>
                        </div>
                     </BankAccountWrapper>
                  </div>
               );
            })}
         </div>
      </div>
   );
}

Index.propTypes = {
   businessAccountId: number.isRequired,
};
export default Index;
