/* eslint-disable no-else-return */
import { useEffect, useState } from 'react';
import Modal from 'components/modal';
// import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import { bool, func, number, objectOf, string } from 'prop-types';
import { toast } from 'react-hot-toast';
// import Documents from './documents';
// import Log from './log';
import { confirm } from 'components/confirm-box';
import { useAuth } from 'hooks/auth';
import {
   firstLoanApproval,
   finalLoanApproval,
   fetchLoanRepaymentHistory,
   loanWorkerTracker,
   getPendingLoanStream,
} from 'services/loans';
import { getBusinessDetails } from 'services/businessDetails';
import { Button } from 'components/button';
// import { Spinner } from 'components/spinner';
import BusinessOverview from 'containers/business-details/overview-2';
import LoanOverview from './loan-overview';

import BusinessReference from './business-reference';
// import Overview from './overview';
import Repayments from './repayments';
import RequestedLoans from './requested-loans';
// import SendMessage from './sendMessage';
// import Transactions from './transactions';
import Notes from './notes';
import { Navigation, NavigationTab } from './style';

const BusinessDetails = ({
   open,
   onClose,
   businessAccountId,
   loan,
   workerActiveStatus,
   workerDateRange,
}) => {
   // const { state, setActiveTab } = useBizDetailsModal();
   // const { activeTab } = state;

   const [activeTab, setActiveTab] = useState('loan-overview');
   const [loanDetails, setLoanDetails] = useState('');
   const [repaymentHistory, setRepaymentHistory] = useState('');
   const { currentUser } = useAuth();
   const [isApproving, setIsApproving] = useState(false);
   const [isRejecting, setIsRejecting] = useState(false);
   const [NumberReferenceApproved, setNumberReferenceApproved] = useState(0);
   const [newLoan, setNewLoan] = useState(false);
   const [newBusiness] = useState(false);
   async function initialize() {
      setLoanDetails([]);
      try {
         const result = await fetchLoanRepaymentHistory(newLoan || loan);
         const biz = await getBusinessDetails(newBusiness || businessAccountId);
         // console.log(result.data[0].repayment_history);
         setLoanDetails(result.data[0].loan_details);
         // console.log("Business Details : ", biz.data.biz_reference_details)
         const e = biz.data.biz_reference_details.filter((item) => item.ref_status === 'approved');
         // console.log('BUSINESS REFERENCE APPROVED LENGTH : ', e.length);
         setNumberReferenceApproved(e.length);
         setRepaymentHistory(result.data[0].repayment_history);
      } catch ({ message }) {
         toast.error(message);
      }
   }

   useEffect(() => {
      initialize();
   }, [newLoan]);

   const activePage = () => {
      switch (activeTab) {
         case '':
         case 'loan-overview':
            return <LoanOverview businessAccountId={businessAccountId} loanDetails={loanDetails} />;

         case 'notes':
            return <Notes businessAccountId={businessAccountId} />;

         case 'business-overview':
            return <BusinessOverview businessAccountId={businessAccountId} />;

         case 'repayments':
            return <Repayments repaymentHistory={repaymentHistory} />;

         case 'requested-loans':
            return <RequestedLoans businessAccountId={businessAccountId} />;

         case 'biz_reference':
            return (
               <BusinessReference businessAccountId={businessAccountId} initialize={initialize} />
            );

         default:
            // return <BusinessOverview businessAccountId={businessAccountId} />;
            return 'No Screen';
      }
   };

   const handleFirstApproval = async (status) => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isApproving,
         onConfirm: async () => {
            if (status === 'pre_approved') {
               setIsApproving(true);
            } else if (status === 'rejected') {
               setIsRejecting(true);
            }
            try {
               const result = await firstLoanApproval({
                  biz_loan_id: loanDetails.biz_loan_id,
                  biz_loan_decision: status,
               });
               toast.success(result.message);
               await initialize();
            } catch (e) {
               toast.error(e.message);
            }
            if (status === 'pre_approved') {
               setIsApproving(false);
            } else if (status === 'rejected') {
               setIsRejecting(false);
            }
         },
      });
   };

   const handleFinalApproval = async (status) => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isApproving,
         onConfirm: async () => {
            if (status === 'approved') {
               setIsApproving(true);
            } else if (status === 'rejected') {
               setIsRejecting(true);
            }
            try {
               const result = await finalLoanApproval({
                  biz_loan_id: loanDetails.biz_loan_id,
                  biz_loan_decision: status,
               });
               toast.success(result.message);
               await initialize();
            } catch (e) {
               toast.error(e.message);
            }
            if (status === 'approved') {
               setIsApproving(false);
            } else if (status === 'rejected') {
               setIsRejecting(false);
            }
         },
      });
   };

   const runTracker = async () => {
      await loanWorkerTracker({
         biz_loan_id: loan,
         stream_type: 'work_loan_pending',
         action_type: 'single',
      });
   };

   const workerActions = () => (
      <div className="d-flex align-items-end">
         <Button
            variant="primary"
            className="me-3"
            onClick={() => {
               confirm({
                  confirmText: 'Are you sure you want to go to next loan?',
                  isLoading: false,
                  onConfirm: async () => {
                     try {
                        runTracker();
                        const stream = await getPendingLoanStream(workerDateRange);
                        if (!stream.data)
                           return toast.error('No business is currently in this time range stream');
                        setNewLoan(stream.data.biz_loan_id);
                        // toast.success(tracker.message)
                     } catch (e) {
                        toast.error(e?.message);
                     }
                  },
               });
            }}
            // isLoading={isApproving}
         >
            Next Loan
         </Button>
         <Button
            variant="danger"
            onClick={() => {
               confirm({
                  confirmText: 'Are you sure you want to end work?',
                  isLoading: false,
                  onConfirm: async () => {
                     try {
                        runTracker();
                        onClose();
                        setActiveTab('none');
                        toast.success('You have ended work successfully');
                     } catch ({ message }) {
                        toast.error(message);
                     }
                  },
               });
            }}
            // isLoading={isRejecting}
         >
            End Work
         </Button>
      </div>
   );

   const renderActions = () => {
      // console.log(loanDetails.loan_status, currentUser.user_type,loanDetails.loan_status === 'pending' && currentUser.user_type === 'prospasuperadmin')
      let template = '';
      if (loanDetails.loan_status === 'pending' && currentUser.user_type !== 'prospasuperadmin') {
         template = (
            <>
               <Button
                  variant="primary"
                  className="me-3"
                  onClick={() =>
                     NumberReferenceApproved > 1
                        ? handleFirstApproval('pre_approved')
                        : toast.error('Business has less than 2 references approved')
                  }
                  isLoading={isApproving}
               >
                  Approve
               </Button>
               <Button
                  variant="danger"
                  onClick={() => handleFirstApproval('rejected')}
                  isLoading={isRejecting}
               >
                  Reject
               </Button>
            </>
         );
      } else if (
         loanDetails.loan_status === 'pending' &&
         currentUser.user_type === 'prospasuperadmin'
      ) {
         template = (
            <>
               <Button
                  variant="primary"
                  className="me-2"
                  onClick={() =>
                     NumberReferenceApproved > 1
                        ? handleFinalApproval('approved')
                        : toast.error('Business has less than 2 references approved')
                  }
                  isLoading={isApproving}
               >
                  Approve &amp; Disburse
               </Button>
               <Button
                  variant="danger"
                  onClick={() => handleFinalApproval('rejected')}
                  isLoading={isRejecting}
               >
                  Reject
               </Button>
            </>
         );
      } else if (
         loanDetails.loan_status === 'pre_approved' &&
         currentUser.user_type === 'prospasuperadmin'
      ) {
         template = (
            <>
               <Button
                  variant="primary"
                  className="me-3"
                  onClick={() =>
                     NumberReferenceApproved > 1
                        ? handleFinalApproval('approved')
                        : toast.error('Business has less than 2 references approved')
                  }
                  isLoading={isApproving}
               >
                  Disburse
               </Button>
               <Button
                  variant="danger"
                  onClick={() => handleFinalApproval('rejected')}
                  isLoading={isRejecting}
               >
                  Reject
               </Button>
            </>
         );
      }
      // else if(loanDetails.loan_status === 'pre_approved' && currentUser.user_type !== 'prospasuperadmin'){
      //    template =
      //       <>
      //          <Button variant="danger" onClick={() => handleFirstApproval('rejected')}>
      //             Reject
      //          </Button>
      //       </>
      // }
      // else if(loanDetails.loan_status === 'approved' && currentUser.user_type === 'prospasuperadmin'){
      //       template =
      //          <Button variant="danger" onClick={() => handleFinalApproval('rejected')}>
      //             Reject
      //          </Button>
      // }else if(loanDetails.loan_status === 'rejected' && currentUser.user_type === 'prospasuperadmin'){
      //       template =
      //       <Button variant="primary" className="me-2" onClick={() =>handleFinalApproval('approved')}>
      //          Approve &amp; Disburse
      //       </Button>
      // }
      return loanDetails?.biz_name ? template : '';
   };

   return (
      <Modal
         open={open}
         onClose={() => {
            onClose();
            setActiveTab('none');
         }}
         closeOnOverlayClick={false}
         title="Business Account Details"
         className="biz-details-modal"
      >
         <div className="d-flex justify-content-between">
            <Navigation>
               <NavigationTab
                  className={activeTab === 'loan-overview' ? 'active' : ''}
                  onClick={() => setActiveTab('loan-overview')}
               >
                  Loan Overview
               </NavigationTab>

               <NavigationTab
                  className={activeTab === 'notes' ? 'active' : ''}
                  onClick={() => setActiveTab('notes')}
               >
                  Notes
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'business-overview' ? 'active' : ''}
                  onClick={() => setActiveTab('business-overview')}
               >
                  Business Overview
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'repayments' ? 'active' : ''}
                  onClick={() => setActiveTab('repayments')}
               >
                  Repayments
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'requested-loans' ? 'active' : ''}
                  onClick={() => setActiveTab('requested-loans')}
               >
                  Requested Loans
               </NavigationTab>

               <NavigationTab
                  className={activeTab === 'biz_reference' ? 'active' : ''}
                  onClick={() => setActiveTab('biz_reference')}
               >
                  Business Reference
               </NavigationTab>
            </Navigation>
            {workerActiveStatus ? (
               workerActions()
            ) : (
               <div className="d-flex align-items-end">{renderActions()}</div>
            )}
         </div>

         <div className="pt-5">{activePage()}</div>
         {workerActiveStatus && (
            <div className="d-flex align-items-end justify-content-end">{renderActions()}</div>
         )}
      </Modal>
   );
};

BusinessDetails.propTypes = {
   open: bool.isRequired,
   loan: number.isRequired,
   onClose: func.isRequired,
   businessAccountId: number.isRequired,
   workerActiveStatus: bool.isRequired,
   workerDateRange: objectOf(string).isRequired,
};

export default BusinessDetails;
