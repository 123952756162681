import { v4 as uuidv4 } from 'uuid';
import { bool } from 'prop-types';
import NavItem from './nav-item';
import { SideBarWrapper } from './style';

function SideBar({ sideBar }) {
   const navLink = [
      {
         id: uuidv4(),
         link: '/business-accounts',
         text: 'Business Accounts',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/pos',
         text: 'POS',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/cards',
         text: 'Cards',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/points',
         text: 'Karma Points',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/webstore',
         text: 'Webstore',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/limit-board',
         text: 'Limit Board',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: '/billing',
         text: 'Billing',
         icon: 'file',
      },
      {
         id: uuidv4(),
         link: 'sign-out',
         text: 'Sign Out',
         icon: 'lock',
      },
   ];
   const navList = navLink.filter((value) => Object.keys(value).length !== 0);
   return (
      <SideBarWrapper
         id="sidebarMenu"
         className="col-md-3 col-lg-2 d-md-block bg-light"
         show={sideBar}
      >
         <div className="position-sticky pt-5">
            <ul className="nav flex-column mb-2 js-nav">
               {navList.map(({ link, icon, text, id }) => (
                  <NavItem link={link} icon={icon} text={text} key={id} />
               ))}
            </ul>
         </div>
      </SideBarWrapper>
   );
}

SideBar.propTypes = {
   sideBar: bool.isRequired,
};
export default SideBar;
