/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'components/button';
import { Skeleton } from '@material-ui/lab';
import { MoreHoriz } from '@material-ui/icons';
import { TableContainer, TableContent } from 'styles/table';
import { MenuComponent } from 'components/menu';
import { confirm } from 'components/confirm-box';
import { IconButton } from '@material-ui/core';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import AddBillingRateModal from 'containers/billing/add-new-tier';
import { getAllBillingRates, deleteBillingRate } from 'services/billing';

export const PayGSettings = () => {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [currentPage, setCurrentPage] = useState(page || 1);
   const [tableData, setTableData] = useState([]);
   const [, setError] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [newBillingRateModal, setNewBillingRateModal] = useState(false);
   const [editBillingRateModal, setEditBillingRateModal] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [editDefault, setEditDefault] = useState({});
   const [rateId, setRateId] = useState('');

   const fetchBillingRates = async () => {
      try {
         const result = await getAllBillingRates({ page: currentPage });
         if (result.ok) {
            setTableData(result.results);
            setPaginationData(result);
            setError('');
         }
         setIsLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setIsLoading(false);
      }
   };

   const deleteHandler = async () => {
      confirm({
         confirmText: 'Are you sure want to delete it?',
         isLoading: false,
         onConfirm: async () => {
            try {
               const result = await deleteBillingRate(rateId);
               setError(result.message);
               setIsLoading(false);
            } catch (err) {
               setError(err.message || err.custom_message);
               setIsLoading(false);
            }
            fetchBillingRates();
         },
      });
   };

   useEffect(() => {
      fetchBillingRates();
   }, [currentPage]);

   const options = ['Edit', 'Delete'];

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleOpenModal = () => {
      setEditBillingRateModal(true);
   };

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[() => handleOpenModal(row.id), () => deleteHandler(row.id)]}
               />
            </div>
         ),
      },
      {
         field: 'min_amount',
         width: 250,
         headerName: 'Transaction Tiers',
         renderCell: ({ row }) => (
            <TooltipComponent
               title={`${row?.min_amount}-${row?.max_amount}`}
            >{`${row?.min_amount}-${row?.max_amount}`}</TooltipComponent>
         ),
      },
      {
         field: 'bill_rate',
         headerName: 'Percentage',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.bill_rate}>{row?.bill_rate}%</TooltipComponent>
         ),
      },
      {
         field: 'created',
         headerName: 'Created Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.created}>
               {moment(row.created).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row.created).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
      {
         field: 'modified',
         headerName: 'Modified Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.modified}>
               {moment(row.modified).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row.modified).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   function onRowClick({ row }) {
      setRateId(row.id);
      setEditDefault(row);
   }

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <TableContainer className="activity">
         <TableContent>
            <div className="d-flex justify-content-between w-100 align-items-center">
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1>Transaction Tiers</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
            </div>

            <div style={{ marginLeft: '10px' }}>
               <Button onClick={() => setNewBillingRateModal(true)}>Add New Tier</Button>
            </div>
         </TableContent>
         <TableComponent
            columns={columns}
            rows={tableData}
            isLoading={isLoading}
            onPageChange={onPageChange}
            count={Math.ceil(paginationData.count / 15)}
            onRowClick={onRowClick}
         />

         {newBillingRateModal && (
            <AddBillingRateModal
               open={newBillingRateModal}
               editDefault={{}}
               fetchBillingRates={fetchBillingRates}
               onClose={() => setNewBillingRateModal(false)}
            />
         )}
         {editBillingRateModal && (
            <AddBillingRateModal
               isEdit
               open={editBillingRateModal}
               editDefault={editDefault}
               fetchBillingRates={fetchBillingRates}
               onClose={() => setEditBillingRateModal(false)}
            />
         )}
      </TableContainer>
   );
};
