import { Http } from 'services/http';
import { paramsObjectToQueryString } from 'utils/others';

export const listLimitBoardDetails = (payload) =>
   Http(`/backmen/prospa_boards/${paramsObjectToQueryString(payload)}`);

export const createLimitBoardDetails = (payload) =>
   Http(`/backmen/prospa_boards/`, {
      method: 'POST',
      body: payload,
   });

export const updateLimitBoardDetails = async (payload, limitId) => {
   try {
      const response = await Http(`/backmen/prospa_boards/${limitId}/`, {
         method: 'PATCH',
         body: payload,
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const deleteLimitBoardDetails = async (limitId) => {
   try {
      const response = await Http(`/backmen/prospa_boards/${limitId}/`, {
         method: 'DELETE',
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
