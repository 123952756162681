import { Http } from 'services/http';
import { logActivity } from 'services/activity';

export const getCalendarNotes = () => Http(`/backmen/psp_manager_calendars/`);

export const getSingleAdminStats = (prospa_admin_id) =>
   Http(`/backmen/admin_stat_details/${prospa_admin_id}/`);

export const addNoteToCalendar = async (payload) => {
   try {
      const response = await Http(`/backmen/psp_add_manager_calendar/`, {
         body: payload,
         method: 'POST',
      });
      await logActivity(`Added note to calendar - ${payload.calendar_note}`);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const getAdminStats = () => Http(`/backmen/admin_own_stat_details/`);

export const addAdminUser = (payload) =>
   Http(`/backmen/add_prospa_others/`, {
      body: payload,
      method: 'POST',
   });
export const adminTrackaCount = (payload) =>
   Http(`/backmen/direct_manager_round_stat/`, {
      body: payload,
      method: 'POST',
   });

export const getAdminDocumentStats = (payload) =>
   Http(`/backmen/direct_manager_doc_other_stat/`, {
      body: payload,
      method: 'POST',
   });

export const enable2FA = (payload, token) =>
   Http(`/account/enable_prospa_mfa/`, {
      body: payload,
      method: 'POST',
      headers: {
         Authorization: `Token ${token}`,
      },
   });
