/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { bool, func, number, objectOf, string } from 'prop-types';
import { useAuth } from 'hooks/auth';
import { changeStatus } from 'services/business';
import { MenuComponent } from 'components/menu';
import Avatar from 'assets/images/user.svg';
import { Status } from 'styles/utils';
import { Button } from 'components/button';
import useBusinessMeta from 'hooks/businessMeta';
import { confirm } from 'components/confirm-box';
import { approveBusiness, preapproveBusiness } from 'services/businessDetails';
import { toast } from 'react-hot-toast';
import { AvatarWrapper, Info, Name, TopSection, UserProfile } from './overview.style';

function UserProfileContainer({
   businessAccountId,
   bizDetails,
   fetchBusinessDetails,
   setLoading,
   noCloseIcon,
}) {
   const { currentUser } = useAuth();
   const [isApproving, setIsApproving] = useState(false);
   const [bizCurrentStatus, setBizCurrentStatus] = useState([]);
   const { businessCurrentStatus } = useBusinessMeta();
   const [anchorEl, setAnchorEl] = useState(null);
   const openStatusMenu = Boolean(anchorEl);
   const userImage = bizDetails?.account_holder_details?.photo_url || Avatar;
   const firstName = bizDetails?.account_holder_details?.first_name || '';
   const lastName = bizDetails?.account_holder_details?.last_name || '';
   const accountHolderName = `${firstName} ${lastName}`;
   const phone = bizDetails?.account_holder_details?.phone;
   const email = bizDetails?.account_holder_details?.email;

   useEffect(() => {
      if (businessCurrentStatus) {
         setBizCurrentStatus(
            businessCurrentStatus.map(({ current_status_tag }) => ({
               value: current_status_tag,
               options:
                  current_status_tag.charAt(0).toUpperCase() +
                  current_status_tag.slice(1).replaceAll('_', ' '),
            })),
         );
      }
   }, [businessCurrentStatus]);

   const handleCloseStatusMenu = () => {
      setAnchorEl(null);
   };

   const handleStatusButtonClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const approveOrPreapprove = async () => {
      setIsApproving(true);
      try {
         let result;
         if (currentUser?.user_type === 'prospasuperadmin') {
            result = await approveBusiness({ biz_account_id: businessAccountId });
         } else {
            result = await preapproveBusiness({ biz_account_id: businessAccountId });
         }
         if (result.ok) {
            fetchBusinessDetails();
            toast.success(result?.message, { duration: 3000 });
            //   setError('');
         }
         setLoading(false);
         setIsApproving(false);
      } catch (err) {
         //    setError(err.message || err.custom_message);
         toast.error(err.message || err.custom_message);
         setLoading(false);
         setIsApproving(false);
      }
   };

   const changeStatusAction = async (status) => {
      const statusData = {
         biz_current_status: status,
         biz_account_id: businessAccountId,
      };
      try {
         const result = await changeStatus(statusData);
         if (result.ok) {
            fetchBusinessDetails();
            toast.success(result?.message, { duration: 3000 });
         }
      } catch (err) {
         toast.error(err.message || err.custom_message);
         //    setError(err.message || err.custom_message);
      }
   };

   const handleChangeStatus = async (status) => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: false,
         onConfirm: () => changeStatusAction(status),
      });
   };

   const approveAccount = () => {
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: isApproving,
         onConfirm: approveOrPreapprove,
      });
   };
   return (
      <>
         <TopSection>
            <UserProfile>
               <AvatarWrapper>
                  <img src={userImage} alt="account-holder-avatar" />
               </AvatarWrapper>
               <div>
                  <Name className="mb-2">{accountHolderName}</Name>
                  <Info>{phone}</Info>
                  <Info>{email}</Info>
                  <Status status={bizDetails?.biz_status}>{bizDetails?.biz_status}</Status>
               </div>
            </UserProfile>
            {!noCloseIcon && bizDetails?.biz_status !== 'approved' && (
               <div>
                  {/* <Button
                     className={currentUser?.user_type === 'prospasuperadmin' ? 'bg-success' : ''}
                     onClick={approveAccount}
                     isLoading={isApproving}
                     disabled={isApproving || !bizDetails?.account_holder_details?.bvn_number}
                  >
                     {currentUser?.user_type === 'prospasuperadmin' ? 'Approve' : 'Preapprove'}
                  </Button> */}

                  <Button onClick={handleStatusButtonClick} className="ms-3 bg-secondary">
                     Status Actions
                  </Button>
                  <MenuComponent
                     anchorEl={anchorEl}
                     handleClose={handleCloseStatusMenu}
                     open={openStatusMenu}
                     options={bizCurrentStatus.map((option) => option.options)}
                     optionsFns={bizCurrentStatus.map(
                        (option) => () => handleChangeStatus(option.value),
                     )}
                  />
               </div>
            )}
         </TopSection>
      </>
   );
}

UserProfileContainer.propTypes = {
   businessAccountId: number.isRequired,
   bizDetails: objectOf(string).isRequired,
   fetchBusinessDetails: func.isRequired,
   setLoading: func.isRequired,
   noCloseIcon: bool.isRequired,
};
export default UserProfileContainer;
