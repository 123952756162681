import { useEffect, useState } from 'react';
import { func, objectOf, bool, string, number } from 'prop-types';
import { confirm } from 'components/confirm-box';
import Modal from 'components/modal';
import { Input, NumberInput } from 'components/input';
import { toast } from 'react-hot-toast';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { patchSettings } from 'services/settings';
import { createNewPlan, updateBillingPlan } from 'services/billing';

function AddBillingPlans({
   isEdit = false,
   editDefault,
   fetchBillingPlans,
   onClose,
   open,
   billingConfigId,
}) {
   const [loading, setLoading] = useState(false);
   const [newData, setNewData] = useState({});

   const { name, price, description, setting } = editDefault;
   const { local_transfer_outflow_count, excess_transfer_inflow_volume_charge } = setting || {};
   const { chunk_size, rate, above_to_charge } = excess_transfer_inflow_volume_charge || {};

   const detailsUnchanged = () =>
      newData?.name === name &&
      newData?.price === price &&
      newData?.description === description &&
      newData?.local_transfer_outflow_count === local_transfer_outflow_count &&
      newData?.chunk_size === chunk_size &&
      newData?.rate === rate &&
      newData?.above_to_charge === above_to_charge;

   const isEmpty =
      !newData?.name ||
      !newData?.price ||
      !newData?.description ||
      !newData?.local_transfer_outflow_count ||
      !newData?.rate ||
      !newData?.above_to_charge ||
      !newData?.chunk_size;

   function onChange(val, value) {
      setNewData((prevData) => ({
         ...prevData,
         [val]: value,
      }));
   }

   const addNewPlan = async () => {
      setLoading(true);
      const payload = {
         name: newData?.name,
         type: newData?.name.replace(/\b\w/g, (char) => char.toUpperCase()),
         description: newData?.description,
      };

      try {
         const result = await createNewPlan(payload);
         if (result.ok) {
            fetchBillingPlans();
            toast.success(result?.message || 'Created Successfully');
            onClose();
         }
      } catch (err) {
         toast.error(err.message || 'An error has occurred');
      }
      setLoading(false);
   };

   const updateSettings = async () => {
      setLoading(true);
      const payload = {};
      if (newData?.name) {
         payload[newData.name] = {
            bill_price: newData?.price,
            local_transfer_outflow_count: newData?.local_transfer_outflow_count,
            excess_transfer_inflow_volume_charge: {
               rate: newData?.rate,
               chunk_size: newData?.chunk_size,
               above_to_charge: newData?.above_to_charge,
            },
         };
      }
      try {
         const result = await patchSettings(billingConfigId, payload);
         if (result.ok) {
            fetchBillingPlans();
            onClose();
         }
      } catch (err) {
         toast.error(err.message || 'An error has occurred');
      }
      setLoading(false);
   };

   const handleAddPlans = (e) => {
      e.preventDefault();
      confirm({
         confirmText: 'Are you sure want to perform this action?',
         isLoading: false,
         onConfirm: async () => {
            await addNewPlan();
            await updateSettings();
         },
      });
   };

   const editPlan = async () => {
      setLoading(true);

      try {
         const result = await updateBillingPlan(newData, editDefault.id);
         if (result.ok) {
            fetchBillingPlans();
            toast.success(result?.message || 'Details Updated Successfully');
            onClose();
         }
      } catch (err) {
         toast.error(err.message || err.custom_message);
      }
      setLoading(false);
   };

   const handleEditPlan = (e) => {
      e.preventDefault();
      confirm({
         confirmText: 'Are you sure want to perform this action?',
         isLoading: false,
         onConfirm: async () => {
            await editPlan();
            await updateSettings();
         },
      });
   };

   useEffect(() => {
      if (editDefault && Object.keys(editDefault)) {
         setNewData({
            name: editDefault.name,
            description: editDefault.description,
            type: editDefault.type,
            price: editDefault.setting?.bill_price,
            above_to_charge:
               editDefault.setting?.excess_transfer_inflow_volume_charge.above_to_charge,
            chunk_size: editDefault.setting?.excess_transfer_inflow_volume_charge.chunk_size,
            rate: editDefault.setting?.excess_transfer_inflow_volume_charge.rate,
            local_transfer_outflow_count: editDefault.setting?.local_transfer_outflow_count,
         });
      }
   }, [editDefault]);

   return (
      <Modal open={open} onClose={onClose} title={isEdit ? 'Edit Plan' : 'Add New Plan'}>
         <form onSubmit={isEdit ? handleEditPlan : handleAddPlans}>
            <InputWrapper className="grid-2-2">
               <div>
                  <Input
                     label="Plan Name"
                     placeholder="Plan Name"
                     name="name"
                     value={newData.name}
                     onChange={({ target }) => onChange('name', target.value)}
                  />
               </div>
               <div>
                  <Input
                     label="Plan Description"
                     placeholder="Plan Description"
                     name="description"
                     value={newData.description}
                     onChange={({ target }) => onChange('description', target.value)}
                  />
               </div>
            </InputWrapper>
            <InputWrapper className="grid-2-2">
               <div>
                  <NumberInput
                     label="Amount"
                     placeholder="Enter how much plan will cost"
                     name="price"
                     value={newData.price}
                     prefix="₦"
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('price', valuesObject.floatValue);
                     }}
                  />
               </div>

               <div>
                  <NumberInput
                     label="Free Inflow Treshold"
                     placeholder="Above what amount should inflow be charged?"
                     prefix="₦"
                     name="above_to_charge"
                     value={newData.above_to_charge}
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('above_to_charge', valuesObject.floatValue);
                     }}
                  />
               </div>
            </InputWrapper>
            <InputWrapper className="grid-2-2">
               <div>
                  <NumberInput
                     label="Inflow Charge"
                     placeholder="Billing charge on amounts over the threshold"
                     name="chunk_size"
                     value={newData.chunk_size}
                     prefix="₦"
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('chunk_size', valuesObject.floatValue);
                     }}
                  />
               </div>

               <div>
                  <NumberInput
                     label="Rate"
                     name="rate"
                     placeholder="Amount to be billed"
                     value={newData.rate}
                     prefix="₦"
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('rate', valuesObject.floatValue);
                     }}
                  />
               </div>
            </InputWrapper>
            <InputWrapper className="grid-2-2">
               <div>
                  <NumberInput
                     label="Free Local Transfers"
                     placeholder="Number of free local transfers"
                     name="local_transfer_outflow_count"
                     value={newData.local_transfer_outflow_count || ''}
                     prefix=" "
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) =>
                        onChange('local_transfer_outflow_count', valuesObject.floatValue)
                     }
                  />
               </div>
            </InputWrapper>

            <div className="d-flex justify-content-end pt-5">
               {isEdit ? (
                  <Button
                     type="submit"
                     isLoading={loading}
                     disabled={detailsUnchanged() || loading}
                  >
                     Update Plan
                  </Button>
               ) : (
                  <Button type="submit" isLoading={loading} disabled={loading || isEmpty}>
                     Create New Plan
                  </Button>
               )}
            </div>
         </form>
      </Modal>
   );
}

AddBillingPlans.propTypes = {
   isEdit: bool.isRequired,
   onClose: func.isRequired,
   open: func.isRequired,
   editDefault: objectOf(string).isRequired,
   fetchBillingPlans: func.isRequired,
   billingConfigId: number.isRequired,
};

export default AddBillingPlans;
