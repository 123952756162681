import { func, objectOf, string } from 'prop-types';
import { Status } from 'styles/utils';
import {
   InfoGroup,
   Label,
   OverviewSection,
   SectionTitle,
   Value,
   BoolStatus,
} from '../utils/overview.style';

function OwnerInfo({ bizDetails, statusValue }) {
   // const ownerFirstName = bizDetails?.account_holder_details?.first_name
   // const ownerLastName =  bizDetails?.account_holder_details?.last_name
   // const phone = bizDetails?.account_holder_details?.phone;
   // const email = bizDetails?.account_holder_details?.email;
   // const IDNumber = bizDetails?.account_holder_details?.identity_number;
   // const IDType = bizDetails?.account_holder_details?.identity_type;

   const { first_name, last_name, phone, email, identity_number, identity_type } =
      bizDetails?.account_holder_details;

   return (
      <>
         <div>
            <SectionTitle>Owner&apos;s Information</SectionTitle>

            <OverviewSection>
               <InfoGroup>
                  <Label>First Name</Label>
                  <Value>{first_name}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Last Name</Label>
                  <Value>{last_name}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Phone Number</Label>
                  <Value>{phone}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Email</Label>
                  <Value style={{ textTransform: 'initial' }}>{email || 'N/A'}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>ID Number</Label>
                  <Value>{identity_number}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>ID Type</Label>
                  <Value>{identity_type}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>BVN Verified</Label>
                  <BoolStatus isTrue={!!bizDetails?.account_holder_details?.bvn_number}>
                     {bizDetails?.account_holder_details?.bvn_number ? 'Verified' : 'Not Verified'}
                  </BoolStatus>
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Owner is a Director</Label>
                  <BoolStatus isTrue={bizDetails?.director_match_status === 'accepted'}>
                     {bizDetails?.director_match_status === 'accepted' ? 'Yes' : 'No'}
                  </BoolStatus>
               </InfoGroup>

               <InfoGroup>
                  <Label>Govt Issued ID Verified</Label>
                  <Status status={bizDetails?.account_holder_details?.identity_file_status}>
                     {statusValue(bizDetails?.account_holder_details?.identity_file_status)}
                  </Status>
               </InfoGroup>

               <InfoGroup>
                  <Label>Account Holder ID</Label>
                  <Value>{bizDetails?.account_holder_details?.account_holder_id}</Value>
               </InfoGroup>
            </OverviewSection>
         </div>
      </>
   );
}

OwnerInfo.propTypes = {
   bizDetails: objectOf(string).isRequired,
   statusValue: func.isRequired,
};

export default OwnerInfo;
