/* eslint-disable react/prop-types */
import { getAllPosOrders } from 'services/pos';
import { getPointsStats } from 'services/points';
import { useEffect, useState } from 'react';
import LoggedInDashboard from 'components/logged-in-dashboard';
import { TableContainer, TableContent } from 'styles/table';
import { TooltipComponent } from 'components/tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Skeleton } from '@material-ui/lab';
import { TableComponent } from 'components/table';
import IconButton from '@material-ui/core/IconButton';
import toast from 'react-hot-toast';
import moment from 'moment';
import { MenuComponent } from 'components/menu';
import PosDetails from 'containers/pos-details';
import { StatsCards } from 'components/stats-cards';
import { useBizDetailsModal } from 'hooks/bizDetailsModal';

function FollowUp() {
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [stats, setStats] = useState([]);
   const [anchorEl, setAnchorEl] = useState(null);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [activeFilter] = useState('pos-orders');
   const [posOrderId, setPosOrderId] = useState('');
   const open = Boolean(anchorEl);
   const { setActiveTab } = useBizDetailsModal();

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const options = ['view Details'];

   function handleOpenModal() {
      setActiveTab('order-details');
      setModalVisibility(true);
   }

   function onRowClick({ row }) {
      setPosOrderId(row.pos_order_id);
   }

   const columns = [
      {
         field: 'biz_details?.biz_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_details?.biz_name}>
               <span
                  style={{
                     cursor: 'pointer',
                  }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row.pos_order_id)}
               >
                  {row.biz_details?.biz_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'device_quantity',
         headerName: 'Device Quantity',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.device_quantity}>
               {row.device_quantity || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'pub_date',
         headerName: 'Date Onboarded',
         renderCell: ({ row }) => (
            <span>
               {moment(row?.pub_date).format('MMM DD, YYYY')} |{' '}
               {moment(row?.pub_date).format('h:mm a')}
            </span>
         ),
      },
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreVertIcon />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[
                     // place the callbacks in position corresponding to the options index
                     () => handleOpenModal(row.biz_account_id),
                  ]}
               />
            </div>
         ),
      },
   ];

   const initializeTable = async () => {
      setIsLoading(true);
      setTableData([]);
      try {
         const result = await getAllPosOrders({ page: currentPage });
         setTableData(result.data);
         setPaginationData(result);
      } catch (e) {
         toast.error(e.response.message);
      }
      setIsLoading(false);
   };

   useEffect(() => {
      initializeTable();
   }, [currentPage, activeFilter]);

   async function fetchPointsStats() {
      try {
         const response = await getPointsStats();
         const { total_available_karma, total_karma_point, karma_this_week } = response;
         setStats({
            total_available_karma_points: total_available_karma,
            total_karma_point_earned: total_karma_point,
            karma_this_week,
         });
      } catch (e) {
         toast.error(e);
      }
   }

   useEffect(() => {
      fetchPointsStats();
   }, []);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <LoggedInDashboard pageTitle="Karma Points">
         <StatsCards data={stats} />
         <TableContainer className="d-none">
            <TableContent>
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1> Top 100 users by points</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
            </TableContent>
            {activeFilter === 'pos-orders' && (
               <TableComponent
                  columns={columns}
                  rows={tableData}
                  isLoading={isLoading}
                  onPageChange={onPageChange}
                  count={Math.ceil(paginationData.count / 15)}
                  onRowClick={onRowClick}
               />
            )}
         </TableContainer>
         {modalVisibility && (
            <PosDetails
               posOrderId={posOrderId}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
               initializeTable={initializeTable}
            />
         )}
      </LoggedInDashboard>
   );
}

export default FollowUp;
