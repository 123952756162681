import { Link } from 'react-router-dom';
import { func, bool } from 'prop-types';
import MenuImg from 'assets/images/menu.svg';
import CancelImg from 'assets/images/cancel.svg';
import { HeaderWrapper } from './style';

function Header({ setSideBar, sideBar }) {
   return (
      <>
         <HeaderWrapper className="navbar flex-md-nowrap p-0 shadow py-4 bg-white">
            <div className="d-flex justify-content-between logo-container px-4">
               <Link
                  to="/"
                  className="navbar-brand col-md-3 col-lg-2 me-0 d-flex align-items-center mb-4 mb-md-0"
               >
                  <img
                     src="https://psp-zenvault.s3.eu-west-2.amazonaws.com/static/assets/img/prospa-logo3.svg"
                     alt="Prospa Logo"
                  />{' '}
               </Link>
               <div className="nav-icons d-md-none">
                  {sideBar ? (
                     <img src={CancelImg} alt="cancel" className="close-nav" onClick={setSideBar} />
                  ) : (
                     <img src={MenuImg} alt="menu" className="open-nav" onClick={setSideBar} />
                  )}
               </div>
            </div>
         </HeaderWrapper>
      </>
   );
}

Header.propTypes = {
   setSideBar: func.isRequired,
   sideBar: bool.isRequired,
};

export default Header;
