/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable eqeqeq */
import moment from 'moment';

export const isEmpty = (value) =>
   value === undefined ||
   value === null ||
   (typeof value === 'object' && Object.keys(value).length === 0) ||
   (typeof value === 'string' && value.trim().length === 0) ||
   (typeof value === 'object' && value.toString().length === 0);

export const trimObject = (obj) => {
   for (const propName in obj) {
      if (isEmpty(obj[propName])) {
         delete obj[propName];
      }
   }

   return obj;
};

// export function objectToParams(obj) {
//    let str = '?';
//    // eslint-disable-next-line no-restricted-syntax
//    const trimmedPayload = trimObject(obj);
//    const paramPayloadToArr = Object.keys(trimmedPayload);
//    if (!trimmedPayload || paramPayloadToArr.length < 1) return '';
//    for (const key in obj) {
//       if (str != '') {
//          str += '&';
//       }
//       str += `${key}=${encodeURIComponent(obj[key])}`;
//    }

//    return str;
// }

export function paramsObjectToQueryString(payload) {
   const trimmedPayload = trimObject(payload);
   const paramPayloadToArr = Object.keys(trimmedPayload);
   if (!trimmedPayload || paramPayloadToArr.length < 1) return '';
   const queryString = paramPayloadToArr.reduce((acc, element, index, array) => {
      acc = `${array[0] === element ? '?' : ''}${acc}${element}=${trimmedPayload[element]}${
         array[array.length - 1] !== element ? '&' : ''
      }`;

      return acc;
   }, '');

   return queryString;
}

export function numberWithCommas(x) {
   return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
}

export function currentYear() {
   const curYear = new Date().getFullYear();
   const range = (start, stop, step) =>
      Array.from({ length: (stop - start) / step + 1 }, (pick, i) => start + i * step);
   return range(curYear, curYear - 2, -1);
}

export const dateFilter = (status) => {
   switch (status) {
      case '24-hours':
         return {
            start_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD'),
         };
      case '7-days':
         return {
            start_date: moment().subtract(8, 'days').format('YYYY-MM-DD'),
            end_date: moment().subtract(2, 'days').format('YYYY-MM-DD'),
         };

      case '30-days':
         return {
            start_date: moment().subtract(39, 'days').format('YYYY-MM-DD'),
            end_date: moment().subtract(9, 'days').format('YYYY-MM-DD'),
         };
      case '30-days-plus':
         return {
            start_date: '2019-01-01',
            end_date: moment().subtract(40, 'days').format('YYYY-MM-DD'),
         };
      case 'all':
         return {
            start_date: '2019-01-01',
            end_date: moment().format('YYYY-MM-DD'),
         };
      default:
         return {
            start_date: '2019-01-01',
            end_date: moment().format('YYYY-MM-DD'),
         };
   }
};

export const setNewDate = (startOrEnd, daysAgo) => {
   let value = moment().format('YYYY-MM-DD');
   if (startOrEnd === 'start') {
      value = moment().subtract(daysAgo, 'days').format('YYYY-MM-DD');
   } else if (startOrEnd === 'end') {
      value = moment().subtract(daysAgo, 'days').format('YYYY-MM-DD');
   }

   return value;
};
