/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useState, useEffect, useContext } from 'react';
import Modal from 'components/modal';
import { useBizDetailsModal, useBusinessDetails } from 'hooks/bizDetailsModal';
import { feedWorkerTracker, getFollowUpFeed, getActivationFeed } from 'services/business';
import { verifySecurityQuestion, getBusinessDetails } from 'services/businessDetails';
import { confirm } from 'components/confirm-box';
import { Button } from 'components/button';
import { Input } from 'components/input';
import { useAuth } from 'hooks/auth';
import moment from 'moment';
import { Spinner } from 'components/spinner';
import RequestedLoans from 'containers/loan-details/requested-loans';
import toast from 'react-hot-toast';
import { bool, func, number, string, objectOf } from 'prop-types';
import Documents from './documents';
import Log from './log';
import Notes from './notes';
import Overview from './overview';
import Overview2 from './overview-2';
import SendMessage from './sendMessage';
import Transactions from './transactions';
import Accounts from './accounts';
import { Navigation, NavigationTab } from './style';

const BusinessDetails = ({
   open,
   onClose,
   businessAccountId,
   type,
   noCloseIcon,
   dateRange,
   streamType,
}) => {
   const { state, setActiveTab } = useBizDetailsModal();
   const { currentUser } = useAuth();
   const { activeTab } = state;
   const [newStream, setNewStream] = useState(null);
   const [isLoadingBusiness, setIsLoadingNextBusiness] = useState(null);
   const [dob, setDob] = useState(null);
   const [activeQuestion, setActiveQuestion] = useState('');
   const [questionsToAnswer, setQuestionsToAnswer] = useState([]);
   const [value, setValue] = useState('');
   const [securityModal, setSecurityModal] = useState(true);
   const [bizDetails, setBizDetails] = useState('');
   const [verifyingSecurityQuestion, setVerifyingSecurityQuestiion] = useState(false);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState('');

   const renderOverview = () => {
      switch (type) {
         case 'activation':
         case 'loans':
            return (
               <Overview2
                  businessAccountId={newStream || businessAccountId}
                  noCloseIcon={noCloseIcon}
               />
            );

         default:
            return (
               <Overview
                  businessAccountId={newStream || businessAccountId}
                  noCloseIcon={noCloseIcon}
               />
            );
      }
   };

   const activePage = () => {
      switch (activeTab) {
         case '':
         case 'overview':
            return renderOverview();

         case 'notes':
            return <Notes businessAccountId={newStream || businessAccountId} />;

         case 'log':
            return <Log businessAccountId={newStream || businessAccountId} />;

         case 'documents':
            return <Documents businessAccountId={newStream || businessAccountId} />;

         case 'message':
            return <SendMessage businessAccountId={newStream || businessAccountId} />;

         case 'transactions':
            return <Transactions businessAccountId={newStream || businessAccountId} />;

         case 'accounts':
            return <Accounts businessAccountId={newStream || businessAccountId} />;

         case 'requested-loans':
            return <RequestedLoans businessAccountId={businessAccountId} />;

         default:
            // return <Overview businessAccountId={businessAccountId} />;
            return 'No Screen';
      }
   };

   const handleEndWork = () => {
      confirm({
         confirmText: 'Are you sure you want to end work?',
         isLoading: false,
         onConfirm: async () => {
            try {
               // const payload = {
               //    prospa_manager_id: currentUser.prospa_user_id,
               //    action_type: 'all',
               //    stream_type: streamType,
               // };
               // const result = await feedWorkerTracker(payload, streamType, 'end_work');
               setNewStream(null);
               // toast.success(result.message);
               toast.success('You have successfully ended work');
               onClose();
            } catch ({ message }) {
               toast.error(message);
            }
         },
      });
   };

   const handleNextBusiness = () => {
      confirm({
         confirmText: ' Have you contacted the business owner?',
         isLoading: false,
         multiple: true,
         onConfirm: () => {
            confirm({
               confirmText: 'Have you logged notes?',
               onConfirm: async () => {
                  setIsLoadingNextBusiness(true);
                  try {
                     // const user = localStorage.getItem('prospa-user-data').prospa_user_id;
                     // console.log(user)
                     const payload = {
                        prospa_manager_id: currentUser.prospa_user_id,
                        biz_account_id: newStream || businessAccountId,
                        action_type: 'single',
                        stream_type: streamType,
                     };
                     const result = await feedWorkerTracker(payload, streamType);
                     toast.success(result.message);
                     let stream = '';
                     if (streamType === 'work_followup') {
                        stream = await getFollowUpFeed(dateRange);
                     } else if (streamType === 'work_activation') {
                        stream = await getActivationFeed(dateRange);
                     }
                     if (!stream.data) {
                        setIsLoadingNextBusiness(false);
                        return toast.error("There's no account left on this stream");
                     }
                     setNewStream(stream.data.biz_account_id);
                     setActiveTab('overview');
                     // onClose();
                  } catch ({ message }) {
                     toast.error(message);
                  }
                  setIsLoadingNextBusiness(false);
               },
            });
         },
      });
   };
   const qA = [
      {
         question: "Member's Date of Birth",
         type: 'date_of_birth',
         inputType: 'date',
      },
      {
         question: "Member's Full name",
         type: 'full_name',
         inputType: 'text',
      },
      {
         question: "Member's last transaction amount",
         type: 'last_transaction_amount',
         inputType: 'number',
      },
      {
         question: 'Phone number',
         type: 'phone_number',
         inputType: 'number',
         defaultValue: '234',
      },
   ];
   const fetchBusinessDetails = async () => {
      try {
         const result = await getBusinessDetails(businessAccountId);
         if (result.ok) {
            setBizDetails(result.data);
            setError('');
         }
         setLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setLoading(false);
      }
   };
   const getQuestionsIndex = () => {
      const arr = [];
      while (arr.length < 2) {
         const r = Math.floor(Math.random() * 4) + 1;
         if (arr.indexOf(r) === -1) arr.push(r);
      }
      setQuestionsToAnswer(arr);
      setActiveQuestion(arr[0]);
      // console.log('Selected Question Index', arr);,
   };

   const renderQuestions = () => {
      const index = questionsToAnswer[0] ? questionsToAnswer[0] - 1 : questionsToAnswer - 1;
      return (
         <div>
            <Input
               placeholder={qA[index]?.question}
               label={qA[index]?.question}
               name={qA[index]?.type}
               type={qA[index]?.inputType}
               value={qA[index]?.defaultValue ? qA[index].defaultValue + value : value}
               onChange={(e) => {
                  if (qA[index].defaultValue) {
                     if (e.target.value.length < qA[index].defaultValue.length) {
                        return setValue('');
                     }
                     const ret = e.target.value.toString().replace(qA[index].defaultValue, '');
                     setValue(ret);
                  } else {
                     setValue(e.target.value);
                  }
               }}
            />
         </div>
      );
   };
   useEffect(() => {
      (async function init() {
         // setIsLoadingNextBusiness(true)
         await fetchBusinessDetails();
         getQuestionsIndex();
         // setIsLoadingNextBusiness(false)
      })();
   }, [bizDetails && bizDetails.biz_account_id]);

   const handleQuestionSubmit = async () => {
      if (!value || value === '') return toast.error('Please enter a valid answer');
      setVerifyingSecurityQuestiion(true);
      const index = questionsToAnswer[0] ? questionsToAnswer[0] - 1 : questionsToAnswer - 1;
      const question = qA[index];
      // console.log(question);
      try {
         const params = {
            biz_account_id: businessAccountId,
            [question.type]: `${
               question.type === 'phone_number' ? `+${question.defaultValue}${value}` : value
            }`,
         };
         const response = await verifySecurityQuestion(params);
         // console.log(response)
         if (response.final_check) {
            setValue('');
            // Move to the second question
            if (Array.isArray(questionsToAnswer)) {
               const arr = questionsToAnswer;
               const modifiedArr = arr.pop();
               // console.log('Modified Array', modifiedArr);
               setQuestionsToAnswer(modifiedArr);
               toast.success('Correct, please answer this final question.');
            } else {
               // BOTH SECURITY QUESTIONS PASSED
               setSecurityModal(false);
            }
         } else {
            toast.error('Invalid answer provided, you need to provide 2 correct answers at a go.');
            setValue('');
            getQuestionsIndex();
         }
      } catch (e) {
         // console.log(e)
         toast.error(e.message);
      }
      setVerifyingSecurityQuestiion(false);
   };

   if (loading) return <Spinner />;
   if (
      currentUser.user_type === 'prospacsr' &&
      bizDetails.biz_status === 'approved' &&
      securityModal
   )
      return (
         <Modal
            open={open}
            onClose={() => {
               onClose();
               setActiveTab(null);
            }}
            closeOnOverlayClick={false}
            noCloseIcon={noCloseIcon}
            title="Security Questions"
            className="biz-security-question-modal"
         >
            <form onSubmit={handleQuestionSubmit}>
               <div className="pt-4 mb-4">{renderQuestions()}</div>

               <div>
                  <Button
                     variant="primary"
                     onClick={handleQuestionSubmit}
                     isLoading={verifyingSecurityQuestion}
                     disabled={!value || value === ''}
                  >
                     Submit Answer
                  </Button>
               </div>
            </form>
         </Modal>
      );
   return (
      <Modal
         open={open}
         onClose={() => {
            onClose();
            setActiveTab(null);
         }}
         closeOnOverlayClick={false}
         noCloseIcon={noCloseIcon}
         title="Business Account Details"
         className="biz-details-modal"
      >
         <div className="d-flex justify-content-between">
            <Navigation>
               <NavigationTab
                  className={activeTab === 'overview' ? 'active' : ''}
                  onClick={() => setActiveTab('overview')}
               >
                  Overview
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'notes' ? 'active' : ''}
                  onClick={() => setActiveTab('notes')}
               >
                  Notes
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'log' ? 'active' : ''}
                  onClick={() => setActiveTab('log')}
               >
                  Log
               </NavigationTab>
               {type !== 'build-partner' && (
                  <NavigationTab
                     className={activeTab === 'documents' ? 'active' : ''}
                     onClick={() => setActiveTab('documents')}
                  >
                     Documents
                  </NavigationTab>
               )}

               <NavigationTab
                  className={activeTab === 'message' ? 'active' : ''}
                  onClick={() => setActiveTab('message')}
               >
                  Send Message
               </NavigationTab>

               {/* {type === 'build-partner' && (
                  <NavigationTab
                     className={activeTab === 'transactions' ? 'active' : ''}
                     onClick={() => setActiveTab('transactions')}
                  >
                     Transaction Feeds
                  </NavigationTab>
               )} */}
               <NavigationTab
                  className={activeTab === 'transactions' ? 'active' : ''}
                  onClick={() => setActiveTab('transactions')}
               >
                  Transaction Feeds
               </NavigationTab>

               <NavigationTab
                  className={activeTab === 'accounts' ? 'active' : ''}
                  onClick={() => setActiveTab('accounts')}
               >
                  Accounts
               </NavigationTab>
               <NavigationTab
                  className={activeTab === 'requested-loans' ? 'active' : ''}
                  onClick={() => setActiveTab('requested-loans')}
               >
                  Requested Loans
               </NavigationTab>
            </Navigation>

            {noCloseIcon && (
               <div className="d-flex align-items-end">
                  <Button variant="primary" className="me-4" onClick={handleNextBusiness}>
                     Next Business
                  </Button>
                  <Button variant="danger" onClick={handleEndWork}>
                     End Work
                  </Button>
               </div>
            )}
         </div>
         <div className="pt-5">{!isLoadingBusiness ? activePage() : <Spinner />}</div>
      </Modal>
   );
};

BusinessDetails.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   businessAccountId: number.isRequired,
   type: string,
   dateRange: objectOf(string),
   noCloseIcon: bool,
   streamType: string.isRequired,
};
BusinessDetails.defaultProps = {
   type: '',
   dateRange: {
      start_date: '2019-01-01',
      end_date: moment().format('YYYY-MM-DD'),
   },
   noCloseIcon: false,
};
export default BusinessDetails;
