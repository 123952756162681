import { useState } from 'react';
import { node, string } from 'prop-types';
import { RouteTransition } from 'components/route-animation/route-animation';
import SideBar from 'components/sidebar';
import Header from 'components/header';
import { DashboardWrapper } from './style';

function LoggedInDashboard({ children, pageTitle }) {
   const [sideBar, setSideBar] = useState(false);

   return (
      <DashboardWrapper>
         <Header setSideBar={() => setSideBar(!sideBar)} sideBar={sideBar} />
         <SideBar sideBar={sideBar} />
         <div className="dashboard-content px-md-4 pt-5">
            <main className="col-md-9 ms-sm-auto col-lg-10 layout-grey py-4 pt-5 mt-4 mt-md-0">
               {pageTitle && (
                  <h2 className="pt-5 pb-md-4 px-3 mt-5 mt-md-0 container">{pageTitle}</h2>
               )}
               <div className="container pt-5 ">
                  <RouteTransition>{children}</RouteTransition>
               </div>
            </main>
         </div>
      </DashboardWrapper>
   );
}

LoggedInDashboard.propTypes = {
   children: node,
   pageTitle: string,
};

LoggedInDashboard.defaultProps = {
   children: 'Content Goes Here...',
   pageTitle: '',
};

export default LoggedInDashboard;
