/* eslint-disable no-duplicate-case */

// import { useState } from 'react';
import MeterIcon from 'assets/images/speedometer.png';
import { objectOf, string } from 'prop-types';
import { Wrapper } from './style';

function Index({ bizDetails }) {
   //    console.log(bizDetails);
   const { progress_report_details } = bizDetails;
   const {
      biz_progress_customer,
      biz_progress_inflow,
      biz_progress_invoice,
      biz_progress_product,
      biz_progress_sub_account,
      biz_progress_team_member,
   } = progress_report_details;

   return (
      <Wrapper className="p-5">
         <div className="d-flex">
            <img src={MeterIcon} alt="" className="warning-icon me-5" />
            <div className="ps-3 w-100">
               <h2 className="mb-4">Progress Report</h2>
               <ul>
                  <li>
                     <span className="title">Business Inflow Progress</span>
                     <span className="number">{biz_progress_inflow} %</span>
                  </li>
                  <li className="">
                     <span className="title">Added a product to business Hub</span>
                     <span className="number">{biz_progress_product}</span>
                  </li>
                  <li>
                     <span className="title">Created a customer</span>
                     <span className="number">{biz_progress_customer}</span>
                  </li>

                  <li>
                     <span className="title">Created an Invoice</span>
                     <span className="number">{biz_progress_invoice}</span>
                  </li>
                  <li>
                     <span className="title">Created a sub account</span>
                     <span className="number">{biz_progress_sub_account}</span>
                  </li>
                  <li>
                     <span className="title">Added a team member</span>
                     <span className="number">{biz_progress_team_member}</span>
                  </li>
                  {/* <li>
                     <span className="title">Webstore URL</span>
                     {webstore_details.has_webstore ? (
                        <a
                           target="_blank"
                           rel="noreferrer"
                           className="number"
                           href={`${process.env.REACT_APP_WEBSTORE_BASEURL}${webstore_details.webstore_url}`}
                        >{`${process.env.REACT_APP_WEBSTORE_BASEURL}${webstore_details.webstore_url}`}</a>
                     ) : (
                        <Status status="rejected" className="ms-0">
                           No webstore
                        </Status>
                     )}
                  </li> */}
                  {/* {handleIdentity(identity_file_status, 'Government issued ID') !== '' && (
                     <li>{handleIdentity(identity_file_status, 'Government issued ID')}</li>
                  )}
                  {biz_type === 'registered' &&
                     handleIdentity(cac_cert_photo_status, 'CAC Certificate') !== '' && (
                        <li>{handleIdentity(cac_cert_photo_status, 'CAC Certificate')}</li>
                     )}
                  {handleIdentity(proof_of_address_status, 'Proof of address (Utility bill)') !==
                     '' && (
                     <li>
                        {handleIdentity(proof_of_address_status, 'Proof of address (Utility bill)')}
                     </li>
                  )}
                  {biz_type === 'registered' &&
                     handleIdentity(tin_number_status, 'TIN Number') !== '' && (
                        <li>{handleIdentity(tin_number_status, 'TIN Number')}</li>
                     )}
                  {biz_type === 'registered' && handleDirectorMatch() !== '' && (
                     <li>{handleDirectorMatch()}</li>
                  )}
                  {!tin_number && cac_cert_photo_url && (
                     <li>Requires TIN number. Please followup</li>
                  )} */}
               </ul>
            </div>
         </div>
      </Wrapper>
   );
}

Index.propTypes = {
   bizDetails: objectOf(string).isRequired,
};

export default Index;
