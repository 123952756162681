import axios from 'axios';
import { getToken } from './storage';

const axiosCustom = () => {
   const token = getToken();
   return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
         'Content-type': 'application/json',
         Authorization: `Token ${token}`,
      },
   });
};

export default axiosCustom;
