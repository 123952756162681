/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterList';
import { TableContainer, TableContent, FilterContainer } from 'styles/table';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import { getAllAccountPlans, getBillingPlans } from 'services/billing';
import BillingFilterModal from 'containers/billing/filter-billing';

export const BillingOverview = () => {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [currentPage, setCurrentPage] = useState(page || 1);
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [filterPayload, setFilterPayload] = useState({});
   const [filterData, setFilterData] = useState({});
   const [plans, setPlans] = useState([[]]);

   const fetchPlans = async () => {
      try {
         const response = await getBillingPlans({ page: 1 });
         setPlans(response.results);
      } catch (e) {
         toast.error(e?.message);
      }
   };

   useEffect(() => {
      fetchPlans();
   }, []);

   const fetchAccountPlans = async () => {
      try {
         const result = await getAllAccountPlans({ page: currentPage, ...filterPayload });
         if (result.ok) {
            setTableData(result.results);
            setPaginationData(result);
         }
         setIsLoading(false);
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setIsLoading(false);
      }
   };

   useEffect(() => {
      fetchAccountPlans();
   }, [currentPage, filterPayload]);

   const plan = (option) => {
      switch (option) {
         case 'BILLING__BILL_PAY_AS_YOU_GO':
            return `Pay As You Go Plan`;
         case 'BILLING__BILL_MEMBERS___GROWTH':
            return `Growth Plan `;
         case 'BILLING__BILL_MEMBERS___SCALE':
            return `Scale Plan`;
         default:
            break;
      }
   };

   const columns = [
      {
         field: 'business_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.business_name}>{row?.business_name}</TooltipComponent>
         ),
      },
      {
         field: 'option',
         width: 300,
         headerName: 'Billing Plan',
         renderCell: ({ row }) => (
            <TooltipComponent title={plan(row?.option)}>{plan(row?.option)}</TooltipComponent>
         ),
      },
      // {
      //    field: 'amount',
      //    headerName: 'Amount',
      //    renderCell: ({ row }) => (
      //       <TooltipComponent title={plan(row?.amount)}>{plan(row?.amount)}</TooltipComponent>
      //    ),
      // },
      {
         field: 'billing_rate',
         headerName: 'Percentage',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.billing_rate}>{row?.billing_rate}%</TooltipComponent>
         ),
      },
      // {
      //    field: 'deposit_value',
      //    headerName: 'Deposit Value',
      //    renderCell: ({ row }) => (
      //       <TooltipComponent title={plan(row?.deposit_value)}>
      //          {plan(row?.deposit_value)}
      //       </TooltipComponent>
      //    ),
      // },
   ];

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <TableContainer className="activity">
         <TableContent>
            <div className="d-flex justify-content-between w-100 align-items-center">
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1>Overview</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
            </div>
            <div className="d-flex justify-content-between">
               <FilterContainer
                  style={{ margin: '0px 0px 0px 20px' }}
                  onClick={() => setModalVisibilityFilter(true)}
               >
                  Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
               </FilterContainer>
            </div>
         </TableContent>
         <TableComponent
            columns={columns}
            rows={tableData}
            isLoading={isLoading}
            onPageChange={onPageChange}
            count={Math.ceil(paginationData.count / 15)}
         />
         {modalVisibilityFilter && (
            <BillingFilterModal
               open={modalVisibilityFilter}
               onClose={() => setModalVisibilityFilter(false)}
               setCurrentPage={setCurrentPage}
               filterData={filterData}
               setFilterData={setFilterData}
               setFilterPayload={setFilterPayload}
               plans={plans}
            />
         )}
      </TableContainer>
   );
};
