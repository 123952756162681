import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LoggedInDashboard from 'components/logged-in-dashboard';
import TabMenu from 'components/tab-menu';
import { TabWrapper } from './style';
import { BillingOverview } from './overview';
import { PayGSettings } from './payg-settings';
import { BillingPlans } from './billing-plans';

export const Billing = () => {
   const history = useHistory();
   const { pathname } = useLocation();
   const [activeTab, setActiveTab] = useState('overview');

   const handleActiveTab = (value) => {
      if (value !== activeTab) {
         history.push(pathname);
      }
      setActiveTab(value);
   };

   const tabs = [
      {
         label: 'Overview',
         value: 'overview',
      },
      {
         label: 'PAYG Settings',
         value: 'payg-settings',
      },
      {
         label: 'Billing Plans',
         value: 'billing-plans',
      },
   ];

   return (
      <LoggedInDashboard pageTitle="Billing">
         <TabWrapper>
            <ul className="m-0 d-flex align-items-center">
               {tabs?.map(({ label, value }) => {
                  return (
                     <TabMenu
                        key={value}
                        text={label}
                        active={activeTab === value ? 'active' : ''}
                        setActiveTab={() => {
                           handleActiveTab(value);
                        }}
                     />
                  );
               })}
            </ul>
         </TabWrapper>
         {activeTab === 'overview' && <BillingOverview />}
         {activeTab === 'payg-settings' && <PayGSettings />}
         {activeTab === 'billing-plans' && <BillingPlans />}
      </LoggedInDashboard>
   );
};
