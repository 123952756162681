/* eslint-disable no-duplicate-case */

// import { useState } from 'react';
import AttentionIcon from 'assets/images/warning.png';
import { objectOf, string } from 'prop-types';
import { Wrapper } from './style';

function Index({ bizDetails }) {
   //    console.log(bizDetails);
   const {
      account_holder_details,
      biz_type,
      proof_of_address_status,
      tin_number_status,
      cac_cert_photo_status,
      director_match_status,
      // tin_number,
      // cac_cert_photo_url,
   } = bizDetails;
   const { identity_file_status } = account_holder_details;
   const handleIdentity = (status, doc) => {
      switch (status) {
         case 'pending':
            // case '':
            return `Uploaded ${doc}. Please Verify`;
         case 'rejected':
            return `${doc} rejected`;

         case 'processing':
            return 'Uploaded CAC documents for TIN processing. Please verify';

         case 'tin_doc_rejected':
            return 'Uploaded CAC documents for TIN processing rejected.';

         case 'requires_tin':
            return 'Requires TIN number. Please follow up';

         case null:
            return `${doc} not uploaded. Please follow up`;

         case 'tin_doc_approved':
         case 'accepted':
            return '';
         default:
            return `Pending ${doc} upload`;
      }
   };

   //    const handleTin = () => {
   //       switch (tin_number_status) {
   //          case 'pending':
   //          case '':
   //             return 'TIN Number Submitted. Please Verify';
   //          case 'rejected':
   //             return 'TIN Number rejected';
   //          case 'accepted':
   //             return '';
   //          default:
   //             return 'TIN Number Submitted. Please Verify';
   //       }
   //    };

   const handleDirectorMatch = () => {
      switch (director_match_status) {
         case 'pending':
            return 'Pending Director Match. Please verify';
         case 'rejected':
            return 'Business Owner could not be verified as a director';
         case 'accepted':
            return '';
         default:
            return 'Pending Director Match. Please verify';
      }
   };

   return (
      <Wrapper className="p-5">
         <div className="d-flex">
            <img src={AttentionIcon} alt="" className="warning-icon me-5" />
            <div className="ps-3">
               <h2 className="mb-4">Needs your attention</h2>
               <ul>
                  {handleIdentity(identity_file_status, 'Government issued ID') !== '' && (
                     <li>{handleIdentity(identity_file_status, 'Government issued ID')}</li>
                  )}
                  {biz_type === 'registered' &&
                     handleIdentity(cac_cert_photo_status, 'CAC Certificate') !== '' && (
                        <li>{handleIdentity(cac_cert_photo_status, 'CAC Certificate')}</li>
                     )}
                  {handleIdentity(proof_of_address_status, 'Proof of address (Utility bill)') !==
                     '' && (
                     <li>
                        {handleIdentity(proof_of_address_status, 'Proof of address (Utility bill)')}
                     </li>
                  )}
                  {biz_type === 'registered' &&
                     handleIdentity(tin_number_status, 'TIN Number') !== '' && (
                        <li>{handleIdentity(tin_number_status, 'TIN Number')}</li>
                     )}
                  {biz_type === 'registered' && handleDirectorMatch() !== '' && (
                     <li>{handleDirectorMatch()}</li>
                  )}
               </ul>
            </div>
         </div>
      </Wrapper>
   );
}

Index.propTypes = {
   bizDetails: objectOf(string).isRequired,
};

export default Index;
