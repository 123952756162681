/* eslint-disable react/prop-types */
import { useState } from 'react';
import Modal from 'components/modal';
import { Button } from 'components/button';
import { formatTag } from 'utils/tags';
import BusinessDetails from 'containers/business-details';
import moment from 'moment';
import { numberWithCommas } from 'utils/others';
import { Wrapper } from './style';

export default function Index({ open, onClose, data }) {
   const [modalVisibility, setModalVisibility] = useState(false);
   const [bizAccount, setBizAccount] = useState('');

   const renderTransactionStatus = (row) => {
      let template = '';
      if (row?.transfer_details) {
         template =
            row?.transfer_details?.transfer_status === 'success'
               ? formatTag('accepted', 'Success')
               : formatTag(
                    row?.transfer_details?.transfer_status,
                    row?.transfer_details?.transfer_status,
                 );
      } else if (row?.bill_payment_details) {
         template =
            row?.bill_payment_details?.biller_status === 'success'
               ? formatTag('accepted', 'Success')
               : formatTag(
                    row?.bill_payment_details?.biller_status,
                    row?.bill_payment_details?.biller_status,
                 );
      } else if (row?.payout_details) {
         template =
            row?.payout_details?.payout_status === 'success'
               ? formatTag('accepted', 'Success')
               : formatTag(row?.payout_details?.payout_status, row?.payout_details?.payout_status);
      } else {
         template = row?.is_done
            ? formatTag('accepted', 'Success')
            : formatTag('rejected', 'Failed');
      }
      return template;
   };

   const openBusiness = (id) => {
      setBizAccount(id);
      setModalVisibility(true);
   };

   const {
      biz_account_id,
      child_category,
      // bill_payment_details,
      transfer_details,
      // is_done,
      // payout_details,
      parent_category,
      pub_date,
      source_account_name,
      trans_amount,
      tx_note,
      // tx_ref,
      transaction_id,
      modified_date,
   } = data;
   return (
      <>
         <Modal
            title="Transaction Details"
            open={open}
            onClose={onClose}
            closeOnOverlayClick={false}
         >
            <Wrapper>
               <div className="row pt-5">
                  <div className="col-lg-4 col-md-6 ">
                     <div className="business-details-item">
                        <h3>Recipient</h3>
                        <p className="">{transfer_details?.recipient_account_name || tx_note}</p>
                     </div>
                  </div>

                  <div className="col-lg-4 col-md-6 ">
                     <div className="business-details-item">
                        <h3> Amount</h3>
                        <p className="">
                           {trans_amount ? `₦ ${numberWithCommas(trans_amount)}` : 'N/A'}
                        </p>
                     </div>
                  </div>

                  <div className="col-lg-4 col-md-6 ">
                     <div className="business-details-item">
                        <h3>Status</h3>
                        <p className="">{renderTransactionStatus(data)}</p>
                     </div>
                  </div>

                  <div className="col-lg-4 col-md-6 ">
                     <div className="business-details-item">
                        <h3>Transaction ID</h3>
                        <p className="">{transaction_id}</p>
                     </div>
                  </div>

                  <div className="col-lg-4 col-md-6 ">
                     <div className="business-details-item">
                        <h3>Initiated By</h3>
                        <p className="">{source_account_name}</p>
                     </div>
                  </div>

                  <div className="col-lg-4 col-md-6 ">
                     <div className="business-details-item">
                        <h3>Note</h3>
                        <p className="">{tx_note}</p>
                     </div>
                  </div>

                  <div className="col-lg-4 col-md-6 ">
                     <div className="business-details-item">
                        <h3>Category</h3>
                        <p className="">{parent_category}</p>
                     </div>
                  </div>

                  <div className="col-lg-4 col-md-6 ">
                     <div className="business-details-item">
                        <h3>Child Category</h3>
                        <p className="">{child_category}</p>
                     </div>
                  </div>

                  <div className="col-lg-4 col-md-6 ">
                     <div className="business-details-item">
                        <h3>Date Created</h3>
                        <p className="">
                           {moment(pub_date).format('DD MMM, YYYY')} | {moment().format('HH:mm A')}
                        </p>
                     </div>
                  </div>

                  <div className="col-lg-4 col-md-6 ">
                     <div className="business-details-item">
                        <h3>Date Modified</h3>
                        <p className="">
                           {moment(modified_date).format('DD MMM, YYYY')} |{' '}
                           {moment().format('HH:mm A')}
                        </p>
                     </div>
                  </div>

                  <div className="col-lg-4 col-md-6 ">
                     <Button variant="primary" onClick={() => openBusiness(biz_account_id)}>
                        View Business
                     </Button>
                  </div>
               </div>
            </Wrapper>
         </Modal>
         {modalVisibility && (
            <BusinessDetails
               businessAccountId={bizAccount}
               open={modalVisibility}
               onClose={() => setModalVisibility(false)}
            />
         )}
      </>
   );
}
