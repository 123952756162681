import styled from 'styled-components';

export const Status = styled.span`
   background-color: ${({ status }) => {
      switch (status) {
         case 'accepted':
         case 'approved':
         case 'on':
         case 'completed':
         case 'active':
         case 'delivered':
            return '#EAFBF1';
         case 'rejected':
         case 'off':
         case 'unapproved':
         case 'defaulted':
            return '#FCE9E9';
         case 'pending':
            return '#e3f0fd';
         case null:
         case '':
            return '#FDF2E7';
         default:
            return '#00a3ff';
      }
   }};
   color: ${({ status }) => {
      switch (status) {
         case 'accepted':
         case 'approved':
         case 'on':
         case 'completed':
         case 'active':
         case 'delivered':
            return '#41D87D';
         case 'rejected':
         case 'unapproved':
         case 'off':
         case 'defaulted':
            return '#E43535';
         case 'pending':
            return '#1354d2';
         case null:
         case '':
            return '#F08829';
         default:
            return '#fff';
      }
   }};

   padding: 0.5rem;
   border-radius: 4px;
   text-transform: capitalize;

   max-height: 30px;
   display: inline-flex;
   justify-content: center;
   align-items: center;
   margin: 0 auto;
`;
