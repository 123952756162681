import React, { forwardRef } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@material-ui/data-grid';
import { useHistory, useLocation } from 'react-router-dom';
import { string, arrayOf, bool, oneOfType, number, func } from 'prop-types';

const useStyles = makeStyles({
   root: {
      position: 'relative',

      '& .super-app-theme--header': {
         backgroundColor: '#f8f9fa',
         fontSize: '1.4rem',
         fontFamily: "'Mark OT', sans-serif",
         top: '0',
         boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)',
      },

      '& .MuiDataGrid-iconSeparator': {
         display: 'none',
      },

      '& .MuiDataGrid-row': {
         fontSize: '1.35rem',
         fontFamily: "'Mark OT', sans-serif",
         backgroundColor: '#fff',
         margin: '0 auto',
         fontWeight: 400,
         maxHeight: '20px',
         letterSpacing: '-0.1px',
      },

      '& .MuiDataGrid-root .MuiDataGrid-cell': {
         justifyContent: 'flex-start',
         whiteSpace: 'nowrap',
         overflow: 'hidden',
         textoOerflow: 'ellipsis',
      },

      '& .MuiDataGrid-footerContainer': {
         display: 'none',
      },
   },
});

export const usePaginationStyles = makeStyles({
   root: {
      '& .MuiPaginationItem-root': {
         fontSize: '1.4rem',
         fontFamily: "'Mark OT', sans-serif",
         margin: '10px 0 30px 0',
         marginLeft: 'auto',
         fontWeight: 400,
      },
   },
   ul: {
      fontSize: '1.7rem',
      fontFamily: "'Mark OT', sans-serif",
      justifyContent: 'flex-end',
   },
});

export const TableComponent = forwardRef(
   (
      {
         columns,
         rows,
         isLoading,
         checkboxSelection,
         count,
         page,
         filter,
         onPageChange,
         onSelectionModelChange,
         // selectionModel,
         pageSize,
         onRowClick,
      },
      ref,
   ) => {
      const classes = useStyles();
      const paginationClasses = usePaginationStyles();
      const { search } = useLocation();
      const pageUrl = new URLSearchParams(search).get('page');
      const cols = React.useMemo(
         () =>
            columns.map((item) => ({
               width: 200,
               headerClassName: 'super-app-theme--header',
               sortable: false,
               disableClickEventBubbling: true,
               ...item,
            })),
         [columns],
      );

      const data = React.useMemo(
         () =>
            rows?.map((item) => ({
               ...item,
               id: item?.id || JSON.stringify(item),
            })),
         [rows],
      );
      // console.log(selectionModel.map(item => JSON.stringify(item)))
      const history = useHistory();
      return (
         <div className={classes.root}>
            <DataGrid
               rows={data}
               columns={cols}
               pageSize={pageSize || 15}
               disableColumnMenu
               sortingMode="server"
               hideFooterSelectedRowCount
               hideFooterPagination
               checkboxSelection={checkboxSelection}
               disableSelectionOnClick
               loading={isLoading}
               ref={ref}
               autoHeight
               headerHeight={45}
               rowHeight={45}
               onRowClick={onRowClick}
               onSelectionModelChange={(value) => {
                  // console.log(value)
                  onSelectionModelChange(value);
               }}
            />
            {onPageChange && (
               <div style={{ margin: '0 auto' }}>
                  <Pagination
                     classes={paginationClasses}
                     count={count}
                     page={parseInt(pageUrl, 10) || parseInt(page, 10) || 1}
                     onChange={(e, num) => {
                        onPageChange(e, num);
                        history.push(
                           `${history.location.pathname}?page=${num}${
                              filter ? `&filter=${filter}` : ''
                           }`,
                        );
                     }}
                     boundaryCount={4}
                     disabled={isLoading}
                  />
               </div>
            )}
         </div>
      );
   },
);

TableComponent.propTypes = {
   columns: arrayOf(string.isRequired).isRequired,
   rows: arrayOf(oneOfType([string, number])).isRequired,
   isLoading: bool,
   checkboxSelection: bool,
   count: number.isRequired,
   page: number.isRequired,
   onPageChange: func.isRequired,
   onSelectionModelChange: func.isRequired,
   filter: string,
   onRowClick: func.isRequired,
   pageSize: number,
};

TableComponent.defaultProps = {
   isLoading: false,
   checkboxSelection: false,
   filter: null,
   pageSize: 15,
};
