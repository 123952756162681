/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'components/button';
import { Skeleton } from '@material-ui/lab';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TableContainer, TableContent } from 'styles/table';
import { MenuComponent } from 'components/menu';
import { IconButton } from '@material-ui/core';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import LoggedInDashboard from 'components/logged-in-dashboard';
import UpdateCardData from 'containers/cards/NewCardModal';
import { getCardDetails } from 'services/cards';

const Cards = () => {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [currentPage, setCurrentPage] = useState(page || 1);
   const [tableData, setTableData] = useState([]);
   const [, setError] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [, setCardId] = useState('');
   const [newCardModal, setNewCardModal] = useState(false);
   const [editCardModal, setEditCardModal] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [editDefault, setEditDefault] = useState({});

   const fetchCardData = async () => {
      try {
         const result = await getCardDetails({ page: currentPage });
         if (result.ok) {
            setTableData(result.results);
            setPaginationData(result);
            setError('');
         }
         setIsLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setIsLoading(false);
      }
   };

   useEffect(() => {
      fetchCardData();
   }, [currentPage]);

   const options = ['Edit'];

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleOpenModal = () => {
      setEditCardModal(true);
   };

   const columns = [
      {
         field: 'action_type',
         headerName: 'Action Type',
         renderCell: ({ row }) => (
            <TooltipComponent
               title={
                  row?.action_type.charAt(0).toUpperCase() +
                  row?.action_type.slice(1).replaceAll('_', ' ')
               }
            >
               {row?.action_type.charAt(0).toUpperCase() +
                  row?.action_type.slice(1).replaceAll('_', ' ')}
            </TooltipComponent>
         ),
      },
      {
         field: 'action_amount',
         headerName: 'Action Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.action_amount}>{row?.action_amount}</TooltipComponent>
         ),
      },
      {
         field: 'action_value',
         width: 300,
         headerName: 'Action Value',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.action_value}>
               {row?.action_value || 'NA'}
            </TooltipComponent>
         ),
      },
      {
         field: 'limit_count',
         headerName: 'Limit Count',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.limit_count}>{row?.limit_count}</TooltipComponent>
         ),
      },
      {
         field: 'action_description',
         headerName: 'Action Description',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.action_description}>
               {row?.action_description || 'NA'}
            </TooltipComponent>
         ),
      },
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreVertIcon />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[() => handleOpenModal(row.id)]}
               />
            </div>
         ),
      },
   ];

   function onRowClick({ row }) {
      setCardId(row.id);
      setEditDefault(row);
   }

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <LoggedInDashboard pageTitle="Corporate Cards">
         <TableContainer className="activity">
            <TableContent>
               <div className="d-flex justify-content-between w-100 align-items-center">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1>Cards Fees and Charges</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}
               </div>

               <div style={{ marginLeft: '10px' }}>
                  <Button onClick={() => setNewCardModal(true)}>Create New Board</Button>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               onRowClick={onRowClick}
            />
            {newCardModal && (
               <UpdateCardData
                  open={newCardModal}
                  editDefault={{}}
                  fetchCardDetails={fetchCardData}
                  onClose={() => setNewCardModal(false)}
               />
            )}
            {editCardModal && (
               <UpdateCardData
                  isEdit
                  open={editCardModal}
                  editDefault={editDefault}
                  fetchCardDetails={fetchCardData}
                  onClose={() => setEditCardModal(false)}
               />
            )}
         </TableContainer>
      </LoggedInDashboard>
   );
};

export default Cards;
