import { Http } from 'services/http';
import { paramsObjectToQueryString } from 'utils/others';
import axiosCustom from 'utils/axios';

export const getAllAccountPlans = (payload) => {
   return Http(`/backmen/billing_plan/${paramsObjectToQueryString(payload)}`);
};

export const getBillingPlans = (page) => {
   return Http(`/bill/plans/${paramsObjectToQueryString(page)}`);
};

export const getSingleBillingPlan = (id) => {
   return Http(`/bill/plans/${id}/`);
};

export const getAllBillingRates = () => {
   return Http(`/backmen/billing_rate/`);
};

export const createBillingRate = async (payload) => {
   try {
      const response = await Http(`/backmen/billing_rate/`, {
         method: 'POST',
         body: payload,
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const updateBillingRate = async (payload, id) => {
   try {
      const response = await Http(`/backmen/billing_rate/${id}`, {
         method: 'PATCH',
         body: payload,
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const deleteBillingRate = async (id) => {
   try {
      const response = await Http(`/backmen/billing_rate/${id}`, {
         method: 'DELETE',
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const createNewPlan = async (payload) => {
   try {
      const response = await Http(`/bill/plans/`, {
         method: 'POST',
         body: payload,
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const updateBillingPlan = async (payload, id) => {
   try {
      const response = await Http(`/bill/plans/${id}/`, {
         method: 'PATCH',
         body: payload,
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const deleteBillingPlan = async (id) => {
   try {
      const response = await Http(`/bill/plans/${id}/`, {
         method: 'DELETE',
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const createPlanBenefit = async (payload) => {
   try {
      const axiosInstance = axiosCustom(); // Invoke the axiosCustom function to get the Axios instance
      const response = await axiosInstance.post(`/bill/benefits/`, payload);
      return response;
   } catch (e) {
      throw new Error(e?.message);
   }
};

export const updatePlanBenefit = async (payload, id) => {
   try {
      const axiosInstance = axiosCustom(); // Invoke the axiosCustom function to get the Axios instance
      const response = await axiosInstance.patch(`/bill/benefits/${id}/`, payload);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const deletePlanBenefit = async (id) => {
   try {
      const response = await Http(`/bill/benefits/${id}/`, {
         method: 'DELETE',
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const getSinglePlanBenefit = (id) => {
   return Http(`/bill/benefits/${id}/`);
};

export const createSubBenefit = async (payload) => {
   try {
      const axiosInstance = axiosCustom(); // Invoke the axiosCustom function to get the Axios instance
      const response = await axiosInstance.post(`/bill/sub_benefits/`, payload);
      return response;
   } catch (e) {
      throw new Error(e?.message);
   }
};

export const updateSubBenefit = async (payload, id) => {
   try {
      const axiosInstance = axiosCustom(); // Invoke the axiosCustom function to get the Axios instance
      const response = await axiosInstance.patch(`/bill/sub_benefits/${id}/`, payload);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const deleteSubBenefit = async (id) => {
   try {
      const response = await Http(`/bill/sub_benefits/${id}/`, {
         method: 'DELETE',
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
