import { ProtectedRoute } from 'components/protected-route';
import { AnimatedRoutes } from 'components/route-animation/route-animation';
import Login from 'pages/login';
import Points from 'pages/points';
import Webstore from 'pages/webstore';
import { Redirect } from 'react-router-dom';
import Loans from 'pages/loans';
import Partners from 'pages/partners';
import Pos from 'pages/pos';
import BusinessAccounts from 'pages/business-accounts';
import LimitBoard from 'pages/limit-board';
import Cards from 'pages/cards';
import { Billing } from 'pages/billing';

function Routes() {
   return (
      <AnimatedRoutes exitBeforeEnter initial={false}>
         <ProtectedRoute path="/pos" component={Pos} exact />
         <ProtectedRoute path="/cards" component={Cards} exact />
         <ProtectedRoute path="/partners" component={Partners} exact />
         <ProtectedRoute path="/loans" component={Loans} exact />
         <ProtectedRoute path="/business-accounts" component={BusinessAccounts} exact />
         <ProtectedRoute path="/points" component={Points} exact />
         <ProtectedRoute path="/webstore" component={Webstore} exact />
         <ProtectedRoute path="/limit-board" component={LimitBoard} exact />
         <ProtectedRoute path="/billing" component={Billing} />
         <Redirect from="/" to="/business-accounts" exact />
         <ProtectedRoute notProtected path="/login" component={Login} exact />
      </AnimatedRoutes>
   );
}

export default Routes;
