import { getToken } from 'utils/storage';

function logout() {
   window.localStorage.clear();
}

function Http(endpoint, { method = 'GET', ...customConfig } = {}) {
   const token = getToken();
   let url = '';

   const headers = { 'content-type': 'application/json' };

   if (token) {
      headers.Authorization = `Token ${token}`;
   }

   const config = {
      method,
      ...customConfig,
      headers: {
         ...headers,
         ...customConfig.headers,
      },
   };

   if (config.body) {
      config.body = JSON.stringify(config.body);
   }

   if (!process.env.REACT_APP_API_URL) {
      url = endpoint;
   } else {
      url = `${process.env.REACT_APP_API_URL}${endpoint}`;
   }

   return window.fetch(url, config).then(async (response) => {
      let message;
      const data = await response.json();

      if (response.ok) {
         return Promise.resolve({
            ok: response.ok,
            ...data,
         });
      }

      switch (response.status) {
         case 400:
            message = 'Bad Request';
            break;
         case 401:
            logout();
            window.location.assign(window.location);
            message = "You're not Authenticated. Kindly Login";
            break;
         case 403:
            message = 'UnAuthorised User';
            break;
         case 404:
            message = 'Resource not found';
            break;
         case 500:
            message = 'Internal server error ';
            break;
         default:
            message = '';
      }

      const errorRes = {
         ok: response.ok,
         custom_message: message,
         ...data,
      };

      await Promise.reject(errorRes);
   });
}
export { Http };
