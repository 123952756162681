import { Input } from 'components/input';
import Modal from 'components/modal';
import useBusinessMeta from 'hooks/businessMeta';
import { useHistory } from 'react-router-dom';
import { func, bool, string, number, objectOf } from 'prop-types';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import moment from 'moment';
import { SelectInput } from 'components/select';
import { getAllManagers, getUnApprovedAccounts } from 'services/business';
import { useEffect, useState } from 'react';

const businessType = [
   {
      value: 'registered',
      label: 'Registered',
   },
   {
      value: 'freelancer',
      label: 'Freelancer',
   },
];

const status = [
   {
      value: 'pending',
      label: 'Uploaded',
   },
   {
      value: 'accepted',
      label: 'Accepted',
   },
   {
      value: 'rejected',
      label: 'Rejected',
   },
];

const bvn_options = [
   {
      value: true,
      label: 'True',
   },
   {
      value: false,
      label: 'False',
   },
];

const turnover = [
   {
      value: 'less_than_100k',
      label: 'Less than 100K',
   },
   {
      value: '100k-1m',
      label: '100K - 1M',
   },
   {
      value: '1m-5m',
      label: '1M - 5M',
   },
   {
      value: '5m-10m',
      label: '5M - 10M',
   },
   {
      value: '10m-50m',
      label: '10M - 50M',
   },
   {
      value: '50m_above',
      label: 'Above 50M',
   },
];

const payment = [
   {
      value: 'has_paid',
      label: 'Paid',
   },
   {
      value: 'not_paid',
      label: 'Unpaid',
   },
];

const decision = [
   {
      value: 'approved',
      label: 'Approved',
   },
   {
      value: 'pending',
      label: 'Pending',
   },
];

const BusinessAccountFilterModal = ({
   open,
   onClose,
   setBusinessInfo,
   currentPage,
   setPaginationData,
   setCurrentPage,
   filterFunction,
   active,
   filterData,
   setfilterData,
}) => {
   const history = useHistory();
   const [managers, setManagers] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [bizCurrentStatus, setBizCurrentStatus] = useState([]);
   const { businessCurrentStatus } = useBusinessMeta();
   function onChange(name, data) {
      setfilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   async function onSubmit() {
      try {
         setCurrentPage(() => 1);
         setIsLoading(true);
         const payload = {
            ...filterData,
            ...(!filterData.start_date && { start_date: '2019-01-01' }),
            ...(!filterData.end_date && { end_date: moment().format('YYYY-MM-DD') }),
         };
         const result = filterFunction
            ? await filterFunction({ page: 1 }, payload)
            : await getUnApprovedAccounts({ page: currentPage }, payload);
         history.push(`${history.location.pathname}?page=1`);
         onClose();
         setBusinessInfo(result.data);
         setPaginationData(result);
      } catch (err) {
         return err;
      } finally {
         setIsLoading(false);
      }
   }

   async function clearFilter() {
      try {
         setCurrentPage(1);
         const result = await getUnApprovedAccounts({ page: currentPage });
         setBusinessInfo(result.data);
         setPaginationData(result);
         setfilterData({});
         onClose();
      } catch (err) {
         return err;
      }
   }

   useEffect(() => {
      (async () => {
         try {
            const result = await getAllManagers();
            setManagers(
               result.data.map((item) => ({
                  value: item.prospa_manager_id,
                  label: `${item.first_name} ${item.last_name}`,
               })),
            );
         } catch (err) {
            return err;
         }
      })();
   }, []);

   useEffect(() => {
      if (businessCurrentStatus) {
         setBizCurrentStatus(
            businessCurrentStatus.map(({ current_status_tag }) => ({
               value: current_status_tag,
               label:
                  current_status_tag.charAt(0).toUpperCase() +
                  current_status_tag.slice(1).replaceAll('_', ' '),
            })),
         );
      }
   }, [businessCurrentStatus]);

   return (
      <Modal
         open={open}
         onClose={onClose}
         title="Filters"
         className="biz-details-modal"
         center
         width="750px"
      >
         <form className="row">
            {active.includes('date') && (
               <>
                  <div className="col-md-6 mb-4">
                     <Input
                        placeholder="Start Date"
                        label="Start Date"
                        name="start_date"
                        type="date"
                        value={filterData?.start_date}
                        onChange={({ target }) => onChange('start_date', target)}
                     />
                  </div>
                  <div className="col-md-6 mb-4">
                     <Input
                        placeholder="End Date"
                        label="End Date"
                        name="end_date"
                        type="date"
                        value={filterData?.end_date}
                        onChange={({ target }) => onChange('end_date', target)}
                     />
                  </div>
               </>
            )}
            {active.includes('manager') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     onChange={(val) => onChange('assigned_manager_id', val)}
                     name="assigned_manager_id"
                     label="Manager"
                     options={managers}
                     value={managers.filter(
                        (item) => item.value === filterData?.assigned_manager_id,
                     )}
                  />
               </div>
            )}

            {active.includes('biz_type') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     name="biz_type"
                     label="Business Type"
                     options={businessType}
                     onChange={(val) => onChange('biz_type', val)}
                     value={businessType.filter((item) => item.value === filterData?.biz_type)}
                  />
               </div>
            )}

            {active.includes('identity') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     label="ID status"
                     options={status}
                     onChange={(val) => onChange('identity_file_status', val)}
                     value={status.filter(
                        (item) => item.value === filterData?.identity_file_status,
                     )}
                  />
               </div>
            )}

            {active.includes('bvn') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     label="BVN"
                     options={bvn_options}
                     onChange={(val) => onChange('bvn_number', val)}
                     value={bvn_options.filter((item) => item.value === filterData?.bvn_number)}
                  />
               </div>
            )}

            {active.includes('cac') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     onChange={(val) => onChange('cac_cert_photo_status', val)}
                     label="CAC Cert Status"
                     options={status}
                     value={status.filter(
                        (item) => item.value === filterData?.cac_cert_photo_status,
                     )}
                  />
               </div>
            )}

            {active.includes('poa') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     onChange={(val) => onChange('has_address_verified', val)}
                     name="has_address_verified"
                     label="Proof of Address"
                     options={status}
                     value={status.filter(
                        (item) => item.value === filterData?.has_address_verified,
                     )}
                  />
               </div>
            )}

            {active.includes('payment') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     label="Payment Status"
                     onChange={(val) => onChange('payment_status', val)}
                     options={payment}
                     value={payment.filter((item) => item.value === filterData?.payment_status)}
                  />
               </div>
            )}

            {active.includes('decision') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     label="Decision Status"
                     onChange={(val) => onChange('decision_status', val)}
                     options={decision}
                     value={decision.filter((item) => item.value === filterData?.decision_status)}
                  />
               </div>
            )}

            {active.includes('turn_over') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     label="Turnover"
                     value={turnover.filter((item) => item.value === filterData?.biz_turnover)}
                     onChange={(val) => onChange('biz_turnover', val)}
                     options={turnover}
                  />
               </div>
            )}

            {active.includes('status') && (
               <div className="col-md-6 mb-4">
                  <SelectInput
                     onChange={(val) => onChange('biz_current_status', val)}
                     label="Status"
                     options={bizCurrentStatus}
                     value={bizCurrentStatus.filter(
                        (item) => item.value === filterData?.biz_current_status,
                     )}
                  />
               </div>
            )}

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button variant="secondary" type="button" onClick={clearFilter} fullWidth>
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={onSubmit}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export { BusinessAccountFilterModal };

BusinessAccountFilterModal.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   filterData: objectOf(string).isRequired,
   setfilterData: func.isRequired,
   setBusinessInfo: func.isRequired,
   currentPage: number.isRequired,
   setPaginationData: func.isRequired,
   setCurrentPage: func.isRequired,
   filterFunction: func.isRequired,
   active: bool.isRequired,
};
