import { Http } from 'services/http';
import { paramsObjectToQueryString } from 'utils/others';

export const getAllPosOrders = (page) =>
   Http(`/backmen/all_pos_placed_order/${paramsObjectToQueryString(page)}`);
export const getSinglePosOrders = (id) => Http(`/backmen/pos_order_details/${id}`);
export const getAllPosTerminals = (page) =>
   Http(`/backmen/added_pos_terminals/${paramsObjectToQueryString(page)}`);
export const getTerminalsSingleBiz = (biz_account_id) =>
   Http(`/backmen/tagged_biz_pos_terminals/${biz_account_id}/`);

export const addPosTerminal = (payload) =>
   Http(`/backmen/add_new_pos_terminal/`, {
      method: 'POST',
      body: payload,
   });

export const reassignPosTerminal = (payload) =>
   Http(`/backmen/re_assign_pos_terminal/`, {
      method: 'POST',
      body: payload,
   });

export const removePosTerminal = (payload) =>
   Http(`/backmen/remove_pos_terminal/`, {
      method: 'POST',
      body: payload,
   });

export const changePosDeliveryStatus = (payload) =>
   Http(`/backmen/add_pos_delivery_status/`, {
      method: 'POST',
      body: payload,
   });

export const getPosStats = () => Http(`/backmen/new_mng_pos_stat/`);
