import styled from 'styled-components';

export const CardsContainer = styled.div`
   display: grid;
   // grid-template-columns: repeat(${({ gridNumber }) => gridNumber && gridNumber}, 1fr);
   grid-template-columns: repeat(3, 1fr);
   column-gap: 20px;
   overflow-x: auto;
   width: 100%;
`;
export const Card = styled.div`
   padding: 2.4rem 2rem;
   background-color: #fff;
   box-shadow: -1px 2px 15px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   min-width: 300px;
   margin-bottom: 30px;

   // &:first-child {
   //    background-color: var(--color-blue);
   //    color: #fff;
   // }

   .text {
      font-size: 1.5rem;
      text-transform: capitalize;
   }

   .value {
      margin-top: 3.5rem;
      font-size: 2.3rem;
      font-weight: 500;
   }
`;
