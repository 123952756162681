import { Http } from 'services/http';

export const getSettings = () => {
   return Http(`/settings/settings/`);
};

export const patchSettings = async (id, payload) => {
   try {
      const response = await Http(`/settings/${id}/`, {
         method: 'PATCH',
         body: payload,
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
