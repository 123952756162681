/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useAuth } from 'hooks/auth';
import { loan_admin, prospasuperadmin } from 'utils/user_types';
import FilterListIcon from '@material-ui/icons/FilterList';
import LoggedInDashboard from 'components/logged-in-dashboard';
import { TableContainer, TableContent, FilterContainer } from 'styles/table';
// import { formatTag } from 'utils/tags';
import { TooltipComponent } from 'components/tooltip';
import { Skeleton } from '@material-ui/lab';
import { HeadingOne } from 'styles/typography';
import toast from 'react-hot-toast';
import { LoanWorkerBtn } from 'components/start-work-btn';
import { TableComponent } from 'components/table';
import { Status } from 'styles/utils';
import moment from 'moment';
import { numberWithCommas } from 'utils/others';
// import TranasactionModal from 'components/TransactionModal';
import LoanDetailsModal from 'containers/loan-details';
import { LoansFilterModal } from 'containers/business-account-filter-modal';
import { getPCRLabel } from 'utils/loans';
import { StatsCards } from 'components/stats-cards';
import { fetchLoanApplications, fetchLoanStats } from 'services/loans';
import { useLocation } from 'react-router-dom';
// import { Input } from 'components/input';

function Loans() {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [modalVisibilityFilter, setModalVisibilityFilter] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(page || 1);
   const [bizAccount, setBizAccount] = useState('');
   const [detailsModal, setDetailsModal] = useState(false);
   const [singleLoan, setSingleLoan] = useState('');
   const [workerActiveStatus, setWorkerActiveStatus] = useState(false);
   const [statsData, setStatsData] = useState({});
   const [workerDateRange, setWorkerDateRange] = useState({});
   const [filterDate, setFilterDate] = useState({
      start_date: '2021-01-01',
      end_date: moment().format('YYYY-MM-DD'),
   });
   const [filterLoanStatus, setFilterLoanStatus] = useState(null);
   const { currentUser } = useAuth();

   function handleOpenModal(row) {
      // setActiveTab('loan-overview');
      setBizAccount(row.biz_account_id);
      setSingleLoan(row.biz_loan_id);
      setDetailsModal(true);
      setWorkerActiveStatus(false);
   }

   const columns = [
      {
         field: 'biz_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.biz_name}>
               <span
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                  onClick={() => handleOpenModal(row, false)}
               >
                  {row.biz_name}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_amount',
         headerName: 'Requested Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_amount}>
               ₦{numberWithCommas(row?.loan_amount)}
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_purpose',
         headerName: 'Loan Purpose',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_purpose}>{row?.loan_purpose}</TooltipComponent>
         ),
      },
      {
         field: 'loan_status',
         headerName: 'Loan Status',
         // width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_status}>
               <Status status={row?.loan_status}>{row.loan_status}</Status>
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_repayment_status',
         headerName: 'Loan Repayment Status',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_repayment_status}>
               <Status
                  status={
                     row?.loan_repayment_status === 'defaulted'
                        ? 'rejected'
                        : row?.loan_repayment_status
                  }
               >
                  {row.loan_repayment_status}
               </Status>
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_crs_score',
         headerName: 'Loan CRS Score',
         renderCell: ({ row }) => (
            <TooltipComponent
               title={
                  <>
                     {getPCRLabel(row?.loan_crs_score)} | {row?.loan_crs_score}
                  </>
               }
            >
               {getPCRLabel(row?.loan_crs_score)} | {row?.loan_crs_score || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_tenure',
         headerName: 'Loan Tenure',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_tenure}>{row?.loan_tenure} days</TooltipComponent>
         ),
      },
      {
         field: 'loan_disbursement_status',
         headerName: 'Loan Disbursement',
         // width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_officer_details?.first_name}>
               <Status status={row?.loan_disbursement_status}>
                  {row.loan_disbursement_status || 'N/A'}
               </Status>
               <span>
                  {' '}
                  {row?.loan_approved_by_details &&
                     ` | ${row?.loan_approved_by_details.first_name} ${row?.loan_approved_by_details.last_name}`}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_outstanding',
         headerName: 'Loan Outstanding Balance',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_outstanding}>
               ₦{numberWithCommas(row?.loan_outstanding)}
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_repayment_balance',
         headerName: 'Total Repayed Amount',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.loan_repayment_balance}>
               ₦{numberWithCommas(row?.loan_repayment_balance)}
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_repay_end_date',
         headerName: 'Loan Repayment Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={moment(row?.loan_repay_end_date).format('DD MMM, YYYY')}>
               {moment(row?.loan_repay_end_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>
                  {moment(row?.loan_repay_end_date).format('HH:mm A')}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'loan_officer_details',
         headerName: 'Loan Officer',
         renderCell: ({ row }) =>
            row?.loan_officer_details ? (
               <TooltipComponent
                  title={`${row?.loan_officer_details?.first_name} ${row?.loan_officer_details?.last_name}`}
               >
                  {`${row?.loan_officer_details?.first_name} ${row?.loan_officer_details?.last_name}`}
               </TooltipComponent>
            ) : (
               'N/A'
            ),
      },
      {
         field: 'pub_date',
         headerName: 'Requested Date',
         renderCell: ({ row }) => (
            <TooltipComponent title={moment(row.pub_date).format('DD MMM, YYYY')}>
               {moment(row.pub_date).format('DD MMM, YYYY')} |{' '}
               <span style={{ opacity: '.5' }}>{moment(row.pub_date).format('HH:mm A')}</span>
            </TooltipComponent>
         ),
      },
   ];

   const initializeLoanTable = async () => {
      setIsLoading(true);
      Promise.all([
         fetchLoanApplications(
            {
               ...filterDate,
               ...(filterLoanStatus && { loan_status: filterLoanStatus }),
            },
            { page },
         ),
      ])
         .then(([loans]) => {
            setTableData(loans.data);
            setPaginationData(loans);
            setModalVisibilityFilter(false);
            // console.log('Loans : ', loans.data);
         })
         .finally(() => {
            setIsLoading(false);
         });
   };
   useEffect(() => {
      (async function getLoanStats() {
         try {
            const result = await fetchLoanStats();
            const {
               total_loan_disbursement_amt,
               total_loan_disbursed,
               loan_float_balance,
               total_amount_repaid,
               total_loan_earnings_interest,
               total_loan_earnings_admin_fee,
               total_defaulted_loan,
               total_defaulted_loan_amt,
            } = result;
            if (currentUser.user_type === loan_admin) {
               return setStatsData({
                  loan_float_balance: `₦${numberWithCommas(loan_float_balance)}`,
                  total_defaulted_loan,
                  total_defaulted_loan_amt: `₦${numberWithCommas(total_defaulted_loan_amt)}`,
               });
            }
            setStatsData({
               loan_float_balance: `₦${numberWithCommas(loan_float_balance)}`,
               total_loan_disbursed,
               total_loan_disbursed_amt: `₦${numberWithCommas(total_loan_disbursement_amt)}`,
               total_amount_repaid: `₦${numberWithCommas(total_amount_repaid)}`,
               total_loan_earnings_interest: `₦${numberWithCommas(total_loan_earnings_interest)}`,
               total_loan_earnings_admin_fee: `₦${numberWithCommas(total_loan_earnings_admin_fee)}`,
               total_defaulted_loan,
               total_defaulted_loan_amt: `₦${numberWithCommas(total_defaulted_loan_amt)}`,
            });
         } catch (e) {
            toast.error(e.message);
         }
      })();
   }, [currentUser]);
   useEffect(() => {
      initializeLoanTable();
   }, [currentPage, page, filterDate]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <LoggedInDashboard>
         <div className="d-flex justify-content-between align-items-center">
            <HeadingOne className="mb-0"> Loan Applications</HeadingOne>
            <LoanWorkerBtn
               setModalVisibility={(value) => {
                  setBizAccount(value.biz_account_id);
                  setSingleLoan(value.biz_loan_id);
                  setDetailsModal(true);
                  setWorkerActiveStatus(true);
               }}
               setWorkerDateRange={setWorkerDateRange}
               workerDateRange={workerDateRange}
            />
         </div>
         {currentUser.user_type &&
            (currentUser.user_type === prospasuperadmin ||
               currentUser.user_type === loan_admin) && <StatsCards data={statsData} />}
         <TableContainer>
            <TableContent>
               <div className="d-flex justify-content-between w-100 align-items-center">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1> All Loan Applications</h1>
                        <span className="value">{paginationData?.count || 'N/A'}</span>
                     </div>
                  )}
                  <FilterContainer onClick={() => setModalVisibilityFilter(true)}>
                     Filter By <FilterListIcon style={{ marginLeft: '10px' }} />
                  </FilterContainer>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               page={page}
            />
         </TableContainer>

         {detailsModal && (
            <LoanDetailsModal
               open={detailsModal}
               onClose={() => {
                  setDetailsModal(false);
                  initializeLoanTable();
                  setWorkerActiveStatus('');
               }}
               // data={transactionDetail}
               businessAccountId={bizAccount}
               loan={singleLoan}
               workerActiveStatus={workerActiveStatus}
               workerDateRange={workerDateRange}
            />
         )}
         {modalVisibilityFilter && (
            <LoansFilterModal
               open={modalVisibilityFilter}
               onClose={() => setModalVisibilityFilter(false)}
               setCurrentPage={setCurrentPage}
               setFilterDate={setFilterDate}
               filterDate={filterDate}
               setFilterLoanStatus={setFilterLoanStatus}
               filterLoanStatus={filterLoanStatus}
            />
         )}
      </LoggedInDashboard>
   );
}

export default Loans;
