import { formatTag } from 'utils/tags';

export const getDocumentType = (type) => {
   let value = '';
   switch (type) {
      case 'is_identity_verified':
         value = 'Identity File';
         break;
      case 'is_cac_cert_photo_verified':
         value = `CAC Certificate`;
         break;
      case 'is_board_verified':
         value = `Other Director Details`;
         break;
      case 'is_address_verified':
         value = 'Proof of Address';
         break;
      case 'is_tin_document_verified':
         value = `Addtional CAC Documents`;
         break;
      case 'is_tin_number_verified':
         value = `TIN Number`;
         break;
      case 'is_director_match_verified':
         value = `Business Verification for Director Match`;
         break;
      default:
         value = '';
   }

   return value;
};

export const renderTransactionStatus = (row) => {
   let template = '';
   if (row?.transfer_details) {
      template =
         row?.transfer_details?.transfer_status === 'success'
            ? formatTag('accepted', 'Success')
            : formatTag(
                 row?.transfer_details?.transfer_status,
                 row?.transfer_details?.transfer_status,
              );
   } else if (row?.bill_payment_details) {
      template =
         row?.bill_payment_details?.biller_status === 'success'
            ? formatTag('accepted', 'Success')
            : formatTag(
                 row?.bill_payment_details?.biller_status,
                 row?.bill_payment_details?.biller_status,
              );
   } else if (row?.payout_details) {
      template =
         row?.payout_details?.payout_status === 'success'
            ? formatTag('accepted', 'Success')
            : formatTag(row?.payout_details?.payout_status, row?.payout_details?.payout_status);
   } else {
      template = row?.is_done ? formatTag('accepted', 'Success') : formatTag('rejected', 'Failed');
   }
   return template;
};
