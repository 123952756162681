import { useEffect, useState } from 'react';
import { func, objectOf, bool, string } from 'prop-types';
import Modal from 'components/modal';
import { Input, NumberInput } from 'components/input';
import { SelectInput } from 'components/select';
import { toast } from 'react-hot-toast';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { updateLimitBoardDetails, createLimitBoardDetails } from 'services/limit-board';
import { LIMIT_TYPES, ACCOUNT_TYPE } from 'utils/constants';

function EditLimitData({ isEdit = false, editDefault, fetchLimitDetails, onClose, open }) {
   const [updatingData, setUpdatingData] = useState(false);
   const [loading, setLoading] = useState(false);
   const [newData, setNewData] = useState({});

   const options = [
      {
         value: 'true',
         label: 'True',
      },
      {
         value: 'false',
         label: 'False',
      },
   ];

   const {
      action_type,
      account_plan_type,
      direct_state,
      outbound_amount,
      inbound_amount,
      from_amount,
      to_amount,
      extra_amount,
      limit_count,
   } = editDefault;

   const detailsUnchanged = () =>
      newData.action_type === action_type &&
      newData.account_plan_type === account_plan_type &&
      newData.direct_state === direct_state &&
      newData.outbound_amount === outbound_amount &&
      newData.inbound_amount === inbound_amount &&
      newData.from_amount === from_amount &&
      newData.to_amount === to_amount &&
      newData.extra_amount === extra_amount &&
      newData.limit_count === limit_count;

   const state = newData?.direct_state?.toString();

   function onChange(name, data) {
      setNewData({
         ...newData,
         [name]: data.value,
      });
   }

   const addNewLimitDetails = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
         const result = await createLimitBoardDetails(newData);
         if (result.ok) {
            fetchLimitDetails();
            toast.success(addNewLimitDetails?.message || 'Created Successfully', {
               duration: 3000,
            });
            onClose();
         }
         setUpdatingData(false);
      } catch (err) {
         toast.error(err.message || 'An error has occurred');
         setUpdatingData(false);
      }
      setLoading(false);
   };

   const editLimitDetails = async (e) => {
      e.preventDefault();
      setUpdatingData(true);
      try {
         const result = await updateLimitBoardDetails(newData, editDefault.id);
         if (result.ok) {
            fetchLimitDetails();
            toast.success(editLimitDetails?.message || 'Details Updated Successfully', {
               duration: 3000,
            });
            onClose();
         }
         setUpdatingData(false);
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setUpdatingData(false);
      }
   };

   useEffect(() => {
      setNewData(editDefault);
   }, [editDefault]);

   return (
      <Modal open={open} onClose={onClose} title={isEdit ? 'Edit Limit Board' : 'Create New Data'}>
         <form onSubmit={isEdit ? editLimitDetails : addNewLimitDetails}>
            <InputWrapper className="grid-2-2">
               <SelectInput
                  label="Action Type"
                  options={LIMIT_TYPES}
                  value={LIMIT_TYPES.filter((item) => item.value === newData.action_type)}
                  onChange={(target) => onChange('action_type', target)}
               />

               <SelectInput
                  label="Account Plan Type"
                  options={ACCOUNT_TYPE}
                  value={ACCOUNT_TYPE.filter((item) => item.value === newData.account_plan_type)}
                  onChange={(target) => onChange('account_plan_type', target)}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <div>
                  <NumberInput
                     label="Inbound Amount"
                     name="inbound_amount"
                     value={newData.inbound_amount}
                     prefix=" "
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('inbound_amount', { value: valuesObject.floatValue });
                     }}
                  />
               </div>
               <div>
                  <NumberInput
                     label="Outbound Amount"
                     name="outbound_amount"
                     value={newData.outbound_amount}
                     prefix=" "
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('outbound_amount', { value: valuesObject.floatValue });
                     }}
                  />
               </div>
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <div>
                  <NumberInput
                     label="From Amount"
                     name="from_amount"
                     value={newData.from_amount}
                     prefix=" "
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('from_amount', { value: valuesObject.floatValue });
                     }}
                  />
               </div>
               <div>
                  <NumberInput
                     label="To Amount"
                     name="to_amount"
                     value={newData.to_amount}
                     prefix=" "
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('to_amount', { value: valuesObject.floatValue });
                     }}
                  />
               </div>
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <div>
                  <NumberInput
                     label="Charge"
                     name="extra_amount"
                     value={newData.extra_amount}
                     prefix=" "
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('extra_amount', { value: valuesObject.floatValue });
                     }}
                  />
               </div>

               <div>
                  <NumberInput
                     label="Limit Count"
                     name="limit_count"
                     value={newData.limit_count}
                     prefix=" "
                     className={Input}
                     inputmode="numeric"
                     onChange={(valuesObject) => {
                        onChange('limit_count', { value: valuesObject.floatValue });
                     }}
                  />
               </div>
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <SelectInput
                  label="Direct State"
                  options={options}
                  value={options.filter((item) => item.value === state)}
                  onChange={(target) => onChange('direct_state', target)}
               />
            </InputWrapper>

            <div className="d-flex justify-content-end pt-5">
               {isEdit ? (
                  <Button
                     type="submit"
                     isLoading={updatingData}
                     disabled={detailsUnchanged() || updatingData || loading}
                  >
                     Update Details
                  </Button>
               ) : (
                  <Button type="submit" isLoading={updatingData} disabled={updatingData || loading}>
                     Add New Data
                  </Button>
               )}
            </div>
         </form>
      </Modal>
   );
}

EditLimitData.propTypes = {
   isEdit: bool.isRequired,
   onClose: func.isRequired,
   open: func.isRequired,
   editDefault: objectOf(string).isRequired,
   fetchLimitDetails: func.isRequired,
};

export default EditLimitData;
