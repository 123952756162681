import { Http } from 'services/http';
import { logActivity } from 'services/activity';
import { paramsObjectToQueryString } from 'utils/others';

export const fetchLoanApplications = (payload, page) =>
   Http(`/backmen/loan_list_check/${paramsObjectToQueryString(page)}`, {
      method: 'POST',
      body: payload,
   });

export const getPendingLoanStream = (payload) =>
   Http(`/backmen/pending_loan_stream_check/`, {
      method: 'POST',
      body: payload,
   });

export const loanWorkerTracker = (payload) =>
   Http(`/backmen/admin_loan_work_action/`, {
      method: 'POST',
      body: payload,
   });

export const fetchLoanRepaymentHistory = (id) => Http(`/backmen/loan_and_repayment/${id}/`);

export const loanRequestedByBiz = (id) => Http(`/backmen/loan_requested_by_biz/${id}/`);

export const businessRefActions = async (payload, biz_account_id) => {
   try {
      const response = await Http(`/backmen/biz_reference_decision/`, {
         method: 'POST',
         body: payload,
      });
      await logActivity(
         `Business reference for loan application ${payload.reference_action}`,
         'reg',
         biz_account_id,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const firstLoanApproval = async (payload, biz_account_id) => {
   try {
      const response = await Http(`/backmen/intial_loan_officer_decision/`, {
         method: 'POST',
         body: payload,
      });
      await logActivity(
         `Business loan application ${payload.biz_loan_decision} (first approval)`,
         'reg',
         biz_account_id,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const finalLoanApproval = async (payload, biz_account_id) => {
   try {
      const response = await Http(`/backmen/final_loan_decision/`, {
         method: 'POST',
         body: payload,
      });
      await logActivity(
         `Business loan application ${payload.biz_loan_decision} (final approval)`,
         'reg',
         biz_account_id,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const fetchLoanStats = () => Http(`/backmen/loan_general_stat/`);

// export const logActivity = (content, biz_type, biz_id) => {
//    const params = {
//       activity_content: content,
//       ...(biz_type === 'reg' && { biz_account_id: biz_id }),
//       ...(biz_type === 'unreg' && { unregistered_biz_id: biz_id }),
//    };
//    return Http(`/backmen/direct_backmen_activity_log/`, {
//       method: 'POST',
//       body: params,
//    });
// };
