import { useEffect, useState } from 'react';
import { func, objectOf, bool, string } from 'prop-types';
import Modal from 'components/modal';
import { Input, NumberInput } from 'components/input';
import { toast } from 'react-hot-toast';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { createNewCardDetails, updateCardDetails } from 'services/cards';

function UpdateCardData({ isEdit = false, editDefault, fetchCardDetails, onClose, open }) {
   const [updatingData, setUpdatingData] = useState(false);
   const [loading, setLoading] = useState(false);
   const [newData, setNewData] = useState({});

   const { action_type, action_amount, action_value, limit_count, action_description } =
      editDefault;

   const isValid =
      newData.action_type &&
      newData.action_amount &&
      newData.action_value &&
      newData.limit_count &&
      newData.action_description;

   const detailsUnchanged = () =>
      newData.action_type === action_type &&
      newData.action_amount === action_amount &&
      newData.action_value === action_value &&
      newData.limit_count === limit_count &&
      newData.action_description === action_description;

   function onChange(name, data) {
      setNewData({
         ...newData,
         [name]: data.value,
      });
   }

   const addNewCardDetails = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
         const result = await createNewCardDetails(newData);
         if (result.ok) {
            fetchCardDetails();
            toast.success(addNewCardDetails?.message || 'Created Successfully', {
               duration: 3000,
            });
            onClose();
         }
         setUpdatingData(false);
      } catch (err) {
         toast.error(err.message || 'An error has occurred');
         setUpdatingData(false);
      }
      setLoading(false);
   };

   const editCardDetails = async (e) => {
      e.preventDefault();
      setUpdatingData(true);
      try {
         const result = await updateCardDetails(newData, editDefault.id);
         if (result.ok) {
            fetchCardDetails();
            toast.success(editCardDetails?.message || 'Details Updated Successfully', {
               duration: 3000,
            });
            onClose();
         }
         setUpdatingData(false);
      } catch (err) {
         toast.error(err.message || err.custom_message);
         setUpdatingData(false);
      }
   };

   useEffect(() => {
      setNewData(editDefault);
   }, [editDefault]);

   return (
      <Modal open={open} onClose={onClose} title={isEdit ? 'Edit Limit Board' : 'Create New Data'}>
         <form onSubmit={isEdit ? editCardDetails : addNewCardDetails}>
            <InputWrapper className="grid-2-2">
               <Input
                  label="Action Type"
                  placeholder="Action Type"
                  name="action_type"
                  value={newData.action_type}
                  className={Input}
                  inputmode="text"
                  onChange={(e) => onChange('action_type', e.target)}
               />
               <NumberInput
                  label="Action Amount"
                  name="action_amount"
                  value={newData.action_amount}
                  prefix=" "
                  className={Input}
                  inputmode="numeric"
                  onChange={(valuesObject) => {
                     onChange('action_amount', { value: valuesObject.floatValue });
                  }}
               />
            </InputWrapper>
            <InputWrapper className="grid-2-2">
               <Input
                  label="Action Value"
                  placeholder="Action Value"
                  name="action_value"
                  value={newData.action_value}
                  className={Input}
                  inputmode="text"
                  onChange={(e) => onChange('action_value', e.target)}
               />

               <NumberInput
                  label="Limit Count"
                  placeholder="Limit Count"
                  name="limit_count"
                  value={newData.limit_count}
                  prefix=" "
                  className={Input}
                  inputmode="text"
                  onChange={(valuesObject) => {
                     onChange('limit_count', { value: valuesObject.floatValue });
                  }}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <Input
                  label="Action Description"
                  placeholder="Action Description"
                  name="action_description"
                  value={newData.action_description}
                  className={Input}
                  inputmode="text"
                  onChange={(e) => onChange('action_description', e.target)}
               />
            </InputWrapper>

            <div className="d-flex justify-content-end pt-5">
               {isEdit ? (
                  <Button
                     type="submit"
                     isLoading={updatingData}
                     disabled={!isValid || detailsUnchanged() || updatingData || loading}
                  >
                     Update Details
                  </Button>
               ) : (
                  <Button
                     type="submit"
                     isLoading={updatingData}
                     disabled={!isValid || updatingData || loading}
                  >
                     Add New Data
                  </Button>
               )}
            </div>
         </form>
      </Modal>
   );
}

UpdateCardData.propTypes = {
   isEdit: bool.isRequired,
   onClose: func.isRequired,
   open: func.isRequired,
   editDefault: objectOf(string).isRequired,
   fetchCardDetails: func.isRequired,
};

export default UpdateCardData;
