export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const LIMIT_TYPES = [
   {
      value: 'biz_account_charge',
      label: 'Biz Account Charge',
   },
   {
      value: 'interest_payment',
      label: 'Interest Payment',
   },
   {
      value: 'monthly_interest',
      label: 'Monthly Interest',
   },
   {
      value: 'monthly_turnover_per_one',
      label: 'Monthly Turnover',
   },
   {
      value: 'karma_add_automation',
      label: 'Karma Add Automation',
   },
   {
      value: 'karma_add_customer',
      label: 'Karma Add Customer',
   },
   {
      value: 'karma_add_employee',
      label: 'Karma Add Employee',
   },
   {
      value: 'karma_add_invoice',
      label: 'Karma Add Invoice',
   },
   {
      value: 'karma_add_product',
      label: 'Karma Add Product',
   },
   {
      value: 'karma_add_savings_account',
      label: 'Karma Add Savings Account',
   },
   {
      value: 'karma_add_subaccount',
      label: 'Karma Add Subaccount',
   },
   {
      value: 'karma_add_supplier',
      label: 'Karma Add Supplier',
   },
   {
      value: 'karma_add_taxaccount',
      label: 'Karma Add Taxaccount',
   },
   {
      value: 'karma_add_webstore',
      label: 'Karma Add Webstore',
   },
   {
      value: 'karma_budget_goal',
      label: 'Karma Budget Goal',
   },
   {
      value: 'karma_buy_airtime',
      label: 'Karma Buy Airtime',
   },
   {
      value: 'karma_buy_data_bundle',
      label: 'Karma Buy Data Bundle',
   },
   {
      value: 'karma_categorize_transaction',
      label: 'Karma Categorize Transaction',
   },
   {
      value: 'karma_enrol_tap',
      label: 'Karma Enrol Tap',
   },
   {
      value: 'karma_fulfill_order',
      label: 'Karma Fulfill Order',
   },
   {
      value: 'karma_log_cash',
      label: 'Karma Log Cash',
   },
   {
      value: 'karma_match_customer',
      label: 'Karma Match Customer',
   },
   {
      value: 'karma_match_employee',
      label: 'Karma Match Employee',
   },
   {
      value: 'karma_match_product',
      label: 'Karma Match Product',
   },
   {
      value: 'karma_match_receipt',
      label: 'Karma Match Receipt',
   },
   {
      value: 'karma_paid_invoice',
      label: 'Karma Paid Invoice',
   },
   {
      value: 'karma_part_paid_invoice',
      label: 'Karma Part Paid Invoice',
   },
   {
      value: 'karma_request_tap_payment',
      label: 'Karma Request Tap Payment',
   },
   {
      value: 'karma_revenue_goal',
      label: 'Karma Revenue Goal',
   },
   {
      value: 'karma_approve_account',
      label: 'Karma Approve account',
   },
   {
      value: 'karma_create_budget_goal',
      label: 'Karma Create Budget Goal',
   },
   {
      value: 'karma_create_revenue_goal',
      label: 'Karma Create Revenue Goal',
   },
   {
      value: 'crs_karma_budget_goal',
      label: 'CRS Karma Budget Goal',
   },
   {
      value: 'crs_karma_cash_inflow',
      label: 'CRS Karma Cash Inflow',
   },
   {
      value: 'crs_karma_repayment_fail',
      label: 'CRS Karma Repayment Fail',
   },
   {
      value: 'crs_karma_repayment_success',
      label: 'CRS Karma Repayment Success',
   },
   {
      value: 'crs_karma_revenue_goal',
      label: 'CRS Karma Revenue Goal',
   },
   {
      value: 'crs_loan_offer_one',
      label: 'CRS Loan Offer One',
   },
   {
      value: 'crs_loan_offer_two',
      label: 'CRS Loan Offer Two',
   },
   {
      value: 'crs_loan_offer_three',
      label: 'CRS Loan Offer Three',
   },
   {
      value: 'crs_loan_offer_four',
      label: 'CRS Loan Offer Four',
   },
   {
      value: 'crs_loan_offer_five',
      label: 'CRS Loan Offer Five',
   },
   {
      value: 'crs_loan_offer_six',
      label: 'CRS Loan Offer Six',
   },
   {
      value: 'crs_loan_offer_seven',
      label: 'CRS Loan Offer Seven',
   },
   {
      value: 'crs_loan_offer_eight',
      label: 'CRS Loan Offer Eight',
   },
   {
      value: 'crs_loan_offer_nine',
      label: 'CRS Loan Offer Nine',
   },
   {
      value: 'crs_loan_offer_ten',
      label: 'CRS Loan Offer ten',
   },
   {
      value: 'crs_loan_offer_eleven',
      label: 'CRS Loan Offer Eleven',
   },
   {
      value: 'crs_loan_offer_twelve',
      label: 'CRS Loan Offer Twelve',
   },
   {
      value: 'crs_loan_offer_thirteen',
      label: 'CRS Loan Offer Thirteen',
   },
   {
      value: 'karma_refer_reward',
      label: 'Karma Refer Reward',
   },
   {
      value: 'karma_repayment_success',
      label: 'Karma Repayment Success',
   },
   {
      value: 'freelancer_account_charge',
      label: 'Freelancer Account Charge',
   },
   {
      value: 'pos_device_payment',
      label: 'POS Device Payment',
   },
   {
      value: 'pos_inflow_charge',
      label: 'POS Inflow Charge',
   },
   {
      value: 'external_transfer_charge',
      label: 'Extrernal Transfer Charge',
   },
   {
      value: 'payment_checkout_free',
      label: 'Payment Checkout Free',
   },
   {
      value: 'cash_promo_point',
      label: 'Cash Promo Point',
   },
   {
      value: 'payment_checkout',
      label: 'Payment Checkout',
   },
   {
      value: 'pre_approved_freelancer_limitation',
      label: 'Pre Approved Freelancer Limitation',
   },
   {
      value: 'pre_approved_business_limitation',
      label: 'Pre Approved Business Limitation',
   },
   {
      value: 'freelancer_spend_limit_per_day',
      label: 'Freelancer Spend Limit',
   },
   {
      value: 'psp_out_transfer',
      label: 'PSP Out Transfer',
   },
   {
      value: 'karma_cash_inflow',
      label: 'Karma Cash Inflow',
   },
   {
      value: 'crs_age_of_account',
      label: 'CRS Age of Account',
   },
   {
      value: 'crs_member_dob',
      label: 'CRS Member DOB',
   },
   {
      value: 'crs_average_balance',
      label: 'CRS Avg Balance',
   },
   {
      value: 'cash_refer_inflow_limit',
      label: 'Cash Refer Inflow Limit',
   },
   {
      value: 'free_transfer',
      label: 'Free Transfer',
   },
   {
      value: 'level_one',
      label: 'Level One',
   },
   {
      value: 'level_two',
      label: 'Level Two',
   },
   {
      value: 'level_three',
      label: 'Level Three',
   },
   {
      value: 'level_four',
      label: 'Level Four',
   },
   {
      value: 'level_five',
      label: 'Level Five',
   },
   {
      value: 'revolut_conversion',
      label: 'Revolut Conversion',
   },
   {
      value: 'fx_withdraw_conversion',
      label: 'Fx Withdraw Conversion',
   },
   {
      value: 'virtual_card_creation_charge',
      label: 'Virtual Card Creation Charge',
   },
   {
      value: 'revolut_bank_charge',
      label: 'Revolut Bank Charge',
   },
   {
      value: 'international_transfer_limit',
      label: 'International Transfer Limit',
   },
   {
      value: 'international_free_transfer',
      label: 'International Free Transfer',
   },
   {
      value: 'spektra_bank_charge',
      label: 'Spektra Bank Charge',
   },
   {
      value: 'upgrade_biz_plan',
      label: 'Upgrade Biz Plan',
   },
   {
      value: 'sub_account_limit',
      label: 'Sub Account Limit',
   },
   {
      value: 'business_member_add',
      label: 'Business Member Add',
   },
   {
      value: 'additional_business_member',
      label: 'Additional Business Member',
   },
   {
      value: 'account_limit',
      label: 'Account Limit',
   },
   {
      value: 'karma_repayment_fail',
      label: 'Karma Repayment Fail',
   },
   {
      value: 'extra_level',
      label: 'Extra Level',
   },
   {
      value: 'fct',
      label: 'FCT',
   },
   {
      value: 'rivers',
      label: 'Rivers',
   },
   {
      value: 'kano',
      label: 'Kano',
   },
];

export const ACCOUNT_TYPE = [
   {
      value: 'free',
      label: 'Free',
   },
   {
      value: 'standard',
      label: 'Standard',
   },
   {
      value: 'micro',
      label: 'Micro',
   },
   {
      value: 'free_one',
      label: 'Free One',
   },
   {
      value: 'free_two',
      label: 'Free Two',
   },
   {
      value: 'free_three',
      label: 'Free Three',
   },
   {
      value: 'specktra_one',
      label: 'Specktra One',
   },
   {
      value: 'specktra_two',
      label: 'Specktra Two',
   },
   {
      value: 'revolut_one',
      label: 'Revolut One',
   },
   {
      value: 'scale',
      label: 'Scale',
   },
   {
      value: 'cash_refer_inflow_limit',
      label: 'Cash Refer Inflow Limit',
   },
   {
      value: 'GBP',
      label: 'GBP',
   },
   {
      value: 'USD',
      label: 'USD',
   },
   {
      value: 'CAD',
      label: 'CAD',
   },
   {
      value: 'EUR',
      label: 'EUR',
   },
   {
      value: 'ZAR',
      label: 'ZAR',
   },
   {
      value: 'AUD',
      label: 'AUD',
   },
   {
      value: 'cash_refer_promo',
      label: 'Cash Refer Promo',
   },
   {
      value: 'karma',
      label: 'Karma',
   },
   {
      value: 'NGN',
      label: 'NGN',
   },
   {
      value: 'XOF',
      label: 'XOF',
   },
   {
      value: 'psp_out_transfer',
      label: 'PSP Out Transfer',
   },
   {
      value: 'crs_karma',
      label: 'CRS Karma',
   },
   {
      value: 'crs_loan_offer',
      label: 'CRS Loan Offer',
   },
   {
      value: 'registered_refer_level',
      label: 'Registered Refer Level',
   },
   {
      value: 'freelancer_refer_level',
      label: 'Freelancer Refer Level',
   },
   {
      value: 'registered_prosperity_bonus',
      label: 'Registered Prosperity Bonus',
   },
   {
      value: 'freelancer_prosperity_bonus',
      label: 'Freelancer Prosperity Bonus',
   },
   {
      value: 'crs_age_of_account',
      label: 'CRS Age of Account',
   },
   {
      value: 'crs_member_dob',
      label: 'CRS Member DOB',
   },
   {
      value: 'lagos',
      label: 'Lagos',
   },
   {
      value: 'crs_major_states',
      label: 'CRS Major States',
   },
   {
      value: 'crs_average_balance',
      label: 'CRS Average Balance',
   },
   {
      value: 'pos_device_payment',
      label: 'POS Device Payment',
   },
   {
      value: 'pos_inflow_charge',
      label: 'POS Inflow Charge',
   },
];
