import { useEffect, useState } from 'react';
import { func, objectOf, bool, string } from 'prop-types';
import { confirm } from 'components/confirm-box';
import Modal from 'components/modal';
import { Input } from 'components/input';
import { toast } from 'react-hot-toast';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { createPlanBenefit, updatePlanBenefit } from 'services/billing';

function AddBenefits({ isEdit = false, editDefault, fetchSinglePlan, onClose, open, planId }) {
   const [loading, setLoading] = useState(false);
   const [imageSelected, setImageSelected] = useState([]);
   const [newData, setNewData] = useState({});

   const { description, id } = editDefault;

   const detailsUnchanged = () => newData.description === description && imageSelected.length < 1;

   const isEmpty = !newData?.description;

   function onChange(val, data) {
      setNewData({
         ...newData,
         [val]: data.value,
      });
   }

   const addNewBenefit = async (e) => {
      e.preventDefault();
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: false,
         onConfirm: async () => {
            setLoading(true);
            const formData = new FormData();
            formData.append('plan', planId);
            formData.append('description', newData?.description);
            if (imageSelected) formData.append('image', imageSelected);
            try {
               const result = await createPlanBenefit(formData);
               if (result.ok) {
                  toast.success(result?.message || 'Created Successfully');
               }
            } catch (err) {
               toast.error(err.message || 'An error has occurred');
            }
            setLoading(false);
            fetchSinglePlan();
            onClose();
         },
      });
   };

   const editBenefit = async (e) => {
      e.preventDefault();
      confirm({
         confirmText: 'Are you sure you want to perform this action?',
         isLoading: false,
         onConfirm: async () => {
            setLoading(true);
            const formData = new FormData();
            formData.append('description', newData?.description);
            if (imageSelected) formData.append('image', imageSelected);
            try {
               const result = await updatePlanBenefit(formData, id);
               if (result.ok) {
                  toast.success(result?.message || 'Details Updated Successfully');
               }
            } catch (err) {
               toast.error(err.message || err.custom_message);
            }
            setLoading(false);
            fetchSinglePlan();
            onClose();
         },
      });
   };

   useEffect(() => {
      setNewData(editDefault);
   }, [editDefault]);

   return (
      <Modal open={open} onClose={onClose} title={isEdit ? 'Edit Tier' : 'Add New Tier'}>
         <form onSubmit={isEdit ? editBenefit : addNewBenefit}>
            <InputWrapper className="grid-2-2">
               <div>
                  <Input
                     label="Description"
                     placeholder="Enter Description"
                     description="description"
                     value={newData.description}
                     onChange={({ target }) => onChange('description', target)}
                  />
               </div>
               <div>
                  <h2>Upload Image</h2>
                  <div className="input-group mt-4 doc-file-input-group align-items-center">
                     <input
                        type="file"
                        accept="image\*"
                        className="form-control file-input"
                        aria-label="Upload"
                        onChange={(e) => setImageSelected(e.target.files[0])}
                     />
                  </div>
               </div>
            </InputWrapper>

            <div className="d-flex justify-content-end pt-5">
               {isEdit ? (
                  <Button
                     type="submit"
                     isLoading={loading}
                     disabled={detailsUnchanged() || loading}
                  >
                     Update Benefit
                  </Button>
               ) : (
                  <Button type="submit" isLoading={loading} disabled={loading || isEmpty}>
                     Create New Benefit
                  </Button>
               )}
            </div>
         </form>
      </Modal>
   );
}

AddBenefits.propTypes = {
   isEdit: bool.isRequired,
   onClose: func.isRequired,
   open: func.isRequired,
   editDefault: objectOf(string).isRequired,
   fetchSinglePlan: func.isRequired,
   planId: string.isRequired,
};

export default AddBenefits;
