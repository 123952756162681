import { useState, useEffect } from 'react';
import { func, number, objectOf, string } from 'prop-types';
import { Input } from 'components/input';
import { toast } from 'react-hot-toast';
import { Button } from 'components/button';
import { updatePersonalDetails } from 'services/businessDetails';
import { Status } from 'styles/utils';
import {
   InfoGroup,
   Label,
   OverviewSection,
   SectionTitle,
   Value,
   BoolStatus,
} from '../utils/overview.style';

function OwnerInfo({ bizDetails, businessAccountId, fetchBusinessDetails, statusValue }) {
   const [updatingOwner, setUpdatingOwner] = useState(false);
   const [ownerFirstName, setOwnerFirstName] = useState('');
   const [ownerLastName, setOwnerLastName] = useState('');

   const phone = bizDetails?.account_holder_details?.phone;
   const email = bizDetails?.account_holder_details?.email;
   const IDNumber = bizDetails?.account_holder_details?.identity_number;
   const IDType = bizDetails?.account_holder_details?.identity_type;

   useEffect(() => {
      setOwnerFirstName(bizDetails?.account_holder_details?.first_name);
      setOwnerLastName(bizDetails?.account_holder_details?.last_name);
   }, [bizDetails]);

   const hasEmptyOwnerField = () => !ownerFirstName || !ownerLastName;
   const ownerUnchanged = () =>
      ownerFirstName === bizDetails?.account_holder_details?.first_name &&
      ownerLastName === bizDetails?.account_holder_details?.last_name;

   const editOwnerDetails = async (e) => {
      e.preventDefault();
      setUpdatingOwner(true);

      const ownerData = {
         customer_id: bizDetails?.account_holder_details?.account_holder_id,
         rep_first_name: ownerFirstName,
         rep_last_name: ownerLastName,
      };

      try {
         const editOwnerResult = await updatePersonalDetails(ownerData, businessAccountId);

         if (editOwnerResult.ok) {
            fetchBusinessDetails();
            toast.success(editOwnerResult?.message, { duration: 3000 });
            //   setError('');
         }
         setUpdatingOwner(false);
      } catch (err) {
         //    setError(err.message || err.custom_message);
         toast.error(err.message || err.custom_message);
         setUpdatingOwner(false);
      }
   };
   return (
      <>
         <form onSubmit={editOwnerDetails}>
            <SectionTitle>Owner&apos;s Information</SectionTitle>

            <OverviewSection>
               <InfoGroup>
                  <Label>First Name</Label>
                  <Input
                     id="ownerFirstName"
                     placeholder="First Name"
                     className="overview-input"
                     value={ownerFirstName}
                     onChange={(e) => setOwnerFirstName(e.target?.value)}
                  />
               </InfoGroup>

               <InfoGroup>
                  <Label>Last Name</Label>
                  <Input
                     id="ownerLastName"
                     placeholder="Last Name"
                     className="overview-input"
                     value={ownerLastName}
                     onChange={(e) => setOwnerLastName(e.target?.value)}
                  />
               </InfoGroup>

               <InfoGroup>
                  <Label>Phone Number</Label>
                  <Value>{phone}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Email</Label>
                  <Value style={{ textTransform: 'initial' }}>{email || 'N/A'}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>ID Number</Label>
                  <Value>{IDNumber}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>ID Type</Label>
                  <Value>{IDType}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>BVN Verified</Label>
                  <BoolStatus isTrue={!!bizDetails?.account_holder_details?.bvn_number}>
                     {bizDetails?.account_holder_details?.bvn_number ? 'Verified' : 'Not Verified'}
                  </BoolStatus>
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Owner is a Director</Label>
                  <BoolStatus isTrue={bizDetails?.director_match_status === 'accepted'}>
                     {bizDetails?.director_match_status === 'accepted' ? 'Yes' : 'No'}
                  </BoolStatus>
               </InfoGroup>

               <InfoGroup>
                  <Label>Govt Issued ID Verified</Label>
                  <Status status={bizDetails?.account_holder_details?.identity_file_status}>
                     {statusValue(bizDetails?.account_holder_details?.identity_file_status)}
                  </Status>
               </InfoGroup>

               <InfoGroup>
                  <Label>Account Holder ID</Label>
                  <Value>{bizDetails?.account_holder_details?.account_holder_id}</Value>
               </InfoGroup>
            </OverviewSection>
            <div className="d-flex justify-content-end mt-2">
               <Button
                  type="submit"
                  isLoading={updatingOwner}
                  disabled={hasEmptyOwnerField() || ownerUnchanged() || updatingOwner}
               >
                  Update User Details
               </Button>
            </div>
         </form>
      </>
   );
}

OwnerInfo.propTypes = {
   bizDetails: objectOf(string).isRequired,
   businessAccountId: number.isRequired,
   fetchBusinessDetails: func.isRequired,
   statusValue: func.isRequired,
};

export default OwnerInfo;
