import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';
import { node, string } from 'prop-types';

const useStyles = makeStyles({
   tooltip: {
      fontSize: '1.4rem',
      fontFamily: "'Mark OT', sans-serif",
      position: 'relative',
      top: '-15px',
   },
});

function TooltipComponent({ title, children }) {
   const classes = useStyles();
   return (
      <Tooltip classes={classes} title={title}>
         <span
            style={{
               maxWidth: '100%',
               margin: '0 auto',
               overflowX: 'hidden',
               textOverflow: 'ellipsis',
            }}
         >
            {children}
         </span>
      </Tooltip>
   );
}

TooltipComponent.propTypes = {
   title: string.isRequired,
   children: node.isRequired,
};

export { TooltipComponent };
