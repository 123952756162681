import { Http } from 'services/http';
import { paramsObjectToQueryString } from 'utils/others';
import { logActivity } from 'services/activity';
import { getDocumentType } from 'utils/business';
import axiosCustom from 'utils/axios';

export const getBusinessDetails = (businessAccountId) => {
   if (businessAccountId) return Http(`/backmen/prospa_mybiz_details/${businessAccountId}/`);
};

export const getBusinessPcr = (businessAccountId) => {
   if (businessAccountId) return Http(`/backmen/admin_biz_crs_check/${businessAccountId}/`);
};

export const getUnregBusinessDetails = (businessAccountId) =>
   Http(`/backmen/just_unregistered_biz_details/${businessAccountId}/`);

export const getAdminLogs = (payload, page) =>
   Http(`/backmen/psp_activity_manager_log/${paramsObjectToQueryString(page)}`, {
      body: payload,
      method: 'POST',
   });

export const getNotes = (businessAccountId) =>
   Http(`/backmen/prospa_mybiz_note/${businessAccountId}`);

export const addNote = async (payload) => {
   try {
      const response = await Http('/backmen/submit_message_biz/', {
         body: payload,
         method: 'POST',
      });
      let msg = '';
      if (payload.drop_message.length > 20) {
         msg = `${payload.drop_message.substring(0, 20)}...`;
      } else {
         msg = payload.drop_message;
      }
      await logActivity(`New note added - ${msg}`, 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const updateBizDetails = async (payload) => {
   try {
      const response = await Http('/backmen/upper_self_biz_update/', {
         body: payload,
         method: 'POST',
      });
      await logActivity(`Updated business details`, 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const updatePersonalDetails = async (payload, biz_account_id) => {
   try {
      const response = await Http('/backmen/upper_self_personal_update/', {
         body: payload,
         method: 'POST',
      });
      await logActivity(`Updated business owner details`, 'reg', biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const getBusinessAccountTransactions = (page, biz_account_id) =>
   Http(`/backmen/by_biz_transactions/${biz_account_id}/${paramsObjectToQueryString(page)}`);
export const updatePersonalDocs = (payload, business_id) => {
   logActivity(`Updated Indentity File`, 'reg', business_id);
   return axiosCustom().post('/backmen/upper_self_personal_update/', payload);
};
export const uploadBusinessDocs = async (payload, type) => {
   try {
      const response = await axiosCustom().post('/backmen/add_extra_kyc_doc/', payload);
      await logActivity(
         `Uploaded ${type} document for business`,
         'reg',
         payload.get('biz_account_id'),
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const sendEmail = async (payload, business_id, type) => {
   try {
      const response = await Http('/backmen/direct_send_customer_mail/', {
         body: payload,
         method: 'POST',
      });
      await logActivity(`Sent email to business`, type || 'reg', business_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const sendSMS = async (payload, business_id, type) => {
   try {
      const response = await Http('/backmen/admin_send_sms/', { body: payload, method: 'POST' });
      await logActivity(`Sent SMS to business`, type || 'reg', business_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const verifyDocument = async (payload, type, action) => {
   try {
      const response = await Http('/backmen/biz_kyc_verify/', { body: payload, method: 'POST' });
      await logActivity(
         `${action.charAt(0).toUpperCase() + action.slice(1)} Document - ${getDocumentType(type)}`,
         'reg',
         payload.biz_account_id,
      );
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const uploadDocument = (payload) =>
   Http('/backmen/add_extra_kyc_doc/', { body: payload, method: 'POST' });

export const triggerTinMail = async (payload) => {
   try {
      const response = await Http('/backmen/trigger_tin_mail/', { body: payload, method: 'POST' });
      await logActivity(`Sent TIN for processing.`, 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};

export const approveBusiness = async (payload) => {
   try {
      const response = await Http('/backmen/prospa_approve_biz/', {
         body: payload,
         method: 'POST',
      });
      await logActivity(`Approved business`, 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const preapproveBusiness = async (payload) => {
   try {
      const response = await Http('/backmen/pre_approve_direct_biz/', {
         body: payload,
         method: 'POST',
      });
      await logActivity(`Preapproved business`, 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
export const verifySecurityQuestion = async (payload) => {
   try {
      const response = await Http('/backmen/moment_of_truth/', {
         body: payload,
         method: 'POST',
      });
      // await logActivity(`Preapproved business`, 'reg', payload.biz_account_id);
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
