import styled from 'styled-components';

export const InputWrapper = styled.div`
   margin: 20px 0;
   // width: 100%;

   &.grid-2-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 30px;
   }
   &.grid-3-3 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 30px;
   }
`;

export const FormContainer = styled.div`
   width: 400px;
   margin: 0 auto;
`;
