/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button } from 'components/button';
import Modal from 'components/modal';
import { Skeleton } from '@material-ui/lab';
import { MoreHoriz } from '@material-ui/icons';
import { TableContainer, TableContent } from 'styles/table';
import { MenuComponent } from 'components/menu';
import { confirm } from 'components/confirm-box';
import { IconButton } from '@material-ui/core';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import AddBenefits from 'containers/billing/add-benefits';
import { getSingleBillingPlan, deletePlanBenefit } from 'services/billing';
import { BillingSubBenefits } from '../sub-benefits';

export const BillingBenefits = ({ open, onClose, noCloseIcon, planId }) => {
   const [benefits, setBenefits] = useState([]);
   const [, setError] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [modalVisibilityDetails, setModalVisibilityDetails] = useState(false);
   const [newBillingBenefitModal, setNewBillingBenefitModal] = useState(false);
   const [editBillingBenefitModal, setEditBillingBenefitModal] = useState(false);
   const [editDefault, setEditDefault] = useState({});
   const [benefitId, setBenefitId] = useState('');

   const fetchSinglePlan = async () => {
      try {
         const result = await getSingleBillingPlan(planId);
         setBenefits(result.benefits);
         setError(result.message);
         setIsLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setIsLoading(false);
      }
   };

   useEffect(() => {
      fetchSinglePlan();
   }, [planId]);

   const deleteHandler = async () => {
      confirm({
         confirmText: 'Are you sure want to delete it?',
         isLoading: false,
         onConfirm: async () => {
            try {
               const result = await deletePlanBenefit(benefitId);
               setError(result.message);
               setIsLoading(false);
            } catch (err) {
               setError(err.message || err.custom_message);
               setIsLoading(false);
            }
            fetchSinglePlan();
         },
      });
   };

   const options = ['Edit', 'Delete'];

   const [anchorEl, setAnchorEl] = useState(null);
   const menuOpen = Boolean(anchorEl);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   function handleOpenDetailsModal() {
      setModalVisibilityDetails(true);
   }

   const handleOpenModal = () => {
      setEditBillingBenefitModal(true);
   };

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={menuOpen}
                  options={options}
                  optionsFns={[() => handleOpenModal(row.id), () => deleteHandler(row.id)]}
               />
            </div>
         ),
      },
      {
         field: 'name',
         width: 300,
         headerName: 'Title',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.description}>
               <span
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                  onClick={() => handleOpenDetailsModal(row?.id)}
               >
                  {row?.description}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'image',
         headerName: 'Image',
         renderCell: ({ row }) => <a href={row?.image}> {row?.image ? 'Download Image' : 'N/A'}</a>,
      },
   ];

   function onRowClick({ row }) {
      setBenefitId(row.id);
      setEditDefault(row);
   }

   return (
      <Modal
         open={open}
         onClose={onClose}
         closeOnOverlayClick={false}
         noCloseIcon={noCloseIcon}
         title="Billing Plan Details"
         className="biz-details-modal"
      >
         <TableContainer className="activity">
            <TableContent>
               <div className="d-flex justify-content-between w-100 align-items-center">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1>Benefits</h1>
                        <span className="value">{benefits?.length || 'N/A'}</span>
                     </div>
                  )}
               </div>

               <div style={{ marginLeft: '10px' }}>
                  <Button onClick={() => setNewBillingBenefitModal(true)}>
                     Create New Benefit
                  </Button>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={benefits}
               isLoading={isLoading}
               onRowClick={onRowClick}
               pageSize={benefits.length}
            />

            {modalVisibilityDetails && (
               <BillingSubBenefits
                  open={modalVisibilityDetails}
                  tableData={benefits[planId]}
                  onClose={() => setModalVisibilityDetails(false)}
                  benefitId={benefitId}
               />
            )}

            {newBillingBenefitModal && (
               <AddBenefits
                  open={newBillingBenefitModal}
                  editDefault={{}}
                  benefitId={benefitId}
                  planId={planId}
                  fetchSinglePlan={fetchSinglePlan}
                  onClose={() => setNewBillingBenefitModal(false)}
               />
            )}
            {editBillingBenefitModal && (
               <AddBenefits
                  isEdit
                  benefitId={benefitId}
                  open={editBillingBenefitModal}
                  editDefault={editDefault}
                  planId={planId}
                  fetchSinglePlan={fetchSinglePlan}
                  onClose={() => setEditBillingBenefitModal(false)}
               />
            )}
         </TableContainer>
      </Modal>
   );
};
