import { css } from 'styled-components';

export const ModalStylesCSS = css`
   .customModal {
      padding: 2.5rem;
      min-height: 30%;
      position: relative;
      border-radius: 4px;

      @media (min-width: 861px) {
         width: 700px;
      }

      @media (min-width: 660px) and (max-width: 860px) {
         width: 600px;
      }

      @media (min-width: 527px) and (max-width: 659px) {
         width: 500px;
      }

      @media (max-width: 526px) {
         width: 360px;
      }

      @media (max-width: 380px) {
         width: 90%;
      }

      .modal-title {
         font-size: 18px;
         font-weight: 600;
         margin-bottom: 1rem;
      }

      .modal-close-icon {
         position: absolute;
         right: 2rem;
         top: 2rem;
         cursor: pointer;
      }
   }

   .customOverlay {
      background: rgba(13, 15, 17, 0.4);
   }

   .modal-children {
      width: 100%;
      overflow-x: auto;
      overflow-y: auto;
   }
`;
