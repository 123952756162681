/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'components/button';
import { Skeleton } from '@material-ui/lab';
import { MoreHoriz } from '@material-ui/icons';
import { TableContainer, TableContent } from 'styles/table';
import { MenuComponent } from 'components/menu';
import { confirm } from 'components/confirm-box';
import { IconButton } from '@material-ui/core';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import AddBillingPlans from 'containers/billing/add-new-plan';
import { getBillingPlans, deleteBillingPlan } from 'services/billing';
import { getSettings } from 'services/settings';
import { numberWithCommas } from 'utils/others';
import { BillingBenefits } from './features/benefits';

const BILLING__PLAN_CONFIGS = 'BILLING__PLAN_CONFIGS';

export const BillingPlans = () => {
   const { search } = useLocation();
   const page = new URLSearchParams(search).get('page');
   const [currentPage, setCurrentPage] = useState(page || 1);
   const [tableData, setTableData] = useState([]);
   const [billingConfigId, setBillingConfigId] = useState('');
   const [, setError] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [modalVisibilityDetails, setModalVisibilityDetails] = useState(false);
   const [manageBillingPlanModal, setManageBillingPlanModal] = useState(false);
   const [paginationData, setPaginationData] = useState({});
   const [editDefault, setEditDefault] = useState({});
   const [planId, setPlanId] = useState('');

   const fetchBillingPlans = async () => {
      try {
         setIsLoading(true);

         const [planRes, settingRes] = await Promise.all([
            getBillingPlans({ page: currentPage }),
            getSettings(),
         ]);

         const settingArray = Object.values(settingRes);

         const billingConfig = settingArray.find((item) => item.name === BILLING__PLAN_CONFIGS);

         const plans = planRes.results.map((plan) => ({
            ...plan,
            setting: billingConfig.value[plan.name],
         }));

         setBillingConfigId(billingConfig.id);
         setTableData(plans);
         setPaginationData(planRes);
      } catch (err) {
         setError(err.message || err.custom_message);
      } finally {
         setIsLoading(false);
      }
   };

   const deleteHandler = async () => {
      confirm({
         confirmText: 'Are you sure want to delete it?',
         isLoading: false,
         onConfirm: async () => {
            try {
               const result = await deleteBillingPlan(planId);
               setError(result.message);
               setIsLoading(false);
            } catch (err) {
               setError(err.message || err.custom_message);
               setIsLoading(false);
            }
            fetchBillingPlans();
         },
      });
   };

   useEffect(() => {
      fetchBillingPlans();
   }, [currentPage]);

   const options = ['Edit', 'Delete'];

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleOpenModal = () => {
      setManageBillingPlanModal('EDIT');
   };

   function handleOpenDetailsModal() {
      setModalVisibilityDetails(true);
   }

   const renderPrice = (row) => {
      if (row.name === 'BILLING__BILL_PAY_AS_YOU_GO') {
         return '';
      }

      return (
         <TooltipComponent title={row?.price}>
            <TooltipComponent title={row?.price}>₦{row?.price || 'N/A'}</TooltipComponent>
         </TooltipComponent>
      );
   };

   const plan = (name) => {
      switch (name) {
         case 'BILLING__BILL_PAY_AS_YOU_GO':
            return `Pay As You Go`;
         case 'BILLING__BILL_MEMBERS___GROWTH':
            return `Growth`;
         case 'BILLING__BILL_MEMBERS___SCALE':
            return `Scale`;
         default:
            return name;
      }
   };

   const getAmount = (value) => {
      if (value === 0) return `₦${value}`;
      if (value) return `₦${numberWithCommas(value)}`;
      return '';
   };

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={open}
                  options={options}
                  optionsFns={[() => handleOpenModal(row.id), () => deleteHandler(row.id)]}
               />
            </div>
         ),
      },
      {
         field: 'name',
         headerName: 'Plan Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={plan(row?.name)}>
               <span
                  style={{ cursor: 'pointer' }}
                  className="text-primary"
                  onClick={() => handleOpenDetailsModal(row?.id)}
               >
                  {plan(row?.name) || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'price',
         headerName: 'Amount',
         renderCell: ({ row }) => renderPrice(row),
      },
      {
         field: 'above_to_charge',
         headerName: 'Free Inflow Treshold',
         renderCell: ({ row }) => {
            const value = getAmount(
               row?.setting?.excess_transfer_inflow_volume_charge?.above_to_charge,
            );
            return <TooltipComponent title={value}>{value || 'N/A'}</TooltipComponent>;
         },
      },
      {
         field: 'rate',
         headerName: 'Rate',
         renderCell: ({ row }) => {
            const value = getAmount(row?.setting?.excess_transfer_inflow_volume_charge?.rate);
            return <TooltipComponent title={value}>{value || 'N/A'}</TooltipComponent>;
         },
      },
      {
         field: 'chunk_size',
         headerName: 'Inflow Charge',
         renderCell: ({ row }) => {
            const value = getAmount(row?.setting?.excess_transfer_inflow_volume_charge?.chunk_size);
            return <TooltipComponent title={value}>{value || 'N/A'}</TooltipComponent>;
         },
      },
      {
         field: 'local_transfer_outflow_count',
         headerName: 'Free Local Transfers',
         renderCell: ({ row }) => {
            const value = `${numberWithCommas(row?.setting?.local_transfer_outflow_count)}`;
            return <TooltipComponent title={value}>{value || 'N/A'}</TooltipComponent>;
         },
      },
   ];

   function onRowClick({ row }) {
      setPlanId(row.id);
      setEditDefault(row);
   }

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <TableContainer className="activity">
         <TableContent>
            <div className="d-flex justify-content-between w-100 align-items-center">
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1>Billing Plans</h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
            </div>

            <div style={{ marginLeft: '10px' }}>
               <Button onClick={() => setManageBillingPlanModal('ADD')}>Create New Plan</Button>
            </div>
         </TableContent>
         <TableComponent
            columns={columns}
            rows={tableData}
            isLoading={isLoading}
            onPageChange={onPageChange}
            count={Math.ceil(paginationData.count / 15)}
            onRowClick={onRowClick}
         />
         {modalVisibilityDetails && (
            <BillingBenefits
               open={modalVisibilityDetails}
               tableData={tableData[planId]}
               setCurrentPage={setCurrentPage}
               onClose={() => setModalVisibilityDetails(false)}
               planId={planId}
               currentPage={currentPage}
            />
         )}
         {manageBillingPlanModal && (
            <AddBillingPlans
               isEdit={manageBillingPlanModal === 'EDIT'}
               editDefault={manageBillingPlanModal === 'EDIT' ? editDefault : {}}
               open={manageBillingPlanModal}
               fetchBillingPlans={fetchBillingPlans}
               onClose={() => setManageBillingPlanModal(false)}
               billingConfigId={billingConfigId}
            />
         )}
      </TableContainer>
   );
};
