import { useState } from 'react';
import { func, number, objectOf, string } from 'prop-types';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { Status } from 'styles/utils';
import { cancelActivePlan } from 'services/business';
import { Button } from 'components/button';
import { confirm } from 'components/confirm-box';
import { numberWithCommas } from 'utils/others';
import { getPCRLabel } from 'utils/loans';
import { InfoGroup, Label, OverviewSection, SectionTitle, Value } from '../utils/overview.style';

function BusinessInfo({ bizDetails, bizPcr, fetchBusinessDetails, businessAccountId }) {
   const [cancelingPlan, setCancelingPlan] = useState(false);
   const dateCreated = moment(bizDetails?.pub_date).format('MMM DD, YYYY');
   const timeCreated = moment(bizDetails?.pub_date).format('h:mm a');

   const cancelPlan = async () => {
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: async () => {
            setCancelingPlan(true);
            try {
               const res = await cancelActivePlan({
                  biz_account_id: businessAccountId,
               });
               toast.success(res.message);
               fetchBusinessDetails();
            } catch (e) {
               toast.error(e.message || e);
            }
            setCancelingPlan(false);
         },
      });
   };
   return (
      <>
         <div>
            <SectionTitle>Business Details</SectionTitle>
            <OverviewSection>
               <InfoGroup>
                  <Label>Business Name</Label>
                  <Value>{bizDetails?.biz_name}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Type</Label>
                  <Value>{bizDetails?.biz_type}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Turnover</Label>
                  <Value>{bizDetails?.biz_turnover?.replace(/_/g, ' ') || 'N/A'}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Prospa Credit Score</Label>
                  <Value>
                     {getPCRLabel(bizPcr)} | {bizPcr || 'N/A'}
                  </Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Deposit Value</Label>
                  <Value>₦ {numberWithCommas(bizDetails?.deposit_value)}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Description</Label>
                  <Value>{bizDetails?.biz_description}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Plan </Label>
                  <Value>
                     <Status status="pending" className="modal-plantype-text">
                        <strong>{bizDetails?.biz_account_plan?.plan_type} plan</strong>{' '}
                        <span>{`- ₦ ${bizDetails?.biz_account_plan?.plan_amount}/Month`}</span>
                     </Status>
                     <div className="mt-3 d-flex">
                        <Status
                           className="py-1 me-2 ms-0"
                           status={
                              bizDetails?.biz_account_plan?.plan_state === 'active'
                                 ? 'accepted'
                                 : 'rejected'
                           }
                        >
                           {bizDetails?.biz_account_plan?.plan_state === 'active'
                              ? 'active'
                              : 'inactive'}
                        </Status>
                        {bizDetails?.biz_account_plan?.plan_state === 'active' &&
                           bizDetails?.biz_account_plan?.plan_type !== 'free' && (
                              <Button
                                 style={{ minHeight: '25px', padding: '7px', fontSize: '12px' }}
                                 onClick={cancelPlan}
                                 isLoading={cancelingPlan}
                                 disabled={cancelingPlan}
                              >
                                 {' '}
                                 Cancel Plan
                              </Button>
                           )}
                     </div>
                  </Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Total Products</Label>
                  <Value>{bizDetails?.total_products}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Total Customers</Label>
                  <Value>{bizDetails?.total_customers}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Total Employees</Label>
                  <Value>{bizDetails?.total_employees}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Total Invoices</Label>
                  <Value>{bizDetails?.total_invoices}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Total Suppliers</Label>
                  <Value>{bizDetails?.total_suppliers}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Total Virtual Cards</Label>
                  <Value>{bizDetails?.total_virtual_cards}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Webstore URL</Label>
                  <Value>
                     {bizDetails?.webstore_details?.has_webstore ? (
                        <a
                           target="_blank"
                           rel="noreferrer"
                           className="number"
                           href={`${
                              process.env.REACT_APP_WEBSTORE_BASEURL ||
                              'https://getprospa.com/webstore/'
                           }${bizDetails?.webstore_details?.webstore_url}`}
                        >{`${
                           process.env.REACT_APP_WEBSTORE_BASEURL ||
                           'https://getprospa.com/webstore/'
                        }${bizDetails?.webstore_details?.webstore_url}`}</a>
                     ) : (
                        <Status status="rejected" className="ms-0">
                           No webstore
                        </Status>
                     )}
                  </Value>
               </InfoGroup>

               {/* <InfoGroup>
                  <Label>Manager</Label>
                  <Value>
                     {bizDetails?.assigned_manager_details?.first_name}{' '}
                     {bizDetails?.assigned_manager_details?.last_name}
                  </Value>
               </InfoGroup> */}

               {/* <InfoGroup>
                  <Label>Build Partner</Label>
                  <Value>
                     {bizDetails?.build_manager_details
                        ? `${bizDetails?.build_manager_details.first_name} ${bizDetails?.build_manager_details.last_name}`
                        : 'N/A'}
                  </Value>
               </InfoGroup> */}

               <InfoGroup>
                  <Label>Date Created</Label>
                  <Value style={{ textTransform: 'initial' }}>
                     {dateCreated} | {timeCreated}
                  </Value>
               </InfoGroup>
            </OverviewSection>
         </div>
      </>
   );
}

BusinessInfo.propTypes = {
   bizDetails: objectOf(string).isRequired,
   bizPcr: string.isRequired,
   fetchBusinessDetails: func.isRequired,
   businessAccountId: number.isRequired,
};

export default BusinessInfo;
