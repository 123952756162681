/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button } from 'components/button';
import Modal from 'components/modal';
import { Skeleton } from '@material-ui/lab';
import { MoreHoriz } from '@material-ui/icons';
import { TableContainer, TableContent } from 'styles/table';
import { MenuComponent } from 'components/menu';
import { confirm } from 'components/confirm-box';
import { IconButton } from '@material-ui/core';
import { TooltipComponent } from 'components/tooltip';
import { TableComponent } from 'components/table';
import AddSubBenefits from 'containers/billing/add-sub-benefits';
import { getSinglePlanBenefit, deleteSubBenefit } from 'services/billing';

export const BillingSubBenefits = ({ open, onClose, noCloseIcon, benefitId }) => {
   const [benefits, setBenefits] = useState([]);
   const [, setError] = useState('');
   const [isLoading, setIsLoading] = useState(false);
   const [newSubBenefitModal, setNewSubBenefitModal] = useState(false);
   const [editSubBenefitModal, setEditSubBenefitModal] = useState(false);
   const [editDefault, setEditDefault] = useState({});
   const [subBenefitId, setSubBenefitId] = useState('');

   const fetchSingleBenefit = async () => {
      try {
         const result = await getSinglePlanBenefit(benefitId);
         setBenefits(result.sub_benefit);
         setError(result.message);
         setIsLoading(false);
      } catch (err) {
         setError(err.message || err.custom_message);
         setIsLoading(false);
      }
   };

   useEffect(() => {
      fetchSingleBenefit();
   }, [benefitId]);

   const deleteHandler = async () => {
      confirm({
         confirmText: 'Are you sure want to delete it?',
         isLoading: false,
         onConfirm: async () => {
            try {
               const result = await deleteSubBenefit(subBenefitId);
               setError(result.message);
               setIsLoading(false);
            } catch (err) {
               setError(err.message || err.custom_message);
               setIsLoading(false);
            }
            fetchSingleBenefit();
         },
      });
   };

   const options = ['Edit', 'Delete'];

   const [anchorEl, setAnchorEl] = useState(null);
   const menuOpen = Boolean(anchorEl);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleOpenModal = () => {
      setEditSubBenefitModal(true);
   };

   const columns = [
      {
         field: '',
         width: 0,
         renderCell: ({ row }) => (
            <div>
               <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
               >
                  <MoreHoriz />
               </IconButton>
               <MenuComponent
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  open={menuOpen}
                  options={options}
                  optionsFns={[() => handleOpenModal(row.id), () => deleteHandler(row.id)]}
               />
            </div>
         ),
      },
      {
         field: 'description',
         width: 400,
         headerName: 'Description',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.description}>{row?.description}</TooltipComponent>
         ),
      },
      {
         field: 'image',
         headerName: 'Image',
         renderCell: ({ row }) => <a href={row?.image}> {row?.image ? 'Download Image' : 'N/A'}</a>,
      },
   ];

   function onRowClick({ row }) {
      setSubBenefitId(row.id);
      setEditDefault(row);
   }

   return (
      <Modal
         open={open}
         onClose={onClose}
         closeOnOverlayClick={false}
         noCloseIcon={noCloseIcon}
         title="Billing Plan Details"
         className="biz-details-modal"
      >
         <TableContainer className="activity">
            <TableContent>
               <div className="d-flex justify-content-between w-100 align-items-center">
                  {isLoading ? (
                     <Skeleton width="30%" height="20" />
                  ) : (
                     <div>
                        <h1>Sub Benefits</h1>
                        <span className="value">{benefits?.length || 'N/A'}</span>
                     </div>
                  )}
               </div>

               <div style={{ marginLeft: '10px' }}>
                  <Button onClick={() => setNewSubBenefitModal(true)}>
                     Create New Sub Benefit
                  </Button>
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={benefits}
               isLoading={isLoading}
               onRowClick={onRowClick}
               pageSize={benefits.length}
            />

            {newSubBenefitModal && (
               <AddSubBenefits
                  open={newSubBenefitModal}
                  editDefault={{}}
                  subBenefitId={subBenefitId}
                  benefitId={benefitId}
                  fetchSingleBenefit={fetchSingleBenefit}
                  onClose={() => setNewSubBenefitModal(false)}
               />
            )}
            {editSubBenefitModal && (
               <AddSubBenefits
                  isEdit
                  subBenefitId={subBenefitId}
                  open={editSubBenefitModal}
                  editDefault={editDefault}
                  benefitId={benefitId}
                  fetchSingleBenefit={fetchSingleBenefit}
                  onClose={() => setEditSubBenefitModal(false)}
               />
            )}
         </TableContainer>
      </Modal>
   );
};
