import { useState, useEffect } from 'react';
import { func, number, objectOf, string } from 'prop-types';
import { Input } from 'components/input';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import { confirm } from 'components/confirm-box';
import { Button } from 'components/button';
import { updateBizDetails } from 'services/businessDetails';
import { cancelActivePlan } from 'services/business';
import { getPCRLabel } from 'utils/loans';
import { Status } from 'styles/utils';
import {
   InfoGroup,
   Label,
   OverviewSection,
   SectionTitle,
   Value,
   BoolStatus,
} from '../utils/overview.style';

function BusinessInfo({
   bizDetails,
   businessAccountId,
   fetchBusinessDetails,
   statusValue,
   bizPcr,
}) {
   const [cancelingPlan, setCancelingPlan] = useState(false);
   const [updatingBiz, setUpdatingBiz] = useState(false);
   const [bizName, setBizName] = useState('');
   const [tinNumber, setTinNumber] = useState('');
   const [bizAddress, setBizAddress] = useState('');
   const [bizCity, setBizCity] = useState('');
   const [bizState, setBizState] = useState('');
   const [rcNumber, setRcNumber] = useState('');
   const dateCreated = moment(bizDetails?.pub_date).format('MMM DD, YYYY');
   const timeCreated = moment(bizDetails?.pub_date).format('h:mm a');

   // const hasEmptyBizField = () =>
   //    !bizName || !tinNumber || !bizAddress || !bizCity || !bizState || !rcNumber;

   const hasEmptyBizField = () => !bizName || !bizAddress || !bizCity || !bizState;

   const bizUnchanged = () =>
      bizName === bizDetails?.biz_name &&
      tinNumber === bizDetails?.tin_number &&
      bizAddress === bizDetails?.biz_address &&
      bizCity === bizDetails?.biz_city &&
      bizState === bizDetails?.biz_state &&
      rcNumber === bizDetails?.rc_number;

   useEffect(() => {
      setBizName(bizDetails?.biz_name);
      setTinNumber(bizDetails?.tin_number);
      setBizAddress(bizDetails?.biz_address);
      setBizCity(bizDetails?.biz_city);
      setBizState(bizDetails?.biz_state);
      setRcNumber(bizDetails?.rc_number);
   }, [bizDetails]);

   const editBizDetails = async (e) => {
      e.preventDefault();
      setUpdatingBiz(true);

      const bizData = {
         biz_account_id: businessAccountId,
         ...(bizName !== bizDetails?.biz_name && { biz_name: bizName }),
         ...(tinNumber !== bizDetails?.tin_number && { biz_tin_number: tinNumber }),
         ...(bizAddress !== bizDetails?.biz_address && { biz_address: bizAddress }),
         ...(bizCity !== bizDetails?.biz_city && { biz_city: bizCity }),
         ...(bizState !== bizDetails?.biz_state && { biz_state: bizState }),
         ...(rcNumber !== bizDetails?.rc_number && { biz_rc_number: rcNumber }),
         //  tin_number: tinNumber,
         //  biz_address: bizAddress,
         //  biz_city: bizCity,
         //  biz_state: bizState,
         //  rc_number: rcNumber,
      };

      try {
         const editBizResult = await updateBizDetails(bizData);

         if (editBizResult.ok) {
            fetchBusinessDetails();
            toast.success(editBizResult?.message, { duration: 3000 });
            //   setError('');
         }
         setUpdatingBiz(false);
      } catch (err) {
         //    setError(err.message || err.custom_message);
         toast.error(err.message || err.custom_message);
         setUpdatingBiz(false);
      }
   };

   const cancelPlan = async () => {
      confirm({
         confirmText: 'Are you sure you want to continue ?',
         onConfirm: async () => {
            setCancelingPlan(true);
            try {
               const res = await cancelActivePlan({
                  biz_account_id: businessAccountId,
               });
               toast.success(res.message);
               fetchBusinessDetails();
            } catch (e) {
               toast.error(e.message || e);
            }
            setCancelingPlan(false);
         },
      });
   };
   return (
      <>
         <form onSubmit={editBizDetails}>
            <SectionTitle>Business Details</SectionTitle>
            <OverviewSection>
               <InfoGroup>
                  <Label>Business Name</Label>
                  <Input
                     className="overview-input"
                     id="bizName"
                     value={bizName}
                     onChange={(e) => setBizName(e.target?.value)}
                  />
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Type</Label>
                  <Value>{bizDetails?.biz_type}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Description</Label>
                  <Value>{bizDetails?.biz_description}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Prospa Credit Score</Label>
                  <Value>
                     {getPCRLabel(bizPcr)} | {bizPcr || 'N/A'}
                  </Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>CAC Certificate Verified</Label>
                  <Status status={bizDetails?.cac_cert_photo_status}>
                     {statusValue(bizDetails?.cac_cert_photo_status)}
                  </Status>
               </InfoGroup>

               <InfoGroup>
                  <Label>Proof of Address</Label>
                  <Status status={bizDetails?.proof_of_address_status}>
                     {statusValue(bizDetails?.proof_of_address_status)}
                  </Status>
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Selected From CAC</Label>
                  <BoolStatus isTrue={bizDetails?.cac_biz_tag !== 0}>
                     {bizDetails?.cac_biz_tag === 0 ? 'No' : 'Yes'}
                  </BoolStatus>
               </InfoGroup>

               <InfoGroup>
                  <Label>Plan </Label>
                  <Value>
                     <Status status="pending" className="modal-plantype-text">
                        <strong>{bizDetails?.biz_account_plan?.plan_type} plan</strong>{' '}
                        <span>{`- ₦ ${bizDetails?.biz_account_plan?.plan_amount}/Month`}</span>
                     </Status>
                     <div className="mt-3 d-flex">
                        <Status
                           className="py-1 me-2 ms-0"
                           status={
                              bizDetails?.biz_account_plan?.plan_state === 'active'
                                 ? 'accepted'
                                 : 'rejected'
                           }
                        >
                           {bizDetails?.biz_account_plan?.plan_state === 'active'
                              ? 'active'
                              : 'inactive'}
                        </Status>
                        {bizDetails?.biz_account_plan?.plan_state === 'active' &&
                           bizDetails?.biz_account_plan?.plan_type !== 'free' && (
                              <Button
                                 style={{ minHeight: '25px', padding: '7px', fontSize: '12px' }}
                                 onClick={cancelPlan}
                                 isLoading={cancelingPlan}
                                 disabled={cancelingPlan}
                              >
                                 {' '}
                                 Cancel Plan
                              </Button>
                           )}
                     </div>
                  </Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Tax Identification Number</Label>
                  <Input
                     id="tinNumber"
                     placeholder="TIN"
                     className="overview-input"
                     value={tinNumber}
                     onChange={(e) => setTinNumber(e.target?.value)}
                  />
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Address</Label>
                  <Input
                     id="bizAddress"
                     placeholder="Address"
                     className="overview-input"
                     value={bizAddress}
                     onChange={(e) => setBizAddress(e.target?.value)}
                  />
                  <Input
                     id="bizCity"
                     placeholder="City"
                     className="overview-input"
                     value={bizCity}
                     onChange={(e) => setBizCity(e.target?.value)}
                  />
                  <Input
                     id="bizState"
                     placeholder="State"
                     className="overview-input"
                     value={bizState}
                     onChange={(e) => setBizState(e.target?.value)}
                  />
               </InfoGroup>

               <InfoGroup>
                  <Label>Registered Business Address</Label>
                  <Value>{bizDetails?.reg_biz_address || 'N/A'}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Registration Number</Label>
                  <Input
                     id="rcNumber"
                     placeholder="Reg. Number"
                     className="overview-input"
                     value={rcNumber}
                     onChange={(e) => setRcNumber(e.target?.value)}
                  />
               </InfoGroup>

               <InfoGroup>
                  <Label>Turnover</Label>
                  <Value>
                     {bizDetails?.biz_turnover
                        ? bizDetails?.biz_turnover?.replace(/_/g, ' ')
                        : 'N/A'}
                  </Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Manager</Label>
                  <Value>
                     {bizDetails?.assigned_manager_details
                        ? `${bizDetails?.assigned_manager_details?.first_name} ${bizDetails?.assigned_manager_details?.last_name}`
                        : 'N/A'}
                  </Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Build Partner</Label>
                  <Value>
                     {bizDetails?.build_manager_details
                        ? `${bizDetails?.build_manager_details.first_name} ${bizDetails?.build_manager_details.last_name}`
                        : 'N/A'}
                  </Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Business Account ID</Label>
                  <Value>{bizDetails.biz_account_id}</Value>
               </InfoGroup>

               <InfoGroup>
                  <Label>Date Created</Label>
                  <Value style={{ textTransform: 'initial' }}>
                     {dateCreated} | {timeCreated}
                  </Value>
               </InfoGroup>
            </OverviewSection>

            <div className="d-flex justify-content-end mt-2">
               <Button
                  type="submit"
                  disabled={hasEmptyBizField() || bizUnchanged() || updatingBiz}
                  isLoading={updatingBiz}
               >
                  Update Business Details
               </Button>
            </div>
         </form>
      </>
   );
}

BusinessInfo.propTypes = {
   bizDetails: objectOf(string).isRequired,
   businessAccountId: number.isRequired,
   fetchBusinessDetails: func.isRequired,
   statusValue: func.isRequired,
   bizPcr: string.isRequired,
};

export default BusinessInfo;
