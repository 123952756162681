/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/prop-types */
import { useEffect, useState, useRef } from 'react';
import LoggedInDashboard from 'components/logged-in-dashboard';
// import { confirm } from 'components/confirm-box';
import { TableContainer, TableContent } from 'styles/table';
import { getPartners } from 'services/partners';
import { TooltipComponent } from 'components/tooltip';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import { Status } from 'styles/utils';
import { TableComponent } from 'components/table';
// import IconButton from '@material-ui/core/IconButton';
// import toast from 'react-hot-toast';
import moment from 'moment';
import Calendar from 'components/calendar';
// import { MenuComponent } from 'components/menu';
import BusinessDetails from 'containers/business-details';
import { useAuth } from 'hooks/auth';
import { useHistory } from 'react-router-dom';
// import { useBizDetailsModal } from 'hooks/bizDetailsModal';
import { Skeleton } from '@material-ui/lab';

function FollowUp() {
   const [tableData, setTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   // const [isChangeStatusLoading, setIsChangeStatusLoading] = useState(false);
   // const [anchorEl, setAnchorEl] = useState(null);
   const [paginationData, setPaginationData] = useState({});
   const [currentPage, setCurrentPage] = useState(1);
   const [modalVisibility, setModalVisibility] = useState(false);
   const [bizAccount, setBizAccount] = useState('');
   // const open = Boolean(anchorEl);
   // const { setActiveTab } = useBizDetailsModal();
   const [startDate] = useState('2019-01-01');
   const [endDate] = useState(moment().format('YYYY-MM-DD'));
   const tableRef = useRef();
   const history = useHistory();
   const { currentUser } = useAuth();

   // const handleClick = (event) => {
   //    setAnchorEl(event.currentTarget);
   // };

   // console.log()

   // const handleClose = () => {
   //    setAnchorEl(null);
   // };

   // async function handleChangeStatus({ target }, bizId) {
   //    confirm({
   //       confirmText: 'Are you sure you want to perform this action?',
   //       isLoading: isChangeStatusLoading,
   //       onConfirm: async () =>
   //          new Promise((resolve, reject) => {
   //             setIsChangeStatusLoading(true);
   //             changeStatus({
   //                biz_current_status: target.value,
   //                biz_account_id: bizId,
   //             })
   //                .then(() => {
   //                   setIsChangeStatusLoading(false);
   //                   toast.success('success');
   //                   resolve();
   //                })
   //                .catch((err) => {
   //                   setIsChangeStatusLoading(false);
   //                   reject(err);
   //                   toast.error(err.message || err.custom_message);
   //                });
   //          }),
   //    });
   // }

   // const options = ['View Details', 'Call', 'Send Message'];

   // function handleOpenModal() {
   //    setActiveTab('overview');
   //    setModalVisibility(true);
   // }

   // function sendMessageModalSection() {
   //    setActiveTab('message');
   //    setModalVisibility(true);
   // }

   // function callNumber(phone) {
   //    const link = document.createElement('a');
   //    const linkText = document.createTextNode('call us');
   //    link.appendChild(linkText);
   //    link.href = `tel:${phone}`;
   //    link.click();
   // }

   function onRowClick({ row }) {
      setBizAccount(row.biz_account_id);
   }

   const columns = [
      {
         field: 'partner_full_name',
         headerName: 'Fullname',
         renderCell: ({ row }) => (
            <TooltipComponent title={row.partner_full_name}>
               <span
               // style={{
               //    cursor: 'pointer',
               // }}
               // className="text-primary"
               // onClick={() => handleOpenModal(row.biz_account_id)}
               >
                  {row.partner_full_name || 'N/A'}
               </span>
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_email',
         headerName: 'Email',
         width: 150,
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_email}>
               {row.partner_email || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_phone',
         headerName: 'Phone',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_phone}>
               {row.partner_phone || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_biz_name',
         headerName: 'Business Name',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_biz_name}>
               {row.partner_biz_name || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_category',
         headerName: 'Category',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_category}>
               {row.partner_category || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_client_num',
         headerName: 'Total Clients',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_client_num}>
               {row.partner_client_num || 'N/A'}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_website',
         headerName: 'Website',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_website}>
               {row?.partner_website ? (
                  <a href={row?.partner_website} rel="noreferrer" target="_blank">
                     {row.partner_website}
                  </a>
               ) : (
                  'N/A'
               )}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_twitter',
         headerName: 'Twitter',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_twitter}>
               {row?.partner_twitter ? (
                  <a href={row?.partner_twitter} rel="noreferrer" target="_blank">
                     {row.partner_twitter}
                  </a>
               ) : (
                  'N/A'
               )}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_instagram',
         headerName: 'Instagram',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_instagram}>
               {row?.partner_instagram ? (
                  <a href={row?.partner_instagram} rel="noreferrer" target="_blank">
                     {row.partner_instagram}
                  </a>
               ) : (
                  'N/A'
               )}
            </TooltipComponent>
         ),
      },
      {
         field: 'partner_facebook',
         headerName: 'Facebook',
         renderCell: ({ row }) => (
            <TooltipComponent title={row?.partner_facebook}>
               {row?.partner_facebook ? (
                  <a href={row?.partner_facebook} rel="noreferrer" target="_blank">
                     {row.partner_facebook}
                  </a>
               ) : (
                  'N/A'
               )}
            </TooltipComponent>
         ),
      },

      // {
      //    field: 'pub_date',
      //    headerName: 'Date Onboarded',
      //    renderCell: ({ row }) => (
      //       <span>
      //          {moment(row?.pub_date).format('MMM DD, YYYY')} |{' '}
      //          {moment(row?.pub_date).format('h:mm a')}
      //       </span>
      //    ),
      // },
      // {
      //    field: '',
      //    width: 0,
      //    renderCell: ({ row }) => (
      //       <div>
      //          <IconButton
      //             aria-label="more"
      //             aria-controls="long-menu"
      //             aria-haspopup="true"
      //             onClick={handleClick}
      //          >
      //             <MoreVertIcon />
      //          </IconButton>
      //          <MenuComponent
      //             anchorEl={anchorEl}
      //             handleClose={handleClose}
      //             open={open}
      //             options={options}
      //             optionsFns={[
      //                // place the callbacks in position corresponding to the options index
      //                () => handleOpenModal(row.biz_account_id),
      //                () => callNumber(row?.account_holder_details?.phone),
      //                () => sendMessageModalSection(row.biz_account_id),
      //             ]}
      //          />
      //       </div>
      //    ),
      // },
   ];

   useEffect(() => {
      if (currentUser.user_type && currentUser.user_type !== 'prospasuperadmin')
         return history.push('/');
   }, [currentUser]);

   useEffect(() => {
      (async () => {
         setIsLoading(true);
         const params = {
            biz_turnover: '',
            biz_current_status: '',
            start_date: startDate,
            end_date: endDate,
         };
         Promise.all([getPartners({ page: currentPage }, params)])
            .then(([businessAccountsData]) => {
               setTableData(businessAccountsData.data);
               setPaginationData(businessAccountsData);
            })
            .finally(() => {
               setIsLoading(false);
            });
      })();
   }, [currentPage]);

   function onPageChange(e, pageNumber) {
      setCurrentPage(pageNumber);
   }

   return (
      <LoggedInDashboard pageTitle="Partners">
         <TableContainer id="table">
            <TableContent>
               {isLoading ? (
                  <Skeleton width="30%" height="20" />
               ) : (
                  <div>
                     <h1>Partners </h1>
                     <span className="value">{paginationData?.count || 'N/A'}</span>
                  </div>
               )}
               <div className="d-flex justify-content-between">
                  <Calendar />
               </div>
            </TableContent>
            <TableComponent
               columns={columns}
               rows={tableData}
               isLoading={isLoading}
               onPageChange={onPageChange}
               count={Math.ceil(paginationData.count / 15)}
               page={currentPage}
               ref={tableRef}
               onRowClick={onRowClick}
            />
         </TableContainer>
         <BusinessDetails
            businessAccountId={bizAccount}
            open={modalVisibility}
            onClose={() => setModalVisibility(false)}
         />
      </LoggedInDashboard>
   );
}

export default FollowUp;
