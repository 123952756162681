import { useEffect, useState } from 'react';
import { bool, number } from 'prop-types';
import { logActivity } from 'services/activity';
import { getBusinessDetails, getBusinessPcr } from 'services/businessDetails';
import { Spinner } from 'components/spinner';
import toast from 'react-hot-toast';
import { AlertMessage } from 'components/message';
import ProgressReport from 'components/progress-report';
import DirectorsInfo from 'containers/business-details/utils/directors-info';
import UserProfileComponent from 'containers/business-details/utils/user-profile';
import { OverviewWrapper } from '../utils/overview.style';
import BusinessInfo from './business-info';
import OwnerInfo from './owner-info';

// import { OverviewWrapper } from './overview.style';

const Overview = ({ businessAccountId, noCloseIcon }) => {
   const [bizDetails, setBizDetails] = useState({});
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState('');
   const [bizPcr, setBizPcr] = useState('');

   const fetchBusinessDetails = async () => {
      if (businessAccountId) {
         try {
            const result = await getBusinessDetails(businessAccountId);
            const response = await getBusinessPcr(businessAccountId);
            setBizPcr(response.crs_score);
            if (result.ok) {
               setBizDetails(result.data);
               setError('');
            }
            setLoading(false);
         } catch (err) {
            setError(err.message || err.custom_message);
            setLoading(false);
         }
      }
   };

   const statusValue = (status) => {
      switch (status) {
         case null:
            return 'not uploaded';
         case 'pending':
            return 'uploaded';
         default:
            return status;
      }
   };

   async function logViewDetails() {
      try {
         await logActivity(`Viewed Business Details`, 'reg', businessAccountId);
      } catch (e) {
         toast.error(e.message);
      }
   }

   useEffect(() => {
      logViewDetails();
      fetchBusinessDetails();
   }, [businessAccountId]);

   return (
      <OverviewWrapper>
         <AlertMessage message={error} variant="error" />

         {loading ? (
            <Spinner />
         ) : (
            <>
               <div className="pb-5">
                  <UserProfileComponent
                     businessAccountId={businessAccountId}
                     bizDetails={bizDetails}
                     fetchBusinessDetails={fetchBusinessDetails}
                     setLoading={(value) => setLoading(value)}
                     noCloseIcon={noCloseIcon}
                  />
               </div>

               <ProgressReport bizDetails={bizDetails} businessAccountId={businessAccountId} />
               {/* <Attention bizDetails={bizDetails} businessAccountId={businessAccountId} /> */}
               <BusinessInfo
                  bizDetails={bizDetails}
                  businessAccountId={businessAccountId}
                  fetchBusinessDetails={fetchBusinessDetails}
                  statusValue={(value) => statusValue(value)}
                  bizPcr={bizPcr}
               />
               <OwnerInfo
                  bizDetails={bizDetails}
                  businessAccountId={businessAccountId}
                  fetchBusinessDetails={fetchBusinessDetails}
                  statusValue={(value) => statusValue(value)}
               />
               {bizDetails?.board_partner_details?.length && (
                  <DirectorsInfo bizDetails={bizDetails} />
               )}
            </>
         )}
      </OverviewWrapper>
   );
};

Overview.propTypes = {
   businessAccountId: number.isRequired,
   noCloseIcon: bool.isRequired,
};

export default Overview;
