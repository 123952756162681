import { Http } from 'services/http';
import { paramsObjectToQueryString } from 'utils/others';

export const getCardDetails = (payload) =>
   Http(`/backmen/prospa_card_management/${paramsObjectToQueryString(payload)}`);

export const createNewCardDetails = (payload) =>
   Http(`/backmen/prospa_card_management/`, {
      method: 'POST',
      body: payload,
   });

export const updateCardDetails = async (payload, CardId) => {
   try {
      const response = await Http(`/backmen/prospa_card_management/${CardId}/`, {
         method: 'PATCH',
         body: payload,
      });
      return response;
   } catch (e) {
      throw new Error(e.message);
   }
};
