import { string, bool, func, node } from 'prop-types';
import { Modal as ReactResponsiveModal } from 'react-responsive-modal';
import CloseIcon from '../../assets/images/close-icon.svg';

const Modal = ({
   title,
   children,
   open,
   onClose,
   closeOnOverlayClick,
   center,
   noCloseIcon,
   className,
   width,
}) => (
   <ReactResponsiveModal
      open={open}
      onClose={() => {
         onClose();
         window.document.body.style.overflow = 'scroll!important';
      }}
      center={center}
      classNames={{
         overlay: 'customOverlay',
         modal: `customModal ${className}`,
      }}
      closeOnOverlayClick={closeOnOverlayClick}
      showCloseIcon={false}
      styles={{
         modalContainer: {
            width,
            margin: '0 auto',
         },
      }}
   >
      {!noCloseIcon && (
         <div className="modal-close-icon" onClick={onClose}>
            <img src={CloseIcon} alt="close" />
         </div>
      )}
      {title && (
         <div>
            <p className="modal-title">{title}</p>
         </div>
      )}
      <div className="modal-children">{children}</div>
   </ReactResponsiveModal>
);

Modal.propTypes = {
   title: string,
   className: string,
   children: node.isRequired,
   open: bool.isRequired,
   onClose: func.isRequired,
   closeOnOverlayClick: bool,
   center: bool,
   width: string,
   noCloseIcon: bool,
};

Modal.defaultProps = {
   title: '',
   closeOnOverlayClick: true,
   center: false,
   className: '',
   width: null,
   noCloseIcon: false,
};

export default Modal;
