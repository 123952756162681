import { Input } from 'components/input';
import Modal from 'components/modal';
import { useHistory } from 'react-router-dom';
import useBusinessMeta from 'hooks/businessMeta';
import { func, bool, arrayOf, objectOf, string } from 'prop-types';
import { InputWrapper } from 'styles/form';
import { Button } from 'components/button';
import { SelectInput } from 'components/select';
import { getAllManagers } from 'services/business';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

const basicOperations = [
   {
      value: 'accepted',
      label: 'Accepted',
   },
   {
      value: 'pending',
      label: 'Pending',
   },
   {
      value: 'rejected',
      label: 'Rejected',
   },
];

const businessType = [
   {
      value: 'registered',
      label: 'Registered',
   },
   {
      value: 'freelancer',
      label: 'Freelancer',
   },
];

const turnover = [
   {
      value: 'less_than_100k',
      label: 'Less than 100K',
   },
   {
      value: '100k-1m',
      label: '100K - 1M',
   },
   {
      value: '1m-5m',
      label: '1M - 5M',
   },
   {
      value: '5m-10m',
      label: '5M - 10M',
   },
   {
      value: '10m-50m',
      label: '10M - 50M',
   },
   {
      value: '50m_above',
      label: 'Above 50M',
   },
];

const bvnStatus = [
   {
      value: true,
      label: 'Accepted',
   },
   {
      value: false,
      label: 'Rejected',
   },
];

const tinStatus = [
   ...basicOperations,
   {
      value: 'requires_tin',
      label: 'Requires TIN',
   },
   {
      value: 'tin_doc_approved',
      label: 'TIN Document Approved',
   },
   {
      value: 'tin_doc_rejected',
      label: 'TIN Document Rejected',
   },
];

const RegisteredAccountsFilter = ({
   open,
   onClose,
   setfilterData,
   filterData,
   handleSubmit,
   handleClearFilter,
   filter,
}) => {
   const history = useHistory();
   const [isLoading, setIsLoading] = useState(false);
   const [managers, setManagers] = useState([]);
   const [isClearingFilter, setIsClearingFilter] = useState(false);
   const { businessCurrentStatus } = useBusinessMeta();
   const [bizCurrentStatus, setBizCurrentStatus] = useState([]);

   useEffect(() => {
      (async function fetchManagers() {
         try {
            const result = await getAllManagers();
            setManagers(
               result.data.map((item) => ({
                  value: item.prospa_manager_id,
                  label: `${item.first_name} ${item.last_name}`,
               })),
            );
         } catch (e) {
            toast.error(e.message);
         }
      })();
   }, []);

   useEffect(() => {
      if (businessCurrentStatus) {
         setBizCurrentStatus(
            businessCurrentStatus.map(({ current_status_tag }) => ({
               value: current_status_tag,
               label:
                  current_status_tag.charAt(0).toUpperCase() +
                  current_status_tag.slice(1).replaceAll('_', ' '),
            })),
         );
      }
   }, [businessCurrentStatus]);

   function onChange(name, data) {
      setfilterData({
         ...filterData,
         [name]: data.value,
      });
   }

   return (
      <Modal
         open={open}
         onClose={onClose}
         title="Filters"
         className="biz-details-modal"
         center
         width="750px"
      >
         <form>
            <InputWrapper className="grid-2-2">
               <Input
                  placeholder="Start Date"
                  label="Start Date"
                  name="start_date"
                  type="date"
                  value={filterData?.start_date}
                  onChange={(e) => {
                     setfilterData({ ...filterData, start_date: e.target.value });
                  }}
               />
               <Input
                  placeholder="End Date"
                  label="End Date"
                  name="end_date"
                  type="date"
                  value={filterData?.end_date}
                  onChange={(e) => {
                     setfilterData({ ...filterData, end_date: e.target.value });
                  }}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <SelectInput
                  name="biz_type"
                  label="Business Type"
                  options={businessType}
                  value={businessType.filter((item) => item.value === filterData?.biz_type)}
                  onChange={(val) => onChange('biz_type', val)}
               />

               <SelectInput
                  label="Manager"
                  value={managers.filter(
                     (item) => item.value === filterData?.build_partner_manager_id,
                  )}
                  onChange={(val) => onChange('build_partner_manager_id', val)}
                  options={managers}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <SelectInput
                  onChange={(val) => onChange('biz_current_status', val)}
                  value={bizCurrentStatus.filter(
                     (item) => item.value === filterData?.biz_current_status,
                  )}
                  label="Status"
                  options={bizCurrentStatus}
               />

               <SelectInput
                  label="Turnover"
                  value={turnover.filter((item) => item.value === filterData?.biz_turnover)}
                  onChange={(val) => onChange('biz_turnover', val)}
                  options={turnover}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <SelectInput
                  label="BVN"
                  value={bvnStatus.filter((item) => item.value === filterData?.bvn_number)}
                  onChange={(val) => onChange('bvn_number', val)}
                  options={bvnStatus}
               />

               <SelectInput
                  label="Identity File Status"
                  value={basicOperations.filter(
                     (item) => item.value === filterData?.identity_file_status,
                  )}
                  onChange={(val) => onChange('identity_file_status', val)}
                  options={basicOperations}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <SelectInput
                  label="CAC Status"
                  value={basicOperations.filter(
                     (item) => item.value === filterData?.cac_cert_photo_status,
                  )}
                  onChange={(val) => onChange('cac_cert_photo_status', val)}
                  options={basicOperations}
               />

               <SelectInput
                  label="Proof Of Address"
                  value={basicOperations.filter(
                     (item) => item.value === filterData?.has_address_verified,
                  )}
                  onChange={(val) => onChange('has_address_verified', val)}
                  options={basicOperations}
               />
            </InputWrapper>

            <InputWrapper className="grid-2-2">
               <SelectInput
                  label="TIN status"
                  value={tinStatus.filter((item) => item.value === filterData?.tin_number_status)}
                  onChange={(val) => onChange('tin_number_status', val)}
                  options={tinStatus}
               />
            </InputWrapper>

            <InputWrapper
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
               <div style={{ marginRight: '20px' }}>
                  <Button
                     variant="secondary"
                     type="button"
                     onClick={async () => {
                        setIsClearingFilter(true);
                        await handleClearFilter();
                        history.push(
                           `${history.location.pathname}?page=1${
                              filter ? `&filter=${filter}` : ''
                           }`,
                        );
                        setIsClearingFilter(false);
                        onClose();
                     }}
                     isLoading={isClearingFilter}
                     fullWidth
                  >
                     clear filter
                  </Button>
               </div>

               <div>
                  <Button
                     variant="primary"
                     type="button"
                     onClick={async () => {
                        setIsLoading(true);
                        await handleSubmit();
                        history.push(
                           `${history.location.pathname}?page=1${
                              filter ? `&filter=${filter}` : ''
                           }`,
                        );
                        setIsLoading(false);
                        onClose();
                     }}
                     isLoading={isLoading}
                     disabled={isLoading}
                     fullWidth
                  >
                     Apply
                  </Button>
               </div>
            </InputWrapper>
         </form>
      </Modal>
   );
};

export { RegisteredAccountsFilter };

RegisteredAccountsFilter.propTypes = {
   open: bool.isRequired,
   onClose: func.isRequired,
   setfilterData: func.isRequired,
   filterData: arrayOf(objectOf(string)).isRequired,
   filter: string.isRequired,
   handleSubmit: func.isRequired,
   handleClearFilter: func.isRequired,
};
