import Styled from 'styled-components';

export const Wrapper = Styled.div`
    .business-details-item h3{
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #81909D;
        margin-bottom:8px;
    }
    .business-details-item{
        margin-bottom:32px;
    }
    .business-details-item h6{
        
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #81909D;
    }
    .business-details-item p{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #3A434B;
        margin-bottom:0;
        text-transform:capitalize;
        overflow-wrap: break-word;
    }
`;
